import React from 'react'
import {connect} from 'react-redux'
import {Form, Input, Button, message} from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import {mapDispatchActionToProps, textMethod} from "../../../common/utils/index";
import PropTypes from "prop-types";
import {doCreateUserWithEmailAndPassword} from '../../../common/utils/firebase/auth'
import Text from "../../i18n/text";

const FormItem = Form.Item;

class RegisterForm extends React.Component {
  formRef = React.createRef();

  state = {
    confirmDirty: false
  };

  render() {
    const {login, text} = this.props;
    return (
      <Form ref={this.formRef} onFinish={this.handleSubmit} className="login-form">
        <FormItem name="email" type="email" rules={[
          { type: 'email', message: text('AUTH.LOGIN.ERR.WRONG_EMAIL') },
          { required: true, message: text('AUTH.LOGIN.ERR.EMAIL_REQUIRED') }
          ]}>
          <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={text('AUTH.LOGIN.EMAIL_PLACEHOLDER')} />
        </FormItem>
        <FormItem name="password" rules={[
          { required: true, message: text('AUTH.LOGIN.ERR.PASS_REQUIRED') }
        ]} hasFeedback>
          <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={text('AUTH.LOGIN.PASS_PLACEHOLDER')}/>
        </FormItem>
        <FormItem name="confirm" rules={[
          { required: true, message: text('AUTH.SIGN_UP.ERR.PASS_CONFIRM_REQUIRED') },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject(new Error(text('AUTH.SIGN_UP.ERR.PASS_CONFIRM_SIMILARITY')));
            },
          })
        ]} dependencies={['password']} hasFeedback>
          <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={text('AUTH.SIGN_UP.PASS_CONFIRM_PLACEHOLDER')} />
        </FormItem>

        <div className='buttons-holder'>
          <Button type="link" className="padding0" onClick={login}>
            <Text path="AUTH.SIGN_UP.LOGIN"/>
          </Button>
          <Button type="primary" htmlType="submit" className="login-form-button">
            <Text path="AUTH.SIGN_UP.REGISTER"/>
          </Button>
        </div>
      </Form>
    );
  }

  handleSubmit = (values) => {
    const {cancel} = this.props;
    const {email, password} = values;

    doCreateUserWithEmailAndPassword(email, password)
      .then(() => {
      cancel(true)
    }).catch(err => {
      this.formRef.current.setFieldsValue({
        email: '',
        password: ''
      });
      console.log(err);
      return message.error("Registration failure");
    })
  }
}

RegisterForm.propTypes = {
  login: PropTypes.func,
  cancel: PropTypes.func
};

const mapStateToProps = (state) => ({
  text: textMethod(state)
});

export default connect(mapStateToProps, mapDispatchActionToProps)(RegisterForm)
