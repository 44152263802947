import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import { Modal, Input, Radio } from 'antd'
import {DATE_OPTIONS} from "../../../../../common/constants";
import {getPeriodOptions} from "../../common/utils";
import {eventValue, mapDispatchActionToProps, textMethod} from "../../../../../common/utils";
import {habitUpdate, habitAdd} from "../../../../../common/actions/habit-actions";
import './habit-amend-modal.css'
import uuid from "uuid-random";
import {maxHabitsOrderSelector} from "../../../../../common/selectors/habits-selectors";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const HabitAmendModal = (props) => {
  const {text, habit} = props;

  const [name, changeName] = useState("");
  const [period, changePeriod] = useState(DATE_OPTIONS.DAY);

  useEffect(() => {
    const {period:habitPeriod = DATE_OPTIONS.DAY, name:habitName = ""} = habit || {};
    changeName(habitName);
    changePeriod(habitPeriod)
  }, [habit]);

  const titlePath = `PAGES.HABITS.${habit && habit.id ? 'AMEND_MODAL_TITLE' : 'ADD_NEW_MODAL_TITLE'}`;
  const modalTitle = text(titlePath);
  const habitNamePlaceholder = text('PAGES.HABITS.NAME_PLACEHOLDER')

  const nameChangeHandler = (event) => {
    changeName(eventValue(event))
  };

  const periodChangeHandler = (event) => {
    changePeriod(eventValue(event))
  };

  return <Modal
    title={modalTitle}
    visible={!!habit}
    okButtonProps={{disabled: name === ""}}
    onOk={handleOk(props, name, period)}
    onCancel={handleCancel(props)}
  >
    <Input placeholder={habitNamePlaceholder} value={name} onChange={nameChangeHandler}/>
    <RadioGroup className='habit-amend-periods'
                onChange={periodChangeHandler}
                value={period}>
      {getPeriodOptions(text).map(option => (<RadioButton key={option.value} value={option.value}>{option.label}</RadioButton>))}
    </RadioGroup>
  </Modal>
};

const handleOk = (props, name, period) => () => {
  const {habit:{id:currentId}, maxOrder, dispatchAction, finishAmend} = props;

  if(currentId) {
    dispatchAction(habitUpdate({id: currentId, name, period}))
  } else {
    const id = uuid();
    const order = maxOrder + 1;

    dispatchAction(habitAdd({id, name, period, order}))
  }

  finishAmend()
};

const handleCancel = (props) => () => {
  props.finishAmend();
};

const mapStateToProps = (state) => {
  return {
    text: textMethod(state),
    maxOrder: maxHabitsOrderSelector(state),
  }
};

export default connect(mapStateToProps, mapDispatchActionToProps)(HabitAmendModal)
