import React from 'react'
import {connect} from 'react-redux'
import {languageSelector} from "../../common/selectors/app-selectors";
import RU_TEXTS from './../../locales/ru'
import EN_TEXTS from './../../locales/en'
import {LANGUAGE} from "../../common/constants";
import {property} from "lodash";

const TEXTS = {
  [LANGUAGE.RU]: RU_TEXTS,
  [LANGUAGE.EN]: EN_TEXTS
}

class Text extends React.Component {
  render() {
    const {path, language} = this.props

    return tt(path, language)
  }
}

const mapStateToProps = (state, ownProps) => ({
  language: languageSelector(state)
})

export default connect(mapStateToProps)(Text)

/*---------------*/
export const tt = (path, l) => {
  return property(path)(TEXTS[l]) || "No copy provided"
}
