import { combineReducers } from 'redux'

import goalsReducer from './goals-reducer'
import habitsReducer from './habits-reducer'
import viewsReducer from './views-reducer'
import resultsReducer from './results-reducer'
import notesReducer from './notes-reducer'
import propertiesReducer from './properties-reducer'
import { userReducer } from './user-reducer'
import {STORAGE_ACTION, I18N_ACTION, USER_ACTION} from "../types";
import {LANGUAGE} from "../constants";
import {languageSelector} from "../selectors/app-selectors";

const lastUpdateTimeReducer = (state = 0, action) => {
  if (action.type === STORAGE_ACTION.SAVE) {
    return new Date().getTime()
  }

  return state
};

const languageReducer = (state = LANGUAGE.EN, action) => {
  if (action.type === I18N_ACTION.LANGUAGE_CHANGE) {
    return action.language
  } else if (action.type === STORAGE_ACTION.RESTORE) {
    return languageSelector(action.state)
  }

  return state
};

const loginAttemptDoneReducer = (state = 0, action) => {
  if (action.type === USER_ACTION.LOGIN || action.type === USER_ACTION.LOGOUT) {
    return true
  }

  return state
};

export default combineReducers({
  user: userReducer,
  goals: goalsReducer,
  habits: habitsReducer,
  views: viewsReducer,
  results: resultsReducer,
  notes: notesReducer,
  properties: propertiesReducer,

  language: languageReducer,
  lastUpdateTime: lastUpdateTimeReducer,
  loginAttemptDone: loginAttemptDoneReducer
})
