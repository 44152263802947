import { createSelector } from 'reselect'
import { property } from 'lodash'
import {VIEWS} from "../constants";

export const viewsSelector = state => state.views
export const selectedViewIdSelector = (state, ownProps) => property('match.params.viewId')(ownProps)

export const selectedView = createSelector(
  selectedViewIdSelector,
  (selectedId) => {
    let view = VIEWS.goals

    if (selectedId) {
      view = VIEWS[selectedId]
    }

    return view ? view : VIEWS.goals
  }
)