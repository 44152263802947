import React, {useEffect, useState} from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import {URL} from '../common/constants'
import withAuthentication from '../common/utils/auth/with-authentication'
import 'antd/dist/antd.css'
import './app.css'
import moment from 'moment'
import {lowerCase} from 'lodash'
import LandingPage from './public/landing-page/landing-page'
import {connect} from "react-redux";
import {languageSelector} from "../common/selectors/app-selectors";
import {russianMomentLocale} from "../locales/ru";
import TopMenu from '../components/top-menu/top-menu'
import AuthModal from '../components/auth-modal/AuthModal'
import FirebaseUpdateWatcher from '../common/components/firebase/firebase-update-watcher'
import GoalsPage from "./user/goals/GoalsPage";
import HabitsPage from "./user/habits/HabitsPage";
import NotesPage from "./user/notes/NotesPage";
import ResultsPage from "./user/results/ResultsPage";
import ProfilePage from "./user/profile/ProfilePage";

const App = (props) => {
  useEffect(() => {
    moment.locale("ru", russianMomentLocale)
    moment().locale(lowerCase(props.language))
  }, [props.language]);

  const [authFormVisible, changeAuthFormVisibility] = useState(false);

  const openLoginModal = () => {
    changeAuthFormVisibility(true)
  };

  const closeLoginModal = (loginSuccessful = false) => {
    changeAuthFormVisibility(false);

    if (loginSuccessful === true) {
      window.location.href = URL.GOALS;
    }
  };

  return <Router>
    <div className="main-layout">
      <div className="main-header">
        <FirebaseUpdateWatcher/>
        <AuthModal visible={authFormVisible} closeModal={closeLoginModal}/>
        <TopMenu openLoginModal={openLoginModal}/>
      </div>
      <div className="main-content">
        <Routes>
          <Route path={URL.HOME} element={<LandingPage  openLoginModal={openLoginModal}/>}/>
          <Route
            exact path={URL.GOALS}
            element={<GoalsPage/>}
          />
          <Route
            exact path={URL.HABITS}
            element={<HabitsPage/>}
          />
          <Route
            exact path={URL.NOTES}
            element={<NotesPage/>}
          />
          <Route
            exact path={URL.RESULTS}
            element={<ResultsPage/>}
          />
          <Route
            exact path={URL.PROFILE}
            element={<ProfilePage/>}
          />
        </Routes>
      </div>
    </div>
  </Router>
};

const mapStateToProps = state => ({
  language: languageSelector(state)
});


const AppController = connect(mapStateToProps)(App)
export default withAuthentication(AppController)
