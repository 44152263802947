import React from 'react'
import {connect} from "react-redux";
import {mapDispatchActionToProps, textMethod} from "../../../common/utils/index";
import {VIEW_TYPE} from "../../../common/constants";
import {withAuthorization} from "../../../common/utils/auth/with-authorization";
import {itemsWithChildrenSelector, selectedGoalsIdsSelector} from "../../../common/selectors/goals-selectors";
import PageTemplate from "../page-template/PageTemplate";

const ProfilePage = (props) => {
  const {text} = props;

  return <PageTemplate
    selectedView={VIEW_TYPE.PROFILE}
    pageTitle={text("VIEWS.profile")}
  >Profile</PageTemplate>
};

const mapStateToProps = (state) => ({
  text: textMethod(state)
});

const ProfilePageController = connect(mapStateToProps, mapDispatchActionToProps)(ProfilePage)
export default withAuthorization(ProfilePageController)
