import React from 'react'
import {connect} from 'react-redux'
import {eventValue, mapDispatchActionToProps, textMethod} from "../../../../../../common/utils";
import {goalsByIdSelector, itemsTagsSelector} from "../../../../../../common/selectors/goals-selectors";
import EditableTagGroup from "../../../../../../common/components/editable-tag-group";
import PeriodSelect from "./PeriodSelect";
import MyDatePicker from "../../../../../../common/components/date-picker/date-picker";
import moment from "moment";
import ImportanceSelect from "../../../../../../common/components/importance-select";
import UrgencySelect from "../../../../../../common/components/urgency-select";
import {itemsBulkUpdate} from "../../../../../../common/actions/item-actions";
import NameEdit from "./NameEdit";
import DescriptionEdit from "./DescriptionEdit";
import Text from "../../../../../../components/i18n/text";

const ItemEdit = (props) => {
  const {selectedItemsIds = [], goalsMap = {}, extendedFieldsOnly = false, dispatchAction} = props;

  const items = selectedItemsIds.map(id => goalsMap[id]);
  const groupEdit = selectedItemsIds.length > 1;

  const propsChange = (key, value) => {
    const itemsUpdate = items.map(({id}) => ({id, [key]: value}))
    dispatchAction(itemsBulkUpdate(itemsUpdate))
  };

  const nameChange = (newName) => { if (items[0].name !== newName) propsChange("name", newName);};
  const descriptionChange = (newDescription) => {if (items[0].description !== newDescription) propsChange("description", newDescription);};

  const nameEdit = items.length !== 1 ? null : <NameEdit
    value={items[0].name}
    placeholder='Name'
    onChange={nameChange}
  />;

  const descriptionEdit = items.length !== 1 ? null : <div className='properties-group'>
    <DescriptionEdit
      value={items[0].description}
      placeholder='Description'
      onChange={descriptionChange}
      rows={4}
    />
  </div>;

  const nameEditBlock = <div className='properties-group'>
    <span className="label"><Text path="VIEWS.PROPERTIES.NAME"/></span>
    {nameEdit}
  </div>;

  const descriptionEditBlock = <div className='properties-group'>
    <span className="label"><Text path="VIEWS.PROPERTIES.DESCRIPTION"/></span>
    {descriptionEdit}
  </div>;

  const periodEditBlock = <div className='properties-group'>
    <span className="label"><Text path="VIEWS.PROPERTIES.PERIOD"/></span>
    {getPeriodSelect(items, propsChange)}
  </div>;

  const dateEditBlock = <div className='properties-group'>
    <span className="label"><Text path="VIEWS.PROPERTIES.DATE"/></span>
    {getDateSelect(items, propsChange)}
  </div>;

  const importanceUrgencyEditBlock = <div className='properties-group'>
    {getImportanceAndUrgencySelect(items, propsChange)}
  </div>;

  return <div className='item-properties'>
    {groupEdit ? null : nameEditBlock}
    <div className='properties-group'>
      <span className="label"><Text path="VIEWS.PROPERTIES.TAGS"/></span>
      {getTagsEdit(props, propsChange)}
    </div>

    {extendedFieldsOnly ? null : periodEditBlock}
    {extendedFieldsOnly ? null : dateEditBlock}
    {extendedFieldsOnly ? null : importanceUrgencyEditBlock}

    {groupEdit ? null : descriptionEditBlock}
  </div>
};

const getTagsEdit = (props, propsChange) => {
  const {selectedItemsIds = [], goalsMap = {}, allTags} = props;

  const tags = selectedItemsIds.length === 1 ? goalsMap[selectedItemsIds[0]].tags : []

  const tagsUpdateHandler = (tags) => {
    propsChange('tags', tags)
  };

  return <EditableTagGroup
    tags={tags}
    allTags={allTags}
    update={tagsUpdateHandler}
    size='default'
  />
};

const getPeriodSelect = (items, propsChange) => {
  if (items.length === 0) return null;

  let curPeriod = items[0].period;
  for (let i=0; i<items.length; i++) {
    if (items[i].period !== curPeriod) {
      curPeriod = null;
      break;
    }
  }

  const changeHandler = (e) => propsChange("period", eventValue(e));

  return <PeriodSelect value={curPeriod} onChange={changeHandler}/>
};

const getDateSelect = (items, propsChange) => {
  if (items.length === 0) return null;

  let curDateFrom = items[0].dateFrom;
  for (let i=0; i<items.length; i++) {
    if (items[i].dateFrom !== curDateFrom) {
      curDateFrom = null;
      break;
    }
  }

  const changeHandler = (moment) => {
    propsChange("dateFrom", moment ? moment.valueOf() : null);
  };

  return <MyDatePicker value={curDateFrom ? moment(curDateFrom) : null} onChange={changeHandler} className="item-date"/>
};

const getImportanceAndUrgencySelect = (items, propsChange) => {
  const itemImportance = items.length === 1 ? items[0].importance : null;
  const itemUrgency = items.length === 1 ? items[0].urgency : null;

  const changeHandler = prop => (e) => propsChange(prop, eventValue(e));

  const importanceProps = {
    key: "importance",
    value: itemImportance,
    onChange: changeHandler('importance'),
    style: {width: '50%'}
  };

  const urgencyProps = {
    key: "urgency",
    value: itemUrgency,
    onChange: changeHandler('urgency'),
    style: {width: '50%'}
  };

  return [
    <ImportanceSelect {...importanceProps}/>,
    <UrgencySelect {...urgencyProps}/>
  ]
};

const mapStateToProps = (state) => ({
  allTags: itemsTagsSelector(state),
  text: textMethod(state),
  goalsMap: goalsByIdSelector(state)
});

export default connect(mapStateToProps, mapDispatchActionToProps)(ItemEdit)
