import {DATE_OPTIONS} from "../common/constants";

export default {
  TOP_MENU: {
    HOW_IT_WORKS: 'How to',
    PRICING: 'Pricing',
    APPLICATION: 'Application',
    SIGN_IN: 'Sign In',
    LOG_IN: 'Log In',
    LOG_OUT: 'Log Out',
    LOG_OUT_CONFIRM: 'Are you sure?',
    TO_HOME_PAGE: 'Home page',
    LANGUAGE_CHANGE: 'Language change',
  },
  VIEWS: {
    goals: 'Goals',
    habits: 'Habits',
    results: 'Results',
    notes:  'Notes',
    profile: 'Profile',
    PROPERTIES: {
      title: 'Edit',
      groupTitle: 'Group edit',
      NAME: 'Name',
      TAGS: 'Tags',
      PERIOD: 'Period',
      DATE: 'Or Date',
      DESCRIPTION: 'Description',
    },
  },
  AUTH: {
    LOGIN: {
      TITLE: 'Login',
      EMAIL_PLACEHOLDER: 'Email',
      PASS_PLACEHOLDER: 'Password',
      FORGOT_PASS: 'Forgot password?',
      REGISTER: 'Register now',
      LOGIN: 'Log in',
      ERR: {
        LOGIN_FAILED: 'Login failed. Please check your credentials and try again',
        EMAIL_REQUIRED: 'Please input your email!',
        WRONG_EMAIL: 'The input is not valid E-mail!',
        PASS_REQUIRED: 'Please input your Password!',
      }
    },
    SIGN_UP: {
      TITLE: 'Registration',
      PASS_CONFIRM_PLACEHOLDER: 'Confirm password',
      REGISTER: 'Register',
      LOGIN: 'Log In now',
      ERR: {
        REGISTRATION_FAILED: 'Registration failure',
        PASS_CONFIRM_REQUIRED: 'Please confirm your Password!',
        PASS_CONFIRM_SIMILARITY: 'The two passwords that you entered do not match!',
      }
    },
    PASS_RESET: {
      TITLE: 'Password reset',
      LOGIN: 'Log In now',
      RESET: 'Reset password'
    },
  },
  CONSTANTS: {
    SEARCH_PLACEHOLDER: 'Search',
    DATE_SELECT_PLACEHOLDER: 'Select date',
    TIME_SELECT_PLACEHOLDER: 'Select time',

    HABIT: 'Habit',

    ADD: 'Add',
    CANCEL: 'Cancel',

    ALL: 'All',
    ANY: 'Any',

    DURATION: 'Duration',
    HOUR_SHORT: 'h',
    HOUR_MID: 'hr',
    HOURS_LONG: 'Hours',
    MIN_SHORT: 'm',
    MIN_MID: 'min',
    DAY: 'day',

    HABIT_PERIODS: {
      [DATE_OPTIONS.DAY]: 'Daily',
      [DATE_OPTIONS.WEEK]: 'Weekly',
      [DATE_OPTIONS.MONTH]: 'Monthly',
      [DATE_OPTIONS.YEAR]: 'Yearly',
    },
    BUTTON_LABELS: {
      ADD: 'Add',
      CANCEL: 'Cancel',
    },
    TIME_PERIOD: {
      [DATE_OPTIONS.DAY]: 'Day',
      [DATE_OPTIONS.WEEK]: 'Week',
      [DATE_OPTIONS.MONTH]: 'Month',
      [DATE_OPTIONS.YEAR]: 'Year',
    },
    TIME_PERIOD_OF: {
      [DATE_OPTIONS.DAY]: 'Day',
      [DATE_OPTIONS.WEEK]: 'Week',
      [DATE_OPTIONS.MONTH]: 'Month',
      [DATE_OPTIONS.YEAR]: 'Year',
    },
    TIME_PERIODS: {
      [DATE_OPTIONS.DAY]: 'Days',
      [DATE_OPTIONS.WEEK]: 'Weeks',
      [DATE_OPTIONS.MONTH]: 'Months',
      [DATE_OPTIONS.YEAR]: 'Years',
    },
    CURRENT_PERIOD_TEXT: {
      [DATE_OPTIONS.DAY]: 'Today',
      [DATE_OPTIONS.WEEK]: 'This week',
      [DATE_OPTIONS.MONTH]: 'This month',
      [DATE_OPTIONS.YEAR]: 'This Year',
    },
    URGENCY: 'Urgency',
    IMPORTANCE: 'Importance',
    IN_THE_PAST: "In the past",
    TODAY: "Today",
    TOMORROW: "Tomorrow",
    ITEM: "item",
    ITEMS_1: "items",
    ITEMS_2: "items",
  },
  PAGES: {
    ACTIONS: {
      ADD_NEW: 'Add a new goal',
      ADD_NEW_TO: 'Add a new goal in ',
      NEW_ITEM_PLACEHOLDER: 'New line = New Goal',
      MOBILE_EDIT: {
        DATE: {
          LABEL: "Date",
          DROP_LABEL: "Select appropriate date",
          OPTIONS: {
            TODAY: "Today",
            TOMORROW: "Tomorrow",
            INAWEEK: "In a week",
            OTHER_DATE: "Other date",
          }
        },
        IMPORTANCE: {
          LABEL: "Importance",
          DROP_LABEL: "Select appropriate importance",
          OPTIONS: {
            MINOR: "Nice to have",
            NORMAL: "Important",
            HIGH: "Must have",
          }
        },
        URGENCY: {
          LABEL: "Urgency",
          DROP_LABEL: "Select appropriate urgency",
          OPTIONS: {
            MINOR: "Minor",
            NORMAL: "Normal",
            HIGH: "Urgent",
          }
        },
        PERIOD: {
          LABEL: "Period",
          DROP_LABEL: "Select update period",
          OPTIONS: {
            NONE: "No period",
            WEEK: "Week",
            MONTH: "Month",
            YEAR: "Year"
          }
        },
        MORE: {
          LABEL: "More",
        },
        LESS: {
          LABEL: "Less",
        }
      }
    },
    PLANINGS: '',
    HABITS: {
      ADD_NEW: 'New habit',
      NAME_PLACEHOLDER: 'Habit name',
      ADD_NEW_MODAL_TITLE: 'Add new habit',
      AMEND_MODAL_TITLE: 'Amend habit',
      WRONG_PERIOD: 'Choose appropriate mode',
    },
    NOTES: {
      ADD_NEW: 'Add a new note',
      SEARCH_PLACEHOLDER: 'Search by note title',
      NOTE_FROM_DATE: 'Note from',
      NOTE_DETAILS_NAME_PLACEHOLDER: 'Type note title here',
      NOTE_DETAILS_CONTENT_PLACEHOLDER: 'Type your thoughts here',
      NO_NAME: '(No name)',
      TIMER: {
        TIME_OUT: 'Your time is up',
        NON_STOP_TIME_OUT: 'Ups... Give it another try'
      }
    },
    RESULTS: {
      ADD_NEW: 'Add a new note',
      NOTE_TITLE_PLACEHOLDER: {
        [DATE_OPTIONS.DAY]: 'Results of the day',
        [DATE_OPTIONS.WEEK]: 'Results of the week',
        [DATE_OPTIONS.MONTH]: 'Month results',
        [DATE_OPTIONS.YEAR]: 'Results of the year',
      },
      NOTE_DETAILS_CONTENT_PLACEHOLDER: 'Your thoughts about achieved goals',
      SHOW_ACHIEVEMENTS: 'Show achievements',
      SHOW_SUMMARY: 'Show summary',
      ADD_SUMMARY: 'Add summary'
    },
  },
  DELETE: {
    areYouSure: "Are you sure you want to delete it?",
    willBeDeleted: "Following item will be deleted",
    delete: "Delete",
    cancel: "Cancel",
  }
}
