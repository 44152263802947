import React, {useState} from 'react'
import {connect} from 'react-redux'
import Item from './list-item/GoalsPriorityListItem'
import {mapDispatchActionToProps, textMethod} from "../../../../../common/utils";
import {
  planingItemGroupsSelector,
  planingItemsSelector
} from "../../../../../common/selectors/goals-selectors";
import "./GoalsPriorityList.css";
import {useKeyPress} from "../../../../../common/hooks/useKeyPress";
import {findIndex} from "lodash";
import {dispatchCustomEvent} from "../../../../../common/hooks/customEvents";
import {CUSTOM_EVENTS} from "../../../../../common/types";

const GoalsPriorityList = (props) => {
  const {groups, period, items, selectedItemsIds, selectItemsWithIds, text} = props;

  const ctrlPressed = useKeyPress('Control');
  const metaPressed = useKeyPress('Meta');
  const shiftPressed = useKeyPress('Shift');

  const selectionProps = {
    ctrlPressed: ctrlPressed || metaPressed,
    shiftPressed,
    items,
    selectedItemsIds,
    selectItemsWithIds
  };

  const editionProps = {
    selectedItemsIds,
    selectItemsWithIds
  };

  const itemsList = (listItems) => {
    return listItems.map(i => <Item
      onSelect={selectItem(i, selectionProps)}
      key={i.id}
      selected={selectedItemsIds.indexOf(i.id) >= 0}
      item={i}
      onEdit={editItem(i, editionProps)}
    />)
  };

  let listContent;

  if (groups && groups.length) {
    listContent = groups
      .filter(({name}) => !period || name === period)
      .map((group, index) => {
      const header = `${getGroupName(group.name, text)} - ${group.items.length} ${getGroupItemsText(group.items.length, text)}`;

      return <div key={`group${index}`} className="ant-spin-container">
        <div className='group-header'>
          {header}
        </div>
        {itemsList(group.items, selectedItemsIds)}
      </div>
    })
  } else {
    listContent = <div className="ant-list-empty-text">
      Add some actions with period or dates, and you'll see them in this list
    </div>
  }

  return <div className='goals-priority-list-container ant-list ant-list-split'>
    <div className='ant-spin-nested-loading'>
      {listContent}
    </div>
  </div>
};

const getGroupName = (name, text) => {
  switch(name) {
    case "TOMORROW":
    case "TODAY":
    case "IN_THE_PAST": return text(`CONSTANTS.${name}`);
    default: return name;
  }
};

const getGroupItemsText = (amount, text) => {
  if (amount === 0) {
    return text('CONSTANTS.ITEMS_2');
  } else if (amount === 1) {
    return text('CONSTANTS.ITEM');
  } else if (amount > 1 && amount < 5) {
    return text('CONSTANTS.ITEMS_1');
  } else {
    return text('CONSTANTS.ITEMS_2');
  }
};

const selectItem = (item, selectionProps) => () => {
  const {ctrlPressed, shiftPressed, items, selectedItemsIds, selectItemsWithIds} = selectionProps;

  if (ctrlPressed) {
    let ids = [];
    if (selectedItemsIds.includes(item.id)) {
      ids = selectedItemsIds.filter(id => id !== item.id)
    } else {
      ids = [...selectedItemsIds, item.id]
    }

    selectItemsWithIds(ids);
  } else if(shiftPressed) {
    let firstIndex = findIndex(items, {id: selectedItemsIds[0]})
    let lastIndex = firstIndex

    selectedItemsIds.forEach(id => {
      const idIndex = findIndex(items, {id})

      firstIndex = idIndex < firstIndex ? idIndex : firstIndex
      lastIndex = idIndex > lastIndex ? idIndex : lastIndex
    });

    const index = findIndex(items, {id: item.id})

    let newSelection = []
    if (index < firstIndex) {
      newSelection = items.slice(index, firstIndex+1)
    } else {
      newSelection = items.slice(firstIndex, index+1)
    }

    let newSelectionIds = newSelection.map(item => item.id)

    selectItemsWithIds(newSelectionIds)
  } else {
    if ((selectedItemsIds || []).length === 1 && selectedItemsIds[0] === item.id) {
      selectItemsWithIds([]);
    } else {
      selectItemsWithIds([item.id]);
    }
  }
};

const editItem = (item, editionProps) => () => {
  const {selectedItemsIds, selectItemsWithIds} = editionProps;

  const editGoalsWithIds = (ids) => {
    dispatchCustomEvent(CUSTOM_EVENTS.EDIT_ACTION, {ids})
  };

  if (selectedItemsIds.includes(item.id)) {
    editGoalsWithIds(selectedItemsIds);
  } else {
    selectItemsWithIds([item.id]);
    editGoalsWithIds([item.id]);
  }
};

const mapStateToProps = (state, ownProps) => ({
  text: textMethod(state),
  items: planingItemsSelector(state, ownProps),
  groups: planingItemGroupsSelector(state),
});

export default connect(mapStateToProps, mapDispatchActionToProps)(GoalsPriorityList)
