import {RESULT_ACTION} from '../types'

export const resultAdd = result => ({
  type: RESULT_ACTION.ADD_RESULT,
  result
})

export const resultDelete = (id) => ({
  type: RESULT_ACTION.DELETE_RESULT,
  id
})

export const resultUpdate = result => ({
  type: RESULT_ACTION.UPDATE_RESULT,
  result
})

export const resultIdSelect = id => ({
  type: RESULT_ACTION.SELECT_RESULT_BY_ID,
  result:{id}
})

export const resultPropsSelect = (year, period, index) => ({
  type: RESULT_ACTION.SELECT_RESULT_BY_PROPS,
  result:{year, period, index}
})