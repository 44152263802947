import React from 'react'
import './landing-page.css'
import {userSelector} from "../../../common/selectors/user-selectors";
import {connect} from "react-redux";
import {URL} from "../../../common/constants";

const landingBlocks = [
  {
    image: 'images/landing/1.jpg',
    header1: 'Get your dreams achieved',
    header2: 'Step by step…',
    text: 'There are tons of things we want to achieve. It is time to put them down and start marking as "Done" one by one',
    actionName: 'Get Started',
    targetView: URL.GOALS
  },
  {
    image: 'images/landing/2.jpg',
    header1: 'Life as a hierarchy of goals',
    header2: 'Plan it in a easy way',
    text: 'Not all goals can fit in check-list. Some of them have sub items, which can have their sub items. Solution is obvious - we use hierarchy!',
    points: [
      'Simple hierarchical view',
      'Keyboard and mouse control',
      'Rich filtering'
    ],
    actionName: 'Introduce goals',
    targetView: URL.GOALS
  },
  {
    image: 'images/landing/3.jpg',
    header1: 'We are our habits',
    header2: 'Create new ones and become better',
    text: "Our live is full of repeating actions. We can't control them unless we measure them. Add habits, follow progress, make decisions",
    points: [
      'Track your daily, weekly and monthly habits',
      'Have a simple and nice overview',
      'Keep additional data for each action'
    ],
    actionName: 'Master new habits',
    targetView: URL.HABITS
  },
  {
    image: 'images/landing/4.jpg',
    header1: 'Summarize for better start',
    header2: 'Tomorrow morning starts today in the evening',
    text: 'With EDDY it is easy to see what was done and to decide with what to go next',
    points: [
      'Sum-up you day, week, month and year activities',
      'Support list of done actions with your thoughts ',
      'See what what was planned and to be done yet',
      'See your habits progress as part of your summary'
    ],
    actionName: 'Start summarizing effectively',
    targetView: URL.RESULTS
  },
  {
    image: 'images/landing/5.jpg',
    header1: 'Be creative when you need it',
    header2: 'Get those brilliant ideas out of your mind with freewriting',
    text: 'Freewriting is popular way of self-research and one-man-brainstorming. Feel the power of freewriting with EDDY and transform your thoughts into goals immediately',
    points: [
      'Keep all your thoughts near your dreams and plans',
      'Use timer to keep organized and timeboxed',
      'Use "Write-or-Die" technique to boost your creativity'
    ],
    actionName: 'Start freewriting',
    targetView: URL.NOTES
  }
];

class Landing extends React.Component {
  render () {
    return <div className='landing'>
      {landingBlocks.map((data, index) => this.landingBlock(data, index))}
    </div>
  }

  landingBlock = (data, index) => {
    const {user, openLoginModal} = this.props

    const hrefClickHandler = (event) => {
      if (user) {
        return true;
      } else {
        event.preventDefault();
        event.stopPropagation();
        openLoginModal()
        return false;
      }
    };

    const points = (data.points && <ul className='points'>
      {data.points.map((p, i) => (<li key={i}>{p}</li>))}
    </ul>) || null

    return <div key={'block'+index} className={`page-block ${index%2 === 0 ? '' : 'vv'}`}  style={{backgroundImage: `url('${data.image}')`}}>
      <div className='text-part'>
        <div className='text-container'>
          <h1 className='header1'>{data.header1}</h1>
          <h1 className='header2'>{data.header2}</h1>
          <div className='text'>{data.text}</div>
          {points}
          <div className='actions'>
            <a className='action' onClick={hrefClickHandler} href={data.targetView}>{data.actionName}</a>
          </div>
        </div>
      </div>
      <div className='image-part'>
      </div>
    </div>
  }
}

const mapStateToProps = state => ({user: userSelector(state)})

export default connect(mapStateToProps)(Landing)
