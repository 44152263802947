import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import { Table } from 'antd'
import {columnWidthReducer, getHabitColumns} from "../../common/utils";
import './habits-table.css'

const HabitsTable = (props) => {
  const {habits, mode, viewPeriod, selectedDate, amendHabit} = props;

  const [nameColWidth, changeNameColWidth] = useState(300);
  useEffect(() => {
    if (window.innerWidth < 700) {
      changeNameColWidth(150);
    }
  }, []);

  if (!(habits && habits.length)) return null;

  const habitColumns = getHabitColumns(mode, viewPeriod, selectedDate, nameColWidth, amendHabit);
  const width = habitColumns.reduce(columnWidthReducer, 0);
  const tableClass = `habits-table ${viewPeriod}`;

  return <div>
    <Table
      rowKey="id"
      columns={habitColumns}
      dataSource={habits}
      bordered
      pagination={false}
      className={tableClass}
      scroll={{ x: width }}
      locale={{emptyText: 'No habits to display. Add some and start tracking your progress'}}
    />
  </div>
};

HabitsTable.propTypes = {
  mode: PropTypes.string,
  viewPeriod: PropTypes.string,
  selectedDate: PropTypes.object,
  habits: PropTypes.array
};

export default HabitsTable
