import {cloneDeep, sortBy, max} from "lodash";
import {getPeriodMoment} from "../utils/date-utils";
import {
  arrayIncludesArray,
  filter,
  filtersFit, isNull,
  lessThen,
  moreThen,
  yearPeriodIndexTimeMachine
} from "../utils/item-utils";
import {DATE_OPTIONS, DEFULT_SORT_ATTRIBUTES, IS_NULL, ITEM_TYPE} from "../constants";
import moment from 'moment'

export const addToParentChildren = (byId) => (id) => {
  const entity = byId[id]

  if (!entity) {
    console.log(id)
  }

  if (entity && entity.parentId) {
    if (!byId[entity.parentId]) {
      byId[id].parentId = ''
      return
    }

    if (!byId[entity.parentId].children) {
      byId[entity.parentId].children = [entity]
    } else {
      byId[entity.parentId].children.push(entity)
    }
  }
}

export const createItemsTree = (byId, sortAttributes = DEFULT_SORT_ATTRIBUTES) => {
  const itemsById = cloneDeep(byId)
  const allIds = Object.keys(byId)

  allIds.forEach(addToParentChildren(itemsById))
  allIds.forEach(itemId => {
    let item = itemsById[itemId]
    if (item.children && item.children.length) {
      item.children = sortBy(item.children, sortAttributes)
    }
  })

  return itemsById
}

export const getDoneWithinPeriodFilters = (period, year, index) => {
  const date = getPeriodMoment(year, period, index)
  const startTime = date.clone().startOf(period).valueOf()
  const endTime = date.clone().endOf(period).valueOf()

  const filters = [
    filter('type', ITEM_TYPE.ACTION, true),
    filter('done', true, true),
    filter('doneDate', startTime, false, moreThen),
    filter('doneDate', endTime, false, lessThen)
  ]

  return filters
}

export const getPlannedWithinPeriodFilters = (period, year, index) => {
  const date = getPeriodMoment(year, period, index)
  const startTime = date.clone().startOf(period).valueOf()
  const endTime = date.clone().endOf(period).valueOf()

  const filters = [
    filter('type', ITEM_TYPE.ACTION, true),
    filter('done', false, true),
    filter('dateFrom', startTime, false, moreThen),
    filter('dateFrom', endTime, false, lessThen)
  ]

  return filters
}

const itemParentIdsReducer = (itemsById) => (pIds, item) => {
  return item.parentId && !itemsById[item.parentId] && !pIds.includes(item.parentId) ? [item.parentId, ...pIds] : pIds
}

const itemAllParentIdsReducer = (itemsById, allItemsById) => (pIds, item) => {
  let newIds = []
  const isNew = pId => !(itemsById[pId] || pIds.includes(pId))

  if(item.parentId) {
    const path = getItemPath(allItemsById, item.parentId)
    newIds = path.map(p => p.id).filter(isNew)
  }

  return [...newIds, ...pIds]
}

export const getItemsParents = (items, itemsById, byId, allParents=false) => {
  return items
    .reduce(
      (allParents ?
        itemAllParentIdsReducer(itemsById, byId) :
        itemParentIdsReducer(itemsById)),
      []
    )
    .filter(id => !!byId[id])
    .map(id => byId[id])
}

const fillResObjects = (resById) => item => {
  resById[item.id] = item
}

export const createItemsArrayTree = items => {
  let resById = {}

  items.forEach(fillResObjects(resById))

  return createItemsTree(resById)
}

export const topItemsOnly = (byId, sortAttributes = DEFULT_SORT_ATTRIBUTES) => {
  const topArray = Object.keys(byId)
    .filter(key => !byId[key].parentId)
    .map(key => byId[key])

  return sortBy(topArray, sortAttributes)
}

export const getFilteredTmItemsTree = (byId, filters, model=(i) => i, period, year, index) => {
  const allIds = Object.keys(byId)

  const items = allIds
    .map(id => byId[id])
    .map(model)
    .map(yearPeriodIndexTimeMachine(year, period, index))
    .filter(item => filtersFit(item, filters))
    .map(item => ({...item, filtered: true}))

  const itemsById = items.reduce(arrayToObjectByIdReducer, {})

  const parents = getItemsParents(items, itemsById, byId)
    .map(model)
    .map(yearPeriodIndexTimeMachine(year, period, index))

  const finalById = createItemsArrayTree(parents.concat(items))

  return topItemsOnly(finalById)
}

export const getItemPath = (byId, itemId) => {
  let item = byId[itemId]

  if(!item) return []

  let ids = [itemId]
  let path = [{id: item.id, name: item.name}]

  while(item.parentId && byId[item.parentId] && !ids.includes(item.parentId)) {
    ids.push(item.parentId)
    item = byId[item.parentId]
    path = [{id: item.id, name: item.name}, ...path]
  }

  return path
}

export const getSearchFilters = ({search, tags, period, type,
                                   dateFrom, dateTo,
                                   dateCreatedFrom, dateCreatedTo,
                                   done, doneDateFrom, doneDateTo}) => {
  let filters = []

  if (search) filters.push(filter('searchString', search))
  if (type) filters.push(filter('type', type, true))
  if (period) filters.push(filter('period', period, true))
  if (dateFrom) {
    if (dateFrom === IS_NULL) {
      filters.push(filter('dateFrom', dateFrom, false, isNull))
    } else {
      filters.push(filter('dateFrom', dateFrom, false, moreThen))
    }
  }
  if (dateTo) filters.push(filter('dateTo', dateTo, false, lessThen))
  if (dateCreatedFrom) filters.push(filter('dateCreated', dateCreatedFrom, false, moreThen))
  if (dateCreatedTo) filters.push(filter('dateCreated', dateCreatedTo, false, lessThen))
  if (tags && tags.length > 0) filters.push(filter('tags', tags, false, arrayIncludesArray))

  if (done || done === false) filters.push(filter('done', done, true))
  if (doneDateFrom) filters.push(filter('doneDate', doneDateFrom, false, moreThen))
  if (doneDateTo) filters.push(filter('doneDate', doneDateTo, false, lessThen))

  return filters
}

export const searchFilter = (filters, viewItemId, timeInThePast=0) => item => item.id === viewItemId || filtersFit(item, filters, timeInThePast)

export const yearPeriodIndexFilter = (year, period, index) => item => (item.year === year && item.period === period && item.index === index)

export const itemsTagsList = byId => {
  const allIds = Object.keys(byId)
  const tagsSet = new Set()

  allIds.forEach(id => {
    if (byId[id].tags) {
      byId[id].tags.forEach(tag => tagsSet.add(tag))
    }
  })

  return Array.from(tagsSet)
}

export const getFilteredItemsTree = (byId, filters, model=(i) => i, topId, viewItemId, timeInThePast) => {
  const allIds = Object.keys(byId)

  const items = allIds
    .map(id => byId[id])
    .map(model)
    .filter(searchFilter(filters, viewItemId, timeInThePast))

  items.forEach(item => {
    if (searchFilter(filters, viewItemId)(item)) {
      item.filtered = true
    }
  })

  const itemsById = items.reduce(arrayToObjectByIdReducer, {})

  const parents = getItemsParents(items, itemsById, byId, true)
    .map(model)

  const finalById = createItemsArrayTree(parents.concat(items))

  const tree = (topId && finalById[topId]) ? [finalById[topId]] : topItemsOnly(finalById)

  return {items, parents, tree, byId: finalById}
}

const arrayToObjectByIdReducer = (p, i) => ({...p, [i.id]: i})

export const getChildParentIds = (itemsWithChildren) => (id) => {
  const item = itemsWithChildren[id]

  if (!(item && item.children && item.children.length)) return []

  let childParentIds = []
  item.children.forEach(child => {
    if (child.children && child.children.length) {
      const childIds = getChildParentIds(itemsWithChildren)(child.id)
      childParentIds.push(child.id)
      childParentIds = childParentIds.concat(childIds)
    }
  })

  return childParentIds
}

export const getRootItemsMaxOrderValue = byId => {
  const ordersArray = Object.keys(byId)
    .map(id => byId[id])
    .filter(item => !item.parentId)
    .map(item => item.order)

  return max(ordersArray) || 0
}

export const fillTreeItemsPath = (items, byId, path = []) => {
  items.forEach(item => {
    let parentData = null
    let parentPath = [...path]

    if (item.parentId) {
      const parentName = byId[item.parentId].name
      parentData = {id: item.parentId, name: parentName}
    }

    if (parentData) {
      parentPath.push(parentData)
    }

    byId[item.id].parentPath = parentPath

    if (item.children && item.children.length) {
      fillTreeItemsPath(item.children, byId, parentPath)
    }
  })

  return byId
}

const periods = [DATE_OPTIONS.DAY, DATE_OPTIONS.WEEK, DATE_OPTIONS.MONTH, DATE_OPTIONS.YEAR]

export const dateWeightTimeSorting = (a, b) => {
  const aTimeFrom = a.timeFrom ? moment(a.timeFrom) : 0
  const aHours = aTimeFrom ? aTimeFrom.hour() : 23
  const aMinutes = aTimeFrom ? aTimeFrom.minute() : 60
  const aTime = aHours * 60 + aMinutes

  const aUrgent = 7 - ((+a.urgency) || 0)
  const aImportance = 7 - ((+a.importance) || 0)
  const aWeight = aUrgent + aImportance

  const bTimeFrom = b.timeFrom ? moment(b.timeFrom) : 0
  const bHours = bTimeFrom ? bTimeFrom.hour() : 23
  const bMinutes = bTimeFrom ? bTimeFrom.minute() : 60
  const bTime = bHours * 60 + bMinutes

  const bUrgent = 7 - ((+b.urgency) || 0)
  const bImportance = 7 - ((+b.importance) || 0)
  const bWeight = bUrgent + bImportance

  return a.year - b.year || a.day - b.day || aTime - bTime || aWeight - bWeight || a.name.localeCompare(b.name)
}

export const periodAndWeightSorting = (a, b) => {
  const aPeriod = periods.indexOf(a.period) + 1

  const aUrgent = 7 - ((+a.urgency) || 0)
  const aImportance = 7 - ((+a.importance) || 0)
  const aWeight = aUrgent + aImportance

  const bPeriod = periods.indexOf(b.period) + 1

  const bUrgent = 7 - ((+b.urgency) || 0)
  const bImportance = 7 - ((+b.importance) || 0)
  const bWeight = bUrgent + bImportance

  return aPeriod - bPeriod || aWeight - bWeight || a.name.localeCompare(b.name)
}

export const weightSorting = (a, b) => {
  const aUrgent = 7 - ((+a.urgency) || 0)
  const aImportance = 7 - ((+a.importance) || 0)
  const aWeight = aUrgent + aImportance

  const bUrgent = 7 - ((+b.urgency) || 0)
  const bImportance = 7 - ((+b.importance) || 0)
  const bWeight = bUrgent + bImportance

  return aWeight - bWeight || a.name.localeCompare(b.name)
}

export const getPeriodParams = () => {
  const weekPeriod = moment().add(1, 'w').endOf('day')
  const weekPeriodYear = weekPeriod.year()
  const weekPeriodDay = weekPeriod.dayOfYear()

  const monthPeriod = moment().add(1, 'M').endOf('day')
  const monthPeriodYear = monthPeriod.year()
  const monthPeriodDay = monthPeriod.dayOfYear()

  const yearPeriod = moment().add(1, 'y').endOf('day')
  const yearPeriodYear = yearPeriod.year()
  const yearPeriodDay = yearPeriod.dayOfYear()

  return {
    [DATE_OPTIONS.WEEK]: {
      year: weekPeriodYear,
      day: weekPeriodDay,
    },
    [DATE_OPTIONS.MONTH]: {
      year: monthPeriodYear,
      day: monthPeriodDay,
    },
    [DATE_OPTIONS.YEAR]: {
      year: yearPeriodYear,
      day: yearPeriodDay,
    },
  }
}
