import {findIndex, sortBy} from "lodash";
import {itemsBulkUpdate, itemsSelect, itemUpdate} from "../actions/item-actions";

export const moveSelected = ({items, itemsWithChildren, expandedRowKeys, selectedItemsIds, dispatchAction, expandRow}) => (colDelta, rowDelta=0) => {
  if (!selectedItemsIds.length) return;

  const oneParent = areIdsFromSameParent(itemsWithChildren)(selectedItemsIds);

  if (!oneParent) return;

  const firstItem = itemsWithChildren[selectedItemsIds[0]];
  const groups = getSelectedGroups(items, itemsWithChildren, expandedRowKeys)(selectedItemsIds);
  const parentChildren = firstItem.parentId ? itemsWithChildren[firstItem.parentId].children : items;

  if(colDelta !== 0) {
    if (colDelta > 0) {   // move down
      groups.forEach(moveSelectedGroupDown(parentChildren, dispatchAction))
    } else {  // move up
      groups.forEach(moveSelectedGroupUp(parentChildren, dispatchAction))
    }
  } else if (rowDelta !== 0) {
    if (rowDelta > 0){ // move right
      groups.forEach(moveSelectedGroupRight(parentChildren, dispatchAction, expandRow))
    } else {  // move left
      moveSelectedGroupsLeft(parentChildren, dispatchAction, itemsWithChildren, selectedItemsIds, items)(groups)
    }
  }
}

const moveSelectedGroupDown = (parentChildren, dispatchAction) => (group) => {
  const lastItemIndex = findIndex(parentChildren, {id: group[group.length-1].id});

  // check whether group is not on the bottom
  if (lastItemIndex !== (parentChildren.length -1)) {
    // move the next item after group on the top of it
    const nextItem = parentChildren[lastItemIndex+1];

    // check if group is on the top and calculate order
    const firstItemIndex = findIndex(parentChildren, {id: group[0].id});
    let newOrder = 0;
    if (firstItemIndex === 0) {
      newOrder = group[group.length-1].order / 2
    } else {
      const firstItemOrder = parentChildren[firstItemIndex].order;
      const previousItemOrder = parentChildren[firstItemIndex-1].order;

      newOrder = previousItemOrder + (firstItemOrder - previousItemOrder) / 2
    }

    dispatchAction(itemUpdate({id: nextItem.id, order: newOrder}))
  }
};

const moveSelectedGroupUp = (parentChildren, dispatchAction) => group => {
  const firstItemIndex = findIndex(parentChildren, {id: group[0].id});

  // check whether group is not on the top
  if (firstItemIndex !== 0) {
    // move the previous item before group on the bottom of it
    const previousItem = parentChildren[firstItemIndex-1];

    // check is group is on the bottom and calculate order
    const lastItemIndex = findIndex(parentChildren, {id: group[group.length-1].id});

    let newOrder = 0;

    if (lastItemIndex === (parentChildren.length -1)) {
      newOrder = parentChildren[lastItemIndex].order + 1
    } else {
      const lastItemOrder = parentChildren[lastItemIndex].order;
      const nextItemOrder = parentChildren[lastItemIndex+1].order;
      newOrder = lastItemOrder + (nextItemOrder - lastItemOrder) / 2
    }

    dispatchAction(itemUpdate({id: previousItem.id, order: newOrder}))
  }
};

const moveSelectedGroupRight = (parentChildren, dispatchAction, expandRow) => group => {
  const firstItemIndex = findIndex(parentChildren, {id: group[0].id})

  // if group on top, it can't be moved as child for the item above
  if (firstItemIndex !== 0) {
    const aboveItem = parentChildren[firstItemIndex-1];
    console.log(aboveItem);
    const aboveItemLastOrder = aboveItem.children && aboveItem.children.length ? aboveItem.children[aboveItem.children.length-1].order : 0;

    const updateItems = group.map((item, i) => ({id: item.id, parentId: aboveItem.id, order: aboveItemLastOrder + 1 + i}));
    dispatchAction(itemsBulkUpdate(updateItems));

    expandRow(aboveItem.id)
  }
};

const moveSelectedGroupsLeft = (parentChildren, dispatchAction, itemsWithChildren, selectedItemsIds, items) => (groups) => {
  if(!groups[0][0].parentId) return;

  const parentItem = itemsWithChildren[groups[0][0].parentId];
  const parentParentChildren = parentItem.parentId ? itemsWithChildren[parentItem.parentId].children : items;

  const parentIndex = findIndex(parentParentChildren, {id: parentItem.id});
  const isLastIndex = parentIndex === (parentParentChildren.length - 1);
  const nextItemOrder = isLastIndex ? 0 : parentParentChildren[parentIndex + 1].order;
  const orderStep = isLastIndex ? 1 : (nextItemOrder - parentItem.order) / (selectedItemsIds.length + 1);

  let orderIncrement = 0;
  let updateItems = [];

  for (let g=0; g<groups.length; g++) {
    const group = groups[g];

    for (let i = 0; i < group.length; i++) {
      const id = group[i].id;
      const parentId = parentItem.parentId || null;
      const order = parentItem.order + (++orderIncrement) * orderStep;

      updateItems.push({id, parentId, order})
    }
  }

  dispatchAction(itemsBulkUpdate(updateItems))
};

// selection change
export const moveSelection = ({items, itemsWithChildren, selectedItemsIds, dispatchAction, expandedRowKeys, expandRow, collapseRow}) => (colDelta, rowDelta=0) => {
  const visibleItemsArray = visibleItems(items, expandedRowKeys, itemsWithChildren);

  if(!items.length) return;

  let newSelectionId = items[0].id;

  if (selectedItemsIds.length) {
    const id = selectedItemsIds[0];
    newSelectionId = id;
    const item = itemsWithChildren[id];

    if(colDelta !== 0) {
      const index = findIndex(visibleItemsArray, {id});
      const newIndex = (visibleItemsArray.length + index + colDelta) % visibleItemsArray.length;
      newSelectionId = visibleItemsArray[newIndex].id
    } else if (rowDelta !== 0) {
      if (rowDelta > 0) {
        if (item.children && item.children.length) {
          expandRow(item.id)
        }
      } else {
        if (expandedRowKeys.includes(id)) {
          collapseRow(id)
        } else if (item.parentId) {
          newSelectionId = item.parentId;
          collapseRow(item.parentId)
        }
      }
    }
  }

  dispatchAction(itemsSelect([newSelectionId]))
};

export const visibleItems = (items, expandedRowKeys=[], itemsWithChildren) => {
  let visibleItemsArray = []

  items.forEach(item => {
    visibleItemsArray.push(item)
    if (item.children && item.children.length && expandedRowKeys.includes(item.id)) {
      const visibleChildren = visibleItems(item.children, expandedRowKeys, itemsWithChildren);
      visibleItemsArray = visibleItemsArray.concat(visibleChildren)
    }
  });

  return visibleItemsArray
};

export const getItemParentChildren = (itemsWithChildren, items) => (id) => {
  const item = itemsWithChildren[id];
  return item.parentId ? (itemsWithChildren[item.parentId].children || []) : items
};

export const getIndexInParentChildren = (itemsWithChildren, items) => (id) => {
  const parentChildren = getItemParentChildren(itemsWithChildren, items)(id);
  return findIndex(parentChildren, {id})
};

export const firstInTheList = (itemsWithChildren, items) => (id) => {
  return getIndexInParentChildren(itemsWithChildren, items)(id) === 0
};

export const lastInTheList = (itemsWithChildren, items) => (id) => {
  const parentChildren = getItemParentChildren(itemsWithChildren, items)(id)
  return getIndexInParentChildren(itemsWithChildren, items)(id) === (parentChildren.length-1)
};

export const onTheRootLevel = byId => id => {
  return !byId[id].parentId
};

export const onTheBottomLevel = itemsWithChildren => id => {
  const item = itemsWithChildren[id]
  return !(item.children && item.children.length)
};

export const areIdsFromSameParent = (byId) => ids => {
  if (ids) {
    const parentsSet = new Set()

    ids.forEach(id => {
      parentsSet.add(byId[id] && byId[id].parentId ? byId[id].parentId : 'null')
    });

    return parentsSet.size === 1
  }

  return false
};

export const getSelectedGroups = (items, itemsWithChildren, expandedRowKeys) => (ids) => {
  if (!(ids && ids.length)) return [[]];

  const visibleItemsArray = visibleItems(items, expandedRowKeys, itemsWithChildren);

  const haosItems = ids.map(id => {
    let item = itemsWithChildren[id];
    item.index = findIndex(visibleItemsArray, {id});

    return item
  });

  const sortedItems = sortBy(haosItems, ['index']);

  let groups = [[sortedItems[0]]];
  for (let i=1; i<sortedItems.length; i++) {
    const curItem = sortedItems[i];
    const preItem = sortedItems[i-1];

    if ((curItem.index - 1) > preItem.index) {
      groups.push([])
    }

    groups[groups.length-1].push(curItem)
  }

  return groups
};
