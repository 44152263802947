import {createSelector} from "reselect";
import {sortBy} from "lodash";
import {getSearchFilters, itemsTagsList, searchFilter} from "./selector-utils";
import {ownPropsSelector} from "./own-props-selectors";
import noteModel from "../models/note-model";

export const notesSelector = state => state.notes
export const selectedIdSelector = state => state.notes.selectedId

export const notesListSelector = createSelector(
  notesSelector,
  ownPropsSelector,
  ({byId}, ownProps) => {
    const filters = getSearchFilters(ownProps);

    const notesList = Object.keys(byId)
      .map(id => byId[id])
      .map(noteModel)
      .filter(searchFilter(filters));

    return sortBy(notesList, [function(res) { return -res.dateCreated }])
  }
);

export const selectedNoteSelector = createSelector(
  notesSelector,
  selectedIdSelector,
  ({byId}, selectedId) => {
    return byId[selectedId]
  }
)

export const notesTagsSelector = createSelector(
  notesSelector,
  ({byId}) => {
    return itemsTagsList(byId)
  }
)
