import { STORAGE_ACTION } from '../types'

export const saveToStorage = () => ({
  type: STORAGE_ACTION.SAVE
})

export const receivedFromStorage = (state) => ({
  type: STORAGE_ACTION.RECEIVE,
  state
})

export const restoreFromStorage = (state) => ({
  type: STORAGE_ACTION.RESTORE,
  state
})