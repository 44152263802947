import React from 'react'
import {connect} from 'react-redux'
import { Button } from 'antd'
import PropTypes from "prop-types";
import {userSelector} from '../../../common/selectors/user-selectors'
import {doSignOut} from '../../../common/utils/firebase/auth'
import {textMethod} from "../../../common/utils";

class AuthButton extends React.Component {
  render() {
    const {logIn, user, text} = this.props

    const onClick = user ? doSignOut : logIn
    const title = user ? text('TOP_MENU.LOG_OUT') : text('TOP_MENU.LOG_IN')

    return <Button onClick={onClick}>{title}</Button>
  }
}

AuthButton.propTypes = {
  logIn: PropTypes.func,
  user: PropTypes.object
}

const mapStateToProps = state => {
  return {
    user: userSelector(state),
    text: textMethod(state)
  }
}

export default connect(mapStateToProps)(AuthButton)