import React from 'react'
import {connect} from 'react-redux'
import PropTypes from "prop-types";
import {
  doneItemsWithParentsSelector,
  plannedItemsWithParentsSelector
} from "../../../../../common/selectors/goals-selectors";
import { Table } from 'antd'
import doneNameRenderer from './done-name-renderer/done-name-renderer'
import plannedNameRenderer from './planned-name-renderer/planned-name-renderer'
import './period-items.css'
import {DEFAULT_TODAY_RESULT} from "../../common/constants";

const nameColumn = (columnTitle, renderer) => ({
  title: columnTitle,
  key: 'listName',
  render: renderer
});

const tableProps = {
  pagination: false,
  size: 'small',
  bordered: false,
  rowKey: "id",
  defaultExpandAllRows: true
};

const PeriodItems = (props) => {
  const {doneItems, plannedItems, selectedPeriod} = props;
  const {period, year, index} = selectedPeriod || DEFAULT_TODAY_RESULT;

  if (!period || !year || !index) return null;

  return <div className='period-items'>
    <Table {...tableProps}
           columns={[nameColumn('Done items', doneNameRenderer())]}
           dataSource={doneItems}
           locale={{emptyText: 'There is nothing done yet. Keep working!'}}
    />

    <Table {...tableProps}
           columns={[nameColumn('Planned items', plannedNameRenderer({period, year, index}))]}
           dataSource={plannedItems}
           locale={{emptyText: 'No plans! Relax, or pick something up from actions'}}
    />
  </div>
};

PeriodItems.propTypes = {
  period: PropTypes.string,
  year: PropTypes.number,
  index: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => {
  const {selectedPeriod} = ownProps;
  const {period, year, index} = selectedPeriod || DEFAULT_TODAY_RESULT;

  return {
    selectedPeriod,
    doneItems: doneItemsWithParentsSelector(state, {period, year, index}),
    plannedItems: plannedItemsWithParentsSelector(state, {period, year, index}),
  }
};

export default connect(mapStateToProps)(PeriodItems)
