import "regenerator-runtime/runtime";

import { put, takeEvery, all } from 'redux-saga/effects'
import { RESULT_ACTION } from '../types'
import { resultIdSelect } from "../actions/result-actions"

function* selectNewResult(action) {
  yield put(resultIdSelect(action.result.id));
}

// sagas
function* watchResultAdd() {
  yield takeEvery(RESULT_ACTION.ADD_RESULT, selectNewResult);
}

function* itemsSaga() {
  yield all([
    watchResultAdd()
  ])
}

export default itemsSaga;