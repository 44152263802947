import React, {useRef} from 'react'
import { useDrop } from 'react-dnd';
import './items-table-row.css'
import {useCustomEvent} from "../../../../../../../../common/hooks/customEvents";
import {CUSTOM_EVENTS} from "../../../../../../../../common/types";

const BodyRow = (props) => {
  const {
    id,
    selected,
    connectDragSource,
    connectDropTarget,
    dragRow,
    clientOffset,
    sourceClientOffset,
    initialClientOffset,
    dispatch,
    updateparent,
    ...restProps
  } = props;

  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop(() => ({
    accept: 'DraggableAction',
    collect: monitor => {
      const { id: dragIndex } = monitor.getItem() || {};
      if (dragIndex === id) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < id ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      const dragId = item.id;
      const hoverId = id;

      // Don't replace items with themselves
      if (dragId === hoverId) {
        return;
      }

      // Time to actually perform the action
      props.updateparent(dragId, hoverId);
    },
  }), [id]);

  drop(ref);

  const {id: draggingId = null, isDragging = false} = useCustomEvent(CUSTOM_EVENTS.DRAG_ACTION) || {};
  const opacity = isDragging && id === draggingId ? 0 : 1;

  const style = { ...restProps.style, opacity };

  let className = 'action-item-tr ' + restProps.className + (selected ? ' selected' : '') + (isOver ? ` ${dropClassName}` : '');

  return  <tr
    ref={ref}
    {...restProps}
    className={className}
    style={style}
  />
};

export default BodyRow
