import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { Form, Input, Button, message } from 'antd';
import { MailOutlined } from '@ant-design/icons'
import {mapDispatchActionToProps, textMethod} from "../../../common/utils/index";
import {doPasswordReset} from '../../../common/utils/firebase/auth'
import Text from "../../i18n/text";

const FormItem = Form.Item;

class LoginForm extends React.Component {
  formRef = React.createRef();

  render() {
    const {login, text} = this.props;

    return (
      <Form ref={this.formRef} onFinish={this.handleSubmit} className="login-form">
        <FormItem name="email" rules={[{ required: true, message: text('AUTH.LOGIN.ERR.EMAIL_REQUIRED') }]}>
          <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={text('AUTH.LOGIN.EMAIL_PLACEHOLDER')} />
        </FormItem>

        <div className='buttons-holder'>
          <Button type="link" className="padding0" onClick={login}>
            <Text path="AUTH.PASS_RESET.LOGIN"/>
          </Button>
          <Button type="primary" htmlType="submit" className="login-form-button">
            <Text path="AUTH.PASS_RESET.RESET"/>
          </Button>
        </div>
      </Form>
    );
  }

  handleSubmit = (values) => {
    const {cancel} = this.props;

    const {email} = values;
    const settings = {
      url: `http://eddy-planner.com/?email=${email}`
    };

    doPasswordReset(email, settings)
      .then(function() {
        return message.success('Password reset email sent.')
      })
      .catch(function(error) {
        console.log(error);
        return message.error('Something went wrong. Please try later')
      });

    cancel();
  }
}

LoginForm.propTypes = {
  register: PropTypes.func,
  cancel: PropTypes.func
};

const mapStateToProps = (state) => ({
  text: textMethod(state)
});

export default connect(mapStateToProps, mapDispatchActionToProps)(LoginForm)
