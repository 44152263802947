import {DATE_OPTIONS} from "../../../../../common/constants";

export const DATE_CREATED_OPTIONS = {
  IN15M: {
    value: 'IN15M',
    label: 'Last 15 min'
  },
  IN1H: {
    value: 'IN1H',
    label: 'Last hour'
  },
  TODAY: {
    value: 'TODAY',
    label: 'Today'
  },
  YESTERDAY: {
    value: 'YESTERDAY',
    label: 'Yesterday'
  },
  NA: {
    value: 'NA',
    label: 'Reset'
  }
};

export const DONE_OPTIONS = {
  //DONE: {
  //  value: 'DONE',
  //  label: 'Done'
  //},
  //ALL: {
  //  value: 'ALL',
  //  label: 'All'
  //},
  IN15M: {
    value: 'IN15M',
    label: 'Last 15 min'
  },
  TODAY: {
    value: 'TODAY',
    label: 'Today'
  },
  YESTERDAY: {
    value: 'YESTERDAY',
    label: 'Yesterday'
  },
  NOT_DONE: {
    value: 'NOT_DONE',
    label: 'Reset'
  }
};

export const PERIOD_OPTIONS = {
  DAILY: { label: 'Daily', value: DATE_OPTIONS.DAY },
  WEEKLY: { label: 'Weekly', value: DATE_OPTIONS.WEEK },
  MONTHLY: { label: 'Monthly', value: DATE_OPTIONS.MONTH },
  YEARLY: { label: 'Yearly', value: DATE_OPTIONS.YEAR },
  ANY: { label: 'Reset', value: '' }
};
