import { HABIT_ACTION } from '../types'

export const habitsSelect = ids => ({
  type: HABIT_ACTION.SELECT_HABIT,
  ids
})

export const habitAdd = habit => ({
  type: HABIT_ACTION.ADD_HABIT,
  habit
})

export const habitsBulkAdd = habits => ({
  type: HABIT_ACTION.BULK_ADD_HABITS,
  habits
})

export const habitDelete = ids => ({
  type: HABIT_ACTION.DELETE_HABIT,
  ids
})

export const habitUpdate = habit => ({
  type: HABIT_ACTION.UPDATE_HABIT,
  habit
})

export const habitsBulkUpdate = habits => ({
  type: HABIT_ACTION.BULK_UPDATE_HABITS,
  habits
})

export const habitsViewModeChange = mode => ({
  type: HABIT_ACTION.VIEW_MODE_CHANGE,
  mode
})

export const habitsViewPeriodChange = viewPeriod => ({
  type: HABIT_ACTION.VIEW_PERIOD_CHANGE,
  viewPeriod
})

export const habitChange = (id, year, index, done, params = null) => ({
  type: HABIT_ACTION.HABIT_CHANGE,
  id,
  year,
  index,
  done,
  params
})