import React from 'react'
import {ITEM_TYPE} from "../../../../../../common/constants";
import PlannedActionNameRenderer from './planned-action-name-renderer'
import PlannedHabitNameRenderer from './planned-habit-name-renderer'

export default (result) => (text, record) => {
  const params = {record, result};

  if (record.type === ITEM_TYPE.HABIT) {
    return <PlannedHabitNameRenderer {...params}/>
  } else {
    return <PlannedActionNameRenderer {...params}/>
  }
}
