import React from 'react'
import {connect} from 'react-redux'
import { Checkbox, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons'
import {getItemPath} from "../../../../../../common/selectors/selector-utils";
import {goalsSelector} from "../../../../../../common/selectors/goals-selectors";
import {mapDispatchActionToProps, textMethod} from "../../../../../../common/utils/index";
import {habitChange} from "../../../../../../common/actions/item-actions";
import {isHabitChecked} from "../../../../habits/common/utils";

class PlannedHabitNameRenderer extends React.Component {
  render() {
    const {record, items:{byId}, text} = this.props;

    const isHabit = record.filtered;
    const className = 'habit-list-name' + (isHabit ? ' habit' : '');

    const nameTitle = isHabit ? record.name : getItemPath(byId, record.id).map(i => i.name).join(' > ');
    const habitTitle = text('CONSTANTS.HABIT');

    return <span className={className}>
      {(isHabit && this.getDoneCheckbox()) || null}

      <Tooltip title={nameTitle} placement="topLeft">
        <span className='long-name'>{record.name}</span>
      </Tooltip>

      <Tooltip title={habitTitle}>
        <span className='float-right'><ReloadOutlined /></span>
      </Tooltip>
    </span>
  }

  getDoneCheckbox() {
    const {record, result, dispatchAction} = this.props;

    const doneChange = e => {
      const done = e.target.checked;
      dispatchAction(habitChange(record.id, result.year, result.index, done))
    };

    const checked = isHabitChecked(record, result.year, result.index)
    return <Checkbox checked={checked} onChange={doneChange}/>
  }
}

const mapStateToProps = (state, ownProps) => ({
  items: goalsSelector(state, ownProps),
  text: textMethod(state)
});

export default connect(mapStateToProps, mapDispatchActionToProps)(PlannedHabitNameRenderer)
