import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import registerServiceWorker from './registerServiceWorker'

import appReducer from './common/reducers/app-reducer'
import appSaga from './common/saga/sagas'
import App from './pages/app'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const store = createStore(appReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(appSaga);

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('eddy')
);

registerServiceWorker();
