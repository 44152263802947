import {minifyString} from "../utils/index";

const MAX_LIST_NAME_LENGTH = 30
const NO_NAME = 'No Name'

export default (note) => ({
  ...note,
  listName: minifyString(note.name || NO_NAME, MAX_LIST_NAME_LENGTH),
  sortTime: note.dateCreated,
  searchString: `${note.name} ${note.content}`
})