import {PROPERTIES_ACTION, STORAGE_ACTION} from "../types";
import {combineReducers} from "redux";
import {goalsViewSelector} from "../selectors/properties-selectors";

export const initialProperties = {
  goalsView: 'hierarchy'
}

export const goalsViewReducer = (state = initialProperties.goalsView, action) => {
  switch(action.type) {
    case PROPERTIES_ACTION.CHANGE_GOALS_VIEW: return action.view
    case STORAGE_ACTION.RESTORE: {
      const goalsView = goalsViewSelector(action.state)
      return goalsView ? goalsView : state
    }
    default: return state
  }
}

export default combineReducers({
  goalsView: goalsViewReducer
})