import React from 'react'
import {connect} from 'react-redux'
import {mapDispatchActionToProps} from "../../../../../common/utils";
import {itemsSelect} from "../../../../../common/actions/item-actions";
import {moveSelected, moveSelection} from "../../../../../common/utils/item-selection-utils";
import {doneSelected} from "../../../../../common/utils/item-utils";
import ItemsTable from "./components/items-table/items-table";
import {itemsTreeSelector} from "../../../../../common/selectors/goals-selectors";

class GoalsHierarchy extends React.Component {
  state = {
    ctrlPressed: false,
    shiftPressed: false,
  };

  render() {
    const tableProps = {
      ...this.props,
      ...this.state,
    };

    return <div
      tabIndex="1"
      onKeyDown={this.keyDownHandler}
      onKeyUp={this.keyUpHandler}
      className="goals-tree-table-wrapper"
    >
      <ItemsTable {...tableProps}/>
    </div>
  }

  // keyboard handlers
  keyDownHandler = ({key}) => {
    const {ctrlPressed, shiftPressed} = this.state

    if ((key === 'Control' || key === 'Meta') && !ctrlPressed) {
      this.setState({ctrlPressed: true})
    } else if ((key === 'Shift') && !shiftPressed) {
      this.setState({shiftPressed: true})
    }
  };

  keyUpHandler = event => {
    const {ctrlPressed, shiftPressed} = this.state

    if ((event.key === 'Control' || event.key === 'Meta') && ctrlPressed) {
      this.setState({ctrlPressed: false})
      return
    } else if ((event.key === 'Shift') && shiftPressed) {
      this.setState({shiftPressed: false})
      return
    }

    const moove = this.state.ctrlPressed ?
      moveSelected(this.props) :
      moveSelection(this.props)

    switch(event.key) {
      case 'Escape': {
        const {dispatchAction} = this.props
        dispatchAction(itemsSelect([]))
        break
      }
      case 'ArrowUp':
        moove(-1);
        break;
      case 'ArrowDown':
        moove(1);
        break;
      case 'ArrowLeft':
        moove(0, -1);
        break;
      case 'ArrowRight':
        moove(0, 1);
        break;
      case 'Enter':
        this.markSelectedDone();
        break;
      default: break
    }
  };

  markSelectedDone = () => {
    const {itemsWithChildren, selectedItemsIds} = this.props

    if (selectedItemsIds.length !== 1) return

    doneSelected(this.props, !itemsWithChildren[selectedItemsIds[0]].done)
  }
}

const mapStateToProps = (state, ownProps) => {
  const {tree, byId} = itemsTreeSelector(state, ownProps);

  return {
    items: tree,
    itemsWithChildren: byId,
  }
};

export default connect(mapStateToProps, mapDispatchActionToProps)(GoalsHierarchy)
