import {db} from './firebase'
import { ref, get, set, child } from "firebase/database";

export const persistUserData = (user, data, path='') => {
  return set(ref(db, `users/${user.uid + path}`), data);
};

export const getUserData = (user, path='') => {
  return get(child(ref(db), `users/${user.uid + path}`));
};

export const getUserDataRef = (user, path='') => {
  return ref(db, `users/${user.uid + path}`);
};
