import { combineReducers } from 'redux'
import { omit, clone } from 'lodash'
import {HABIT_ACTION, STORAGE_ACTION} from '../types'
import {DATE_OPTIONS, EMPTY_HABIT, ITEM_TYPE} from "../constants";
import {historyItem, updateWithHistory} from "../utils/item-utils";
import moment from 'moment'
import {habitsModeSelector, habitsSelector, habitsViewPeriodSelector} from "../selectors/habits-selectors";

export const initialItems = {
  byId: {},
  mode: DATE_OPTIONS.DAY,
  viewPeriod: DATE_OPTIONS.MONTH,
}

export const byIdReducer = (state = initialItems.byId, action) => {
  switch(action.type) {
    // add
    case HABIT_ACTION.ADD_HABIT: {
      const dateCreated = moment().valueOf()
      return {...state, [action.habit.id]: {...action.habit, dateCreated}}
    }
    case HABIT_ACTION.BULK_ADD_HABITS: {
      const dateCreated = moment().valueOf()
      const newHabits = action.habits.reduce((newById, habit) => ({...newById, [habit.id]: {...habit, dateCreated}}), {})
      return {...state, ...newHabits}
    }

    // delete
    case HABIT_ACTION.DELETE_HABIT: return omit(state, action.ids)

    // update
    case HABIT_ACTION.UPDATE_HABIT: {
      const updatedHabit = updateWithHistory(state, action.habit)
      return {...state, [updatedHabit.id]: updatedHabit}
    }
    case HABIT_ACTION.BULK_UPDATE_HABITS: {
      let updatedHabitsArr = []

      action.habits.forEach(habit => {
        const updatedHabit = updateWithHistory(state, habit)
        updatedHabitsArr.push(updatedHabit)
      })

      const updatedHabits = updatedHabitsArr.reduce((newById, habit) => ({...newById, [habit.id]: habit}), {})

      return {...state, ...updatedHabits}
    }

    case HABIT_ACTION.HABIT_CHANGE: {
      const item = state[action.id]
      const {type, period, habit} = item

      if (type === ITEM_TYPE.ACTION) return state
      let newHabit = habit ? clone(habit) : {}

      if(!newHabit[period]) newHabit[period] = {}
      if(!newHabit[period][action.year]) newHabit[period][action.year] = {}
      if(!newHabit[period][action.year][action.index]) newHabit[period][action.year][action.index] = clone(EMPTY_HABIT)

      let habitItem = newHabit[period][action.year][action.index]

      if (habitItem.done !== action.done) {
        const hItem = historyItem('done', habitItem.done, action.done)
        habitItem.history.push(hItem)
      }

      habitItem.done = action.done

      if (action.params) habitItem.params = action.params

      const updatedItem = {...item, habit: newHabit}

      return {...state, [updatedItem.id]: updatedItem}
    }
    case STORAGE_ACTION.RESTORE: {
      const habits = habitsSelector(action.state)
      return habits ? {...habits.byId} : state
    }
    default: return state
  }
}

export const modeReducer = (state = initialItems.mode, action) => {
  if (action.type === HABIT_ACTION.VIEW_MODE_CHANGE) {
    return action.mode
  } else if (action.type === STORAGE_ACTION.RESTORE) {
    const mode = habitsModeSelector(action.state)
    return mode ? mode : state
  }

  return state
}

export const viewPeriodReducer = (state = initialItems.viewPeriod, action) => {
  if (action.type === HABIT_ACTION.VIEW_PERIOD_CHANGE) {
    return action.viewPeriod
  } else if (action.type === STORAGE_ACTION.RESTORE) {
    const viewPeriod = habitsViewPeriodSelector(action.state)
    return viewPeriod ? viewPeriod : state
  }

  return state
}

export default combineReducers({
  byId: byIdReducer,
  mode: modeReducer,
  viewPeriod: viewPeriodReducer
})