import { USER_ACTION } from '../types'

export const userLogin = user => ({
  type: USER_ACTION.LOGIN,
  user
});

export const userLogout = user => ({
  type: USER_ACTION.LOGOUT,
  user
});
