import React, {useEffect, useState} from 'react'
import {Input} from "antd";
import {useDebounce} from "../../../../../../common/hooks/useDebounce";
import {eventValue} from "../../../../../../common/utils";

export default (props) => {
  const {onChange, value, placeholder, rows} = props;

  const [localValue, changeLocalValue] = useState(value);
  useEffect(() => {
    changeLocalValue(value);
  }, [value]);

  const debouncedValue = useDebounce(localValue, 1000);
  useEffect(() => {
    if (debouncedValue) {
      onChange(localValue);
    }
    return () => {};
  }, [debouncedValue]);

  const valueChangeHandler = (e) => {
    changeLocalValue(eventValue(e))
  };

  return <Input.TextArea
    value={localValue}
    placeholder={placeholder}
    onChange={valueChangeHandler}
    rows={rows}
  />
}
