import { Modal } from 'antd'
import {habitDelete} from "../actions/habit-actions";

export const deleteHabit = (habit, dispatchAction) => {
  Modal.confirm({
    title: 'Are you sure?',
    content: `You are going to delete '${habit.name}' habit`,
    okText: 'Delete',
    okType: 'danger',
    onOk(){
      dispatchAction(habitDelete([habit.id]))
    }
  })
}