import "regenerator-runtime/runtime";

import { takeLatest, all, put, select, call, take } from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import {
  STORAGE_ACTION,
  ITEM_ACTION,
  VIEW_ACTION,
  USER_ACTION,
  RESULT_ACTION,
  NOTE_ACTION,
  I18N_ACTION,
  HABIT_ACTION,
  PROPERTIES_ACTION
} from '../types'
import {restoreFromStorage, saveToStorage} from "../actions/storage-actions"
import {getUserData, persistUserData} from "../utils/firebase/db";
import {omit} from "lodash";
import {lastUpdateTimeSelector, stateSelector} from "../selectors/app-selectors";
import {itemHabitsSelector} from "../selectors/goals-selectors";
import {habitsArraySelector} from "../selectors/habits-selectors";
import {habitsBulkAdd} from "../actions/habit-actions";
import {itemsBulkDelete} from "../actions/item-actions";

// server side updates
function* persistToFirebase() {
  try {
    const state = yield select(stateSelector)
    yield call(persistUserData, state.user, omit(state, 'user'))
  } catch (e) {
    console.log(`Error trying to persist state: ${e}`)
  }
}

function* restoreIfNewUpdate(action) {
  const localUpdateTime = yield select(lastUpdateTimeSelector)
  const incomingUpdateTime = lastUpdateTimeSelector(action.state)

  if (incomingUpdateTime > localUpdateTime) {
    yield put(restoreFromStorage(action.state))
  }
}

// sagas
function* initFromFirebase() {
  while (true) {
    const loginAction = yield take(USER_ACTION.LOGIN)
    const {user} = loginAction
    try {
      const snapshot = yield call(getUserData, user)
      const state = snapshot.val()

      if (state) {
        yield put(restoreFromStorage(state));
      }
    } catch (e) {
      console.log(`Error trying to restore state: ${e}`)
    }
  }
}

function* persistOnLogout() {
  while (true) {
    const loginOutAction = yield take(USER_ACTION.LOGOUT)
    const {user} = loginOutAction

    try {
      const state = yield select(stateSelector)
      yield call(persistUserData, user, omit(state, 'user'))
    } catch (e) {
      console.log(`Error trying to restore state: ${e}`)
    }
  }
}

function* watchStateChange() {
  yield takeLatest([
    ITEM_ACTION.BULK_ADD_ITEMS,
    ITEM_ACTION.BULK_DELETE_ITEMS,
    ITEM_ACTION.UPDATE_ITEM,
    ITEM_ACTION.BULK_UPDATE_ITEMS,
    ITEM_ACTION.ITEM_PARENT_CHANGE,
    ITEM_ACTION.HABIT_CHANGE,
    HABIT_ACTION.ADD_HABIT,
    HABIT_ACTION.BULK_ADD_HABITS,
    HABIT_ACTION.UPDATE_HABIT,
    HABIT_ACTION.BULK_UPDATE_HABITS,
    HABIT_ACTION.DELETE_HABIT,
    HABIT_ACTION.HABIT_CHANGE,
    HABIT_ACTION.VIEW_MODE_CHANGE,
    HABIT_ACTION.VIEW_PERIOD_CHANGE,
    VIEW_ACTION.ADD_VIEW,
    VIEW_ACTION.DELETE_VIEW,
    VIEW_ACTION.UPDATE_VIEW,
    RESULT_ACTION.ADD_RESULT,
    RESULT_ACTION.DELETE_RESULT,
    RESULT_ACTION.UPDATE_RESULT,
    NOTE_ACTION.ADD_NOTE,
    NOTE_ACTION.DELETE_NOTE,
    NOTE_ACTION.UPDATE_NOTE,
    I18N_ACTION.LANGUAGE_CHANGE,
    PROPERTIES_ACTION.CHANGE_GOALS_VIEW
  ], triggerStorgeSave)
}

function* triggerStorgeSave() {
  yield delay(3000)
  yield put(saveToStorage())
}

function* watchSaveToStorage() {
  yield takeLatest(STORAGE_ACTION.SAVE, persistToFirebase)
}

function* watchUpdateReceive() {
  yield takeLatest(STORAGE_ACTION.RECEIVE, restoreIfNewUpdate)
}

function* firebaseSaga() {
  yield all([
    watchSaveToStorage(),
    watchStateChange(),
    watchUpdateReceive(),
    initFromFirebase(),
    persistOnLogout()
  ])
}

export default firebaseSaga;
