import React from "react";
import { Input } from 'antd';
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons'
import {eventValue} from "../utils/index";
import {debounce} from "lodash";

class SearchInput extends React.Component {
  state = {
    search: ''
  }

  constructor(props) {
    super(props)
    this.debouncedOnChange = debounce(props.onChange, 1000)
  }

  render() {
    const { defaultValue, className, placeholder='Search', onChange } = this.props
    const { search } = this.state

    const classn = `ant-input-search ant-input-affix-wrapper ${className}`

    const searchChange = e => {
      const value = eventValue(e)
      this.setState({search: value})

      e.persist()
      this.debouncedOnChange(e)
    }

    const searchClear = e => {
      this.setState({search: ''})
      onChange('')
    }

    const icon = search ? <CloseCircleOutlined onClick={searchClear}/> : <SearchOutlined />

    return <span className={classn}>
      <Input
        defaultValue={defaultValue}
        value={search}
        placeholder={placeholder}
        onChange={searchChange}
        onKeyUp={this.keyUpHandler}
      />

      <span className="ant-input-suffix">
        {icon}
      </span>
    </span>
  }

  keyUpHandler = event => {
    const { onChange } = this.props
    const { search } = this.state

    if (event.key === 'Escape' && search) {
      this.setState({search: ''})
      onChange('')
    }
  }
}

export default SearchInput
