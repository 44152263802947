import {useEffect, useState} from "react";

export const useCustomEvent = (type) => {
  const [customEvent, setCustomEvent] = useState(null);

  function eventHandler({ detail }) {
    setCustomEvent(detail);
  }

  useEffect(() => {
    document.addEventListener(type, eventHandler);

    return () => {
      document.removeEventListener(type, eventHandler);
    };
  }, [type]);

  return customEvent;
};

export const dispatchCustomEvent = (type, detail = {}) => {
  document.dispatchEvent(new CustomEvent(type, {detail}))
};
