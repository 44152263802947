import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {eventValue, mapDispatchActionToProps, textMethod} from "../../../common/utils/index";
import {VIEW_TYPE} from "../../../common/constants";
import {withAuthorization} from "../../../common/utils/auth/with-authorization";
import PageTemplate from "../page-template/PageTemplate";
import "./NotesPage.css";
import {notesListSelector} from "../../../common/selectors/notes-selectors";
import {useDebounce} from "../../../common/hooks/useDebounce";
import {Input, Button, List, Modal, message} from "antd";
import NewItem from "../../../common/components/new-item/new-item";
import {LeftOutlined, DeleteOutlined} from '@ant-design/icons';
import NoteTimer, {TIMER_ACTION} from "./components/note-timer/note-timer";
import {noteAdd, noteDelete, noteUpdate} from "../../../common/actions/note-actions";
import uuid from "uuid-random";
import moment from "moment";
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {dispatchCustomEvent, useCustomEvent} from "../../../common/hooks/customEvents";
import {CUSTOM_EVENTS} from "../../../common/types";
import useInterval from "../../../common/hooks/useInterval";
import PlusButton from "../../../common/components/plus-button/PlusButton";

const FOCUS_AREA = Object.freeze({
  NOTES_LIST: "notes-list",
  NOTE_DETAILS: "note-details",
});

const MAX_IDLE_TIME = 5000;

const NotesPage = (props) => {
  const {text, notesList, dispatchAction} = props;

  const [focusArea, changeFocusArea] = useState(FOCUS_AREA.NOTES_LIST);
  const showNotesList = () => {changeFocusArea(FOCUS_AREA.NOTES_LIST);};
  const pageClasses = `notes page ${focusArea}`;

  const [innerSearchText, changeInnerSearchText] = useState("");
  const searchChange = (event) => {changeInnerSearchText(eventValue(event));};
  const debouncedSearchValue = useDebounce(innerSearchText, 1000);

  const [selectedNoteId, changeSelectedNoteId] = useState(null);
  const selectNote = (noteId) => {
    changeNoteName('');
    changeNoteContent('');
    changeSelectedNoteId(noteId);
    changeFocusArea(FOCUS_AREA.NOTE_DETAILS);
  };

  const createNewNote = (patch = {}) => {
    const id = uuid();
    const DEFAULT_NAME = `${text('PAGES.NOTES.NOTE_FROM_DATE')}: ${moment().format('DD.MM.YY')}`;

    const {name = DEFAULT_NAME, content=''} = patch;

    dispatchAction(noteAdd({id, name, content}));
    selectNote(id);
  };

  const [noteName, changeNoteName] = useState('');
  const debouncedNoteName = useDebounce(noteName, 1000);
  const [noteContent, changeNoteContent] = useState('');
  const [typingTime, changeTypingTime] = useState(0);
  const debouncedNoteContent = useDebounce(noteContent, 1000);

  const getSelectedNote = () => {
    const notesWithSelectedId = notesList.filter(({id}) => id === selectedNoteId);
    return notesWithSelectedId.length > 0 ? notesWithSelectedId[0] : null;
  };

  useEffect(() => {
    changeNoteName('');
    changeNoteContent('');

    if (focusArea === FOCUS_AREA.NOTE_DETAILS) {
      const selectedNote = getSelectedNote();
      if (selectedNote) {
        const {name, content=''} = selectedNote;
        changeNoteName(name);
        changeNoteContent(content);
      }
    }
  }, [selectedNoteId, focusArea]);

  const createOrUpdateNote = (patch = {}) => {
    if (selectedNoteId) {
      const {name, content=''} = getSelectedNote() || {};
      const {name: newName, content: newContent} = patch;

      if ((newName && newName !== name) || (newContent && newContent !== content)) {
        dispatchAction(noteUpdate({id: selectedNoteId, ...patch}));
      }
    } else {
      createNewNote(patch);
    }
  };
  useEffect(() => {if (debouncedNoteName && focusArea === FOCUS_AREA.NOTE_DETAILS) createOrUpdateNote({name: debouncedNoteName});}, [debouncedNoteName]);
  useEffect(() => {if (debouncedNoteContent && focusArea === FOCUS_AREA.NOTE_DETAILS) createOrUpdateNote({content: debouncedNoteContent});}, [debouncedNoteContent]);

  const nameChangeHandler = (e) => {changeNoteName(eventValue(e));};
  const contentChangeHandler = (e) => {changeNoteContent(eventValue(e));changeTypingTime(moment().valueOf());};

  const filteredNotes = notesList
    .filter(note =>
      (!debouncedSearchValue) ||
      (note.name && note.name.toLowerCase().indexOf(debouncedSearchValue.toLowerCase()) >= 0)
    );

  const [shouldWriteNonStop, changeNonStopWritingExpectation] = useState(false);
  const [countDownPercent, changeCountDownPercent] = useState(0);
  const timerEvent = useCustomEvent(CUSTOM_EVENTS.TIMER_EVENT);
  useEffect(() => {
    if (timerEvent) {
      switch (timerEvent.action) {
        case TIMER_ACTION.START: {
          if (timerEvent.nonStop) {
            changeTypingTime(0);
            changeCountDownPercent(0);
            changeNonStopWritingExpectation(true);
          }
          break;
        }
        case TIMER_ACTION.STOP:
        case TIMER_ACTION.TIMEOUT: {
          changeNonStopWritingExpectation(false);
        }
      }
    }
  }, [timerEvent]);

  useInterval(() => {
    const curTime = moment().valueOf();
    const delta = curTime - typingTime;

    const percent = Math.round(delta / MAX_IDLE_TIME * 100)

    if (percent >= 100) {
      dispatchCustomEvent(CUSTOM_EVENTS.TIMER_EXTERNAL_EVENT, {action: TIMER_ACTION.EXTERNAL_STOP});
      changeNonStopWritingExpectation(false);
      changeNoteContent('');
      message.error(text('PAGES.NOTES.TIMER.NON_STOP_TIME_OUT'));
    } else {
      changeCountDownPercent(percent);
    }
  }, shouldWriteNonStop && typingTime > 0 ? 100 : null);

  return <PageTemplate
    selectedView={VIEW_TYPE.NOTES}
    pageTitle={text("VIEWS.notes")}
  >
    <div className={pageClasses}>
      <div className="page-list">
        <div className="header">
          <Input.Search
            value={innerSearchText}
            placeholder={text("PAGES.NOTES.SEARCH_PLACEHOLDER")}
            onChange={searchChange}
            onKeyUp={searchChange}
            className="search"
            allowClear
          />
          <PlusButton onClick={createNewNote}/>
        </div>
        <div className="content">
          <List className='notes-list' itemLayout="horizontal"
                dataSource={filteredNotes}
                renderItem={noteRenderer(dispatchAction, selectedNoteId, selectNote, text)}
                locale={{emptyText: 'Start typing and the note will appear here'}}
          />
        </div>
        <div className="footer">
          <NewItem path="PAGES.NOTES.ADD_NEW" showNewItemModal={createNewNote}/>
        </div>
      </div>
      <div className="page-content">
        <div className="header">
          <Button className="back-button" icon={<LeftOutlined />} onClick={showNotesList}/>
          <Input value={noteName} placeholder={text('PAGES.NOTES.NOTE_DETAILS_NAME_PLACEHOLDER')} onChange={nameChangeHandler} onKeyUp={nameChangeHandler}/>
          <NoteTimer/>
        </div>
        <div className={`content countdown${shouldWriteNonStop ? countDownPercent : 0}`}>
          <ReactQuill theme="snow"
                      placeholder={text('PAGES.NOTES.NOTE_DETAILS_CONTENT_PLACEHOLDER')}
                      value={noteContent}
                      onChange={contentChangeHandler} />
        </div>
      </div>
    </div>
  </PageTemplate>
};

const noteRenderer = (dispatchAction, selectedNoteId, selectNote, text) => (note) => {
  const {id, name, dateCreated} = note;

  const noteClick = () => {
    selectNote(id);
  };

  const deleteNoteClick = (event) => {
    event.stopPropagation();

    const title = text("DELETE.areYouSure");
    const content = `${text("DELETE.willBeDeleted")}:"${name}"`;
    const okText = text("DELETE.delete");
    const cancelText = text("DELETE.cancel");

    Modal.confirm({
      title,
      content,
      okText,
      cancelText,
      okType: 'danger',
      onOk() {
        dispatchAction(noteDelete(id));
        if (id === selectedNoteId) {
          selectNote(null);
        }
      }
    })
  };

  const className = `note-list-item ${id === selectedNoteId ? 'selected' : ''}`

  const title = name && name.trim().length > 0 ? name : text('PAGES.NOTES.NO_NAME');

  const description = <div className="actions">
    <span>{!!dateCreated ? moment(dateCreated).format('DD.MM.YY') : '--.--.--'}</span>
    <DeleteOutlined onClick={deleteNoteClick}/>
  </div>;

  return <List.Item className={className} onClick={noteClick}>
    <List.Item.Meta
      title={title}
      description={description}
    />
  </List.Item>
};

const mapStateToProps = (state, ownProps) => ({
  notesList: notesListSelector(state, ownProps),
  text: textMethod(state)
});

const NotesPageController = connect(mapStateToProps, mapDispatchActionToProps)(NotesPage);
export default withAuthorization(NotesPageController)
