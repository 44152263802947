import React from 'react'
import {Button} from "antd";
import Text from "../../../../../../components/i18n/text";
import {getPropertyValueIcon} from "../common/utils";

const PropertyValueButton = (props) => {
  const {property, value, currentValue, changePropertyValue} = props;
  const className = `property-value-button ${property} ${value} ${value === currentValue ? 'selected' : ''}`;

  return <div className={className} onClick={() => changePropertyValue(property, value)}>
    <span className="label"><Text path={`PAGES.ACTIONS.MOBILE_EDIT.${property}.OPTIONS.${value}`}/></span>
    <Button shape="circle" icon={getPropertyValueIcon(property, value)}/>
  </div>
};

export default PropertyValueButton;
