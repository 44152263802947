import React, {useEffect, useRef} from 'react'
import {connect} from "react-redux";
import { Checkbox } from 'antd';
import { PlusCircleOutlined, DeleteOutlined, HolderOutlined, EditOutlined } from '@ant-design/icons'
import {mapDispatchActionToProps, clickStop, textMethod} from "../../../../../../../../common/utils";
import {deleteSelected, doneSelected, getItemDateIcon} from "../../../../../../../../common/utils/item-utils";
import './name-renderer.css'
import {itemsWithChildrenSelector, selectedGoalsIdsSelector} from "../../../../../../../../common/selectors/goals-selectors";
import ImportanceSelectPopover from '../../../../../../../../common/components/list-item-popover/importance-select-popover'
import UrgencySelectPopover from '../../../../../../../../common/components/list-item-popover/urgency-select-popover'
import DurationSelectPopover from '../../../../../../../../common/components/list-item-popover/duration-select-popover'
import {itemsBulkUpdate} from "../../../../../../../../common/actions/item-actions";
import {useDrag} from "react-dnd";
import {dispatchCustomEvent} from "../../../../../../../../common/hooks/customEvents";
import {CUSTOM_EVENTS} from "../../../../../../../../common/types";
import {getDateString} from "../../../../../../../../common/utils/date-utils";
import PeriodSelectPopover from "../../../../../../../../common/components/list-item-popover/period-select-popover";

const ItemNameRenderer = (props) => {
  const {record, dispatchAction, text} = props;

  const dragRef = useRef();
  const [{isDragging}, drag, dragPreview] = useDrag({
    type: 'DraggableAction',
    item: { id: record.id },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(dragRef);

  useEffect(() => {
    dispatchCustomEvent(CUSTOM_EVENTS.DRAG_ACTION, {id: record.id, isDragging});
  }, [isDragging, record.id]);

  useEffect(() => {
    dragPreview(document.querySelectorAll(`[data-row-key="${record.id}"]`)[0]);
  }, [record.id, dragPreview]);

  const doneChange = e => {
    const done = e.target.checked;
    doneSelected({selectedItemsIds: [record.id], dispatchAction}, done)
  };

  const dateString = getDateString(record.dateFrom, text("CONSTANTS.TODAY"));
  const periodWithUpdate = <div {...clickStop}><PeriodSelectPopover item={record} updateItem={updateItem(props)}/></div>
  const dateOrPeriod = record.dateFrom ? <div> {dateString} </div> : (record.period ? periodWithUpdate : null);

  const className = 'item-name' + (record.done ? ' done' : '') + (record.filtered ? ' filter-fit' : '');

  return <div className={className}>
    <div className='name-container'>
      <HolderOutlined ref={dragRef} className="holder"/>
      <Checkbox checked={!!record.done} onChange={doneChange} className="checkbox"/>
      <div className="flex-container column">
        <span className="title">{record.listName}</span>
        <div className="flex-container row gap5 ant-list-item-meta-description">
          <div><ImportanceSelectPopover item={record} updateItem={updateItem(props)}/></div>
          <div><UrgencySelectPopover item={record} updateItem={updateItem(props)}/></div>
          <div/>
          {dateOrPeriod}
        </div>
      </div>

    </div>
    {getIconDetailsContained(props)}
  </div>
};

const getIconDetailsContained = (props) => {
  const {record, showNewItemModal, dispatchAction, selectedItemsIds, itemsWithChildren} = props;

  // edit
  const editClick = (event) => {
    event.stopPropagation();
    dispatchCustomEvent(CUSTOM_EVENTS.EDIT_ACTION, {ids: [record.id]})
  };

  const edit = <div onClick={editClick}><EditOutlined /></div>;

  // add child
  const newChildClick = (event) => {
    event.stopPropagation();
    showNewItemModal(record.id)
  };

  const newChild = <div onClick={newChildClick}><PlusCircleOutlined /></div>;

  // delete
  const ids = selectedItemsIds.indexOf(record.id) >= 0 ? selectedItemsIds : [record.id];

  const deleteClick = (event) => {
    event.stopPropagation();
    deleteSelected({selectedItemsIds: ids, dispatchAction, itemsWithChildren})
  };

  const deleteItem = <div onClick={deleteClick}><DeleteOutlined /></div>;

  const dateIcon = getItemDateIcon(record, updateItem(props));

  return <div className='icon-details-container'>

    <div {...clickStop} className='actions'>
      <div>
        {dateIcon}
      </div>

      <div>
        {deleteItem}
        {edit}
      </div>

      <div>
        {newChild}
      </div>
    </div>
  </div>
};

const updateItem = (props) => (update) => {
  const {selectedItemsIds, dispatchAction} = props;

  let itemsUpdate = [];
  if (selectedItemsIds.indexOf(update.id) >= 0) {
    itemsUpdate = selectedItemsIds.map(id => ({...update, id}))
  } else {
    itemsUpdate = [update]
  }

  dispatchAction(itemsBulkUpdate(itemsUpdate))
};

const mapStateToProps = (state, ownProps) => ({
  text: textMethod(state),
  itemsWithChildren: itemsWithChildrenSelector(state, ownProps),
  selectedItemsIds: selectedGoalsIdsSelector(state),
});

const ItemNameRendererController = connect(mapStateToProps, mapDispatchActionToProps)(ItemNameRenderer)

export default (searchProps) => (text, record) => {
  const props = {...searchProps, record};
  return <ItemNameRendererController {...props}/>
}
