import React from 'react';
import {connect} from 'react-redux'
import AuthUserContext from './auth-user-context';
import {URL} from '../../constants';
import {loginAttemptDoneSelector} from "../../selectors/app-selectors";
import AuthModal from "../../../components/auth-modal/AuthModal";
import Loading from "../../../components/loading/loading";
import PageTemplate from "../../../pages/user/page-template/PageTemplate";

const authenticated = (user) => !!user;
const isUser = (user) => authenticated(user) && user.uid;

export const withAuthorization = (Component) => {

  const AuthorizedComponent = (props) => {
    const renderer = (user) => {
      const closeModalHandler = (loginSuccessful) => {
        if(!loginSuccessful) {
          window.location.pathname = URL.HOME;
        }
      };

      if (!isUser(user)) {
        if (!props.loginAttemptDone) {
          return <PageTemplate><Loading/></PageTemplate>
        } else {
          return <AuthModal visible={true} closeModal={closeModalHandler} stayOnPage="true"/>
        }
      }

      return <Component user={user} {...props} />
    };

    return (
      <AuthUserContext.Consumer>
        {renderer}
      </AuthUserContext.Consumer>
    );
  };

  const mapStateToProps = (state) => ({
    loginAttemptDone: loginAttemptDoneSelector(state)
  });

  return connect(mapStateToProps)(AuthorizedComponent)
};
