import React from 'react'
import {eventValue} from "../../utils/index";
import { Popover, Radio } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons'
import {DEFAULT_ITEM_DURATION, DURATION} from "../../constants/index";

const RadioGroup = Radio.Group

class DurationSelectPopover extends React.Component {
  state = {
    visible: false
  }

  render() {
    const {item, updateItem} = this.props

    const changeDuration = (e) => {
      const duration = eventValue(e)
      this.handleVisibleChange(false)
      updateItem({id: item.id, duration})
    }

    const value = item.duration || DEFAULT_ITEM_DURATION
    const title = value >= 60 ? ((Math.round(value / 30) / 2) + ' hr(s)') : (value + ' min(s)')

    const minSelect = <RadioGroup onChange={changeDuration} value={value}>
      <Radio value={DURATION.MIN5.value}>{DURATION.MIN5.label}</Radio>
      <Radio value={DURATION.MIN15.value}>{DURATION.MIN15.label}</Radio>
      <Radio value={DURATION.MIN30.value}>{DURATION.MIN30.label}</Radio>
    </RadioGroup>

    const hrSelect = <RadioGroup onChange={changeDuration} value={value}>
      <Radio value={DURATION.HR1.value}>{DURATION.HR1.label}</Radio>
      <Radio value={DURATION.HR2.value}>{DURATION.HR2.label}</Radio>
      <Radio value={DURATION.HR4.value}>{DURATION.HR4.label}</Radio>
    </RadioGroup>

    const durationSelect = <div className='duration-select'>
      {minSelect}
      {hrSelect}
    </div>

    return <Popover
      content={durationSelect}
      title="Duration"
      trigger="click"
      visible={this.state.visible}
      onVisibleChange={this.handleVisibleChange}
    >
      <ClockCircleOutlined title={title} />
    </Popover>
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible })
  }
}

export default DurationSelectPopover
