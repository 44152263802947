import React from 'react'
import {DATE_OPTIONS, ITEM_TYPE} from "../constants";
import { Radio } from 'antd'
import T from '../../components/i18n/text'

const RadioGroup = Radio.Group;

const actionOptions = ['', DATE_OPTIONS.WEEK, DATE_OPTIONS.MONTH, DATE_OPTIONS.YEAR];
const habitOptions = [DATE_OPTIONS.DAY, DATE_OPTIONS.WEEK, DATE_OPTIONS.MONTH, DATE_OPTIONS.YEAR];

export default ({type, value, onChange}) => {
  const periodOptions = type === ITEM_TYPE.ACTION ? actionOptions : habitOptions;
  return type ?
    <RadioGroup
      onChange={onChange}
      value={value}>
      {periodOptions.map(option => (<Radio key={option} value={option}>
        {option ? <T path={`CONSTANTS.TIME_PERIOD.${option}`}/> : '--'}
      </Radio>))
      }
    </RadioGroup> : null
}
