import "regenerator-runtime/runtime";
import { all } from 'redux-saga/effects'

import resultsSaga from './results-saga'
import firebaseSaga from './firebase-saga'

function* rootSaga() {
  yield all([
    resultsSaga(),
    firebaseSaga()
  ])
}

export default rootSaga;