import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

import {FIREBASE_DEV_CONFIG, FIREBASE_PROD_CONFIG} from "../../constants/firebase";

const config = process.env.NODE_ENV === 'production' ? FIREBASE_PROD_CONFIG : FIREBASE_DEV_CONFIG

const app = initializeApp(config);

export const db = getDatabase(app);
export const storage = getStorage(app);
