import React from 'react'
import { ReloadOutlined } from '@ant-design/icons'
import Select from '../../../../../common/components/select'
import {DATE_OPTIONS} from "../../../../../common/constants";
import "./GoalsPriorityListFilter.css";
import {textMethod} from "../../../../../common/utils";
import {connect} from "react-redux";
import PlusButton from "../../../../../common/components/plus-button/PlusButton";

const periodFilterOptions = (text) => ([
  {label: text('CONSTANTS.ALL'), value: null},
  {label: text(`CONSTANTS.TIME_PERIOD.${DATE_OPTIONS.WEEK}`), value: DATE_OPTIONS.WEEK},
  {label: text(`CONSTANTS.TIME_PERIOD.${DATE_OPTIONS.MONTH}`), value: DATE_OPTIONS.MONTH},
  {label: text(`CONSTANTS.TIME_PERIOD.${DATE_OPTIONS.YEAR}`), value: DATE_OPTIONS.YEAR},
]);

const GoalsPriorityListFilter = (props) => {
  const { period, periodChange, text, showNewItemModal, selectedItemsIds = [] } = props;

  const plusButtonClick = () => {
    if (selectedItemsIds.length === 1) {
      showNewItemModal(selectedItemsIds[0]);
    } else {
      showNewItemModal(null);
    }
  };

  return <div className="priority-list-filter">
    <Select className="period-select"
            value={period}
            options={periodFilterOptions(text)}
            onChange={periodChange}
            placeholder="Period filter"
            suffixIcon={<ReloadOutlined/>}
    />
    <PlusButton onClick={plusButtonClick} disabled={selectedItemsIds.length > 1}/>
  </div>
};

const mapStateToProps = (state) => ({
  text: textMethod(state),
});

export default connect(mapStateToProps)(GoalsPriorityListFilter)
