import { createSelector } from 'reselect'

export const propertiesSelector = state => state.properties

export const goalsViewSelector = createSelector(
  propertiesSelector,
  (properties) => {
    return properties ? properties.goalsView : null;
  }
)
