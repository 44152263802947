import { combineReducers } from 'redux'
import { omit } from 'lodash'
import { goalsSelector } from '../selectors/goals-selectors'
import { ITEM_ACTION, STORAGE_ACTION } from '../types'
import {updateWithHistory} from "../utils/item-utils";
import moment from 'moment'

export const initialItems = {
  byId: {},
  selectedIds: []
};

const NON_TRACKED_PROPERTIES = ['id', 'name', 'description'];

export const byIdReducer = (state = initialItems.byId, action) => {
  switch(action.type) {
    // add
    case ITEM_ACTION.BULK_ADD_ITEMS: {
      const dateCreated = moment().valueOf();
      const newItems = action.items.reduce((newById, item) => ({...newById, [item.id]: {...item, dateCreated}}), {});
      return {...state, ...newItems}
    }

    // delete
    case ITEM_ACTION.BULK_DELETE_ITEMS: return omit(state, action.ids);

    // update
    case ITEM_ACTION.UPDATE_ITEM: {
      const updatedItem = updateWithHistory(state, action.item, NON_TRACKED_PROPERTIES);
      return {...state, [updatedItem.id]: updatedItem}
    }
    case ITEM_ACTION.BULK_UPDATE_ITEMS: {
      let updatedItemsArr = [];

      action.items.forEach(item => {
        const updatedItem = updateWithHistory(state, item, NON_TRACKED_PROPERTIES);
        updatedItemsArr.push(updatedItem)
      });

      const updatedItems = updatedItemsArr.reduce((newById, item) => ({...newById, [item.id]: item}), {});

      return {...state, ...updatedItems}
    }
    case ITEM_ACTION.ITEM_PARENT_CHANGE: return {...state, [action.id]: {...state[action.id], parentId: action.parentId}};
    case STORAGE_ACTION.RESTORE: {
      const items = goalsSelector(action.state);
      return items ? {...items.byId} : state
    }
    default: return state
  }
};

export const selectedIdsReducer = (state = initialItems.selectedIds, action) => {
  switch(action.type) {
    case ITEM_ACTION.SELECT_ITEMS: return action.ids;
    case ITEM_ACTION.BULK_DELETE_ITEMS: return state.filter(id => !action.ids.includes(id));
    default: return state
  }
};

export default combineReducers({
  byId: byIdReducer,
  selectedIds: selectedIdsReducer,
})
