export const ItemTypes = Object.freeze({
  DATE: "DATE",
  IMPORTANCE: "IMPORTANCE",
  URGENCY: "URGENCY",
  PERIOD: "PERIOD",
});

export const ItemSubTypes = Object.freeze({
  [ItemTypes.DATE]: {
    TODAY: "TODAY",
    TOMORROW: "TOMORROW",
    INAWEEK: "INAWEEK",
    OTHER_DATE: "OTHER_DATE",
  },
  [ItemTypes.IMPORTANCE]: {
    MINOR: "MINOR",
    NORMAL: "NORMAL",
    HIGH: "HIGH",
  },
  [ItemTypes.URGENCY]: {
    MINOR: "MINOR",
    NORMAL: "NORMAL",
    HIGH: "HIGH",
  },
  [ItemTypes.PERIOD]: {
    NONE: "NONE",
    WEEK: "WEEK",
    MONTH: "MONTH",
    YEAR: "YEAR",
  },
});

export const SELECTION_EDIT_PANEL_STATE = Object.freeze({
  SHORT: "short-list",
  FULL: "full-list"
});
