import React from "react";
import { Select } from 'antd';
import {pick} from "lodash";
import {languageSelector} from "../selectors/app-selectors";
import {tt} from "../../components/i18n/text";
const Option = Select.Option;

export const getValueLabelOptions = (items, keyPrefix='key') => {
  return items.map(item => <Option key={keyPrefix+item.value} value={item.value}>{item.label}</Option>)
};

export const mapDispatchActionToProps = dispatch => {
  return {
    dispatchAction: action => dispatch(action)
  }
};

export const selectOptionFilter = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

export const eventValue = e => {
  return e && e.target ? (e.target.value || e.target.value === '' ? e.target.value : e.target.checked) : e
};

export const minifyString = (string, maxLength) => {
  if (!string) return '';
  return string.length > maxLength ? (string.substr(0, maxLength) + '...') : string
};

export const keyboardProps = props => pick(props, ['keyCounter', 'keyPresses', 'ctrlPressed', 'shiftPressed']);

export const labelByValue = (value, all) => {
  const labels = Object.keys(all)
    .filter(key => all[key].value === value)
    .map(key => all[key].label);

  return labels.length ? labels[0] : 'Not selected'
};

export const stopPropagation = event => {
  event.stopPropagation()
};

export const clickStop = {
  onClick: stopPropagation
};

export const only = handler => event => {
  event.stopPropagation();
  handler(event)
};

export const textMethod = state => {
  const language = languageSelector(state);
  return (path) => tt(path, language)
};
