import React from 'react'
import {connect} from 'react-redux'
import {languageSelector} from "../../../common/selectors/app-selectors";
import {mapDispatchActionToProps} from "../../../common/utils/index";
import {LANGUAGE} from "../../../common/constants/index";
import {languageChange} from "../../../common/actions/i18n-actions";
import './language-change.css'

class LanguageChange extends React.Component {
  render() {
    const {language, dispatchAction} = this.props

    const lang = language === LANGUAGE.RU ? 'EN' : 'RU'
    const langChange = () => {
      dispatchAction(languageChange(language === LANGUAGE.RU ? LANGUAGE.EN : LANGUAGE.RU))
    }
    return <div className='language' onClick={langChange}>
      {lang}
    </div>
  }
}

const mapStateToProps = (state, ownProps) => ({
  language: languageSelector(state)
})

export default connect(mapStateToProps, mapDispatchActionToProps)(LanguageChange)