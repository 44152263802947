import React from 'react'
import { Tooltip, Radio } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {IMPORTANCE} from "../constants/index";
import T from "../../components/i18n/text";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

export default ({value, onChange, size='default', className, style}) => {
  return <div className={`tripple-selection ${className}`} style={style}>
    <div className='background-text'>
      <ExclamationCircleOutlined />&nbsp;
      <T path='CONSTANTS.IMPORTANCE'/>
    </div>
    <RadioGroup
      onChange={onChange}
      value={value || undefined}
      size={size}
    >
      <Tooltip mouseEnterDelay={1} placement="top" title={IMPORTANCE.MINOR.label}>
        <RadioButton value={IMPORTANCE.MINOR.value}/>
      </Tooltip>
      <Tooltip mouseEnterDelay={1} placement="top" title={IMPORTANCE.NORMAL.label}>
        <RadioButton value={IMPORTANCE.NORMAL.value}/>
      </Tooltip>
      <Tooltip mouseEnterDelay={1} placement="top" title={IMPORTANCE.HIGH.label}>
        <RadioButton value={IMPORTANCE.HIGH.value}/>
      </Tooltip>
    </RadioGroup>
  </div>
}
