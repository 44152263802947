import React from 'react'
import {connect} from 'react-redux'
import { Button } from 'antd'
import {
  UpCircleOutlined,
  DownCircleOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import {mapDispatchActionToProps} from "../../../../../../common/utils";
import {itemsTreeSelector} from "../../../../../../common/selectors/goals-selectors";
import {
  areIdsFromSameParent,
  firstInTheList, getSelectedGroups,
  lastInTheList,
  moveSelected, onTheBottomLevel, onTheRootLevel
} from "../../../../../../common/utils/item-selection-utils";
import {getChildParentIds} from "../../../../../../common/selectors/selector-utils";


class HierarchyMoveButtons extends React.Component {
  render() {
    const {className} = this.props;

    const availability = this.getButtonsAvailability();
    const moveHandlers = this.getMoveHandlers();

    return <div className={className}>
      <Button shape="circle" icon={<UpCircleOutlined />} disabled={availability.up} onClick={moveHandlers.up} />
      <Button shape="circle" icon={<DownCircleOutlined />} disabled={availability.down} onClick={moveHandlers.down} />
      <Button shape="circle" icon={<LeftCircleOutlined />} disabled={availability.left} onClick={moveHandlers.left} />
      <Button shape="circle" icon={<RightCircleOutlined />} disabled={availability.right} onClick={moveHandlers.right} />
    </div>
  }

  getButtonsAvailability = () => {
    const {selectedItemsIds, itemsWithChildren, items, expandedRowKeys} = this.props;

    const notSelected = !selectedItemsIds || selectedItemsIds.length === 0;
    const selectedMany = selectedItemsIds.length > 1;

    let notOneParent = false;
    let selectedHidden = false;
    let first = false;
    let last = false;
    let root = false;
    let leaf = false;
    let manyGroups = false;

    if (!notSelected) {
      notOneParent = !areIdsFromSameParent(itemsWithChildren)(selectedItemsIds);

      if (!selectedMany) {
        selectedHidden = !itemsWithChildren[selectedItemsIds[0]];

        if(!selectedHidden) {
          leaf = onTheBottomLevel(itemsWithChildren)(selectedItemsIds[0])
        }
      } else {
        if (!notOneParent) {
          const groups = getSelectedGroups(items, itemsWithChildren, expandedRowKeys)(selectedItemsIds);
          manyGroups = groups.length > 1
        }
      }

      if (!notOneParent) {
        if(!selectedHidden) {
          first = firstInTheList(itemsWithChildren, items)(selectedItemsIds[0]);
          last = lastInTheList(itemsWithChildren, items)(selectedItemsIds[selectedItemsIds.length-1]);
          root = onTheRootLevel(itemsWithChildren)(selectedItemsIds[0])
        }
      }
    }

    const up =    notSelected || notOneParent || first;
    const down =  notSelected || notOneParent || (last && !manyGroups);
    const left =  notSelected || notOneParent || root;
    const right = notSelected || notOneParent || first;

    return {up, down, left, right}
  };

  getMoveHandlers = () => {
    const move = moveSelected(this.props);

    const up = () => {move(-1)};
    const down = () => {move(1)};
    const left = () => {move(0, -1)};
    const right = () => {move(0, 1)};

    return {up, down, left, right}
  };
}

const mapStateToProps = (state, ownProps) => {
  const {tree, byId} = itemsTreeSelector(state, ownProps);
  return {
    items: tree,
    itemsWithChildren: byId,
  }
};

export default connect(mapStateToProps, mapDispatchActionToProps)(HierarchyMoveButtons)
