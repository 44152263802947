import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {Button, DatePicker, Select} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {eventValue, selectOptionFilter, textMethod} from "../../../../../common/utils";
import {DATE_OPTIONS} from "../../../../../common/constants";
import {connect} from "react-redux";
import moment from "moment";
import "./ResultsFilterHeader.css"
import {russianMomentLocale} from "../../../../../locales/ru";

const periodFilterOptions = (text) => ([
  {label: text(`CONSTANTS.TIME_PERIOD.${DATE_OPTIONS.DAY}`), value: DATE_OPTIONS.DAY},
  {label: text(`CONSTANTS.TIME_PERIOD.${DATE_OPTIONS.WEEK}`), value: DATE_OPTIONS.WEEK},
  {label: text(`CONSTANTS.TIME_PERIOD.${DATE_OPTIONS.MONTH}`), value: DATE_OPTIONS.MONTH},
  {label: text(`CONSTANTS.TIME_PERIOD.${DATE_OPTIONS.YEAR}`), value: DATE_OPTIONS.YEAR},
]);

const ResultsFilterHeader = (props) => {
  useEffect(() => {
    moment.locale("ru", russianMomentLocale);
    moment.locale("en", {week : {
        dow : 1, // Monday is the first day of the week.
        doy : 4  // The week that contains Jan 4th is the first week of the year.
      }});
  });

  return <div className="results-filter-header">
    <Button className="back-button" icon={<LeftOutlined />} onClick={() => {props.showResultsNotesList()}}/>
    <div className="selection">
      {getPeriodFilter(props)}
      {getExactDateSelection(props)}
    </div>
  </div>
};

const getPeriodFilter = (props) => {
  const {period, periodChange, text} = props;

  const periodChangeHandler = event => {
    periodChange(eventValue(event));
  };

  return <Select className="period-select"
          value={period}
          options={periodFilterOptions(text)}
          onChange={periodChangeHandler}
  />
};

const getExactDateSelection = (props) => {
  const {period, selectedDate, selectedDateChange} = props;

  const now = moment();

  const handlePeriodChange = (period, selectedDate, selectedDateChange) => val => () => {
    if (val === 0) {
      selectedDateChange(moment())
    } else {
      selectedDateChange(selectedDate.clone().add(val, period))
    }
  };

  const dateChange = (newDate) => {
    selectedDateChange(newDate === null ? now : newDate);
  };

  return <Button.Group className="date-selection">
    <Button onClick={handlePeriodChange(period, selectedDate, dateChange)(-1)} icon={<LeftOutlined />}/>
    <div style={{width: 130, display: 'inline-block'}}>{datePicker(period, selectedDate, dateChange)}</div>
    <Button onClick={handlePeriodChange(period, selectedDate, dateChange)(1)} icon={<RightOutlined />}/>
  </Button.Group>
};

const datePicker = (period, selectedDate, selectedDateChange) => {
  switch (period) {
    case DATE_OPTIONS.DAY: {
      return <DatePicker value={selectedDate} onChange={selectedDateChange} placeholder="Select day" />
    }
    case DATE_OPTIONS.WEEK: {
      return <DatePicker.WeekPicker value={selectedDate} onChange={selectedDateChange} placeholder="Select week" />
    }
    case DATE_OPTIONS.MONTH: {
      return <DatePicker.MonthPicker value={selectedDate} onChange={selectedDateChange} placeholder="Select month" />
    }
    case DATE_OPTIONS.YEAR: {
      return yearSelect(selectedDate, selectedDateChange)
    }
    default: return
  }
};

const yearSelect = (selectedDate, selectedDateChange) => {
  const yearChange = year => selectedDateChange(moment().year(year))

  const currentYear = (new Date()).getFullYear()
  return <Select
    value={selectedDate.year()}
    showSearch
    style={{ width: 130 }}
    placeholder="Select an year"
    optionFilterProp="children"
    onChange={yearChange}
    filterOption={selectOptionFilter}
  >
    {[...Array(10).keys()].map(index => <Select.Option key={index} value={currentYear-index}>{currentYear-index}</Select.Option>)}
  </Select>
};

ResultsFilterHeader.propTypes = {
  period: PropTypes.string,
  periodChange: PropTypes.func,
  selectedDate: PropTypes.object,
  selectedDateChange: PropTypes.func,
  showResultsNotesList: PropTypes.func,
  text: PropTypes.func
};

const mapStateToProps = (state) => ({
  text: textMethod(state)
});

export default connect(mapStateToProps)(ResultsFilterHeader);
