export const FIREBASE_PROD_CONFIG = {
  apiKey: "AIzaSyA0ptdxNd8Orov46S8PTfDTcHFFRexrA2o",
  authDomain: "big-goal-achiever.firebaseapp.com",
  databaseURL: "https://big-goal-achiever.firebaseio.com",
  projectId: "big-goal-achiever",
  storageBucket: "big-goal-achiever.appspot.com",
  messagingSenderId: "624053564884"
}

export const FIREBASE_DEV_CONFIG = {
  apiKey: "AIzaSyA0ptdxNd8Orov46S8PTfDTcHFFRexrA2o",
  authDomain: "big-goal-achiever.firebaseapp.com",
  databaseURL: "https://big-goal-achiever.firebaseio.com",
  projectId: "big-goal-achiever",
  storageBucket: "big-goal-achiever.appspot.com",
  messagingSenderId: "624053564884"
}

