import {DATE_OPTIONS} from "../common/constants";

export default {
  TOP_MENU: {
    HOW_IT_WORKS: 'Как это работает',
    PRICING: 'Условия',
    APPLICATION: 'Приложение',
    SIGN_IN: 'Регистрация',
    LOG_IN: 'Вход',
    LOG_OUT: 'Выход',
    LOG_OUT_CONFIRM: 'Вы уверены, что хотите выйти?',
    TO_HOME_PAGE: 'На главную',
    LANGUAGE_CHANGE: 'Поменять язык',
  },
  VIEWS: {
    goals: 'Цели',
    habits: 'Привычки',
    results: 'Итоги',
    notes: 'Заметки',
    profile: 'Профиль',
    PROPERTIES: {
      title: 'Редактировать',
      groupTitle: 'Редактировать все',
      NAME: 'Название',
      TAGS: 'Тэги',
      PERIOD: 'Период',
      DATE: 'Или Дата',
      DESCRIPTION: 'Описание',
    }
  },
  AUTH: {
    LOGIN: {
      TITLE: 'Вход',
      EMAIL_PLACEHOLDER: 'Емэил',
      PASS_PLACEHOLDER: 'Пароль',
      FORGOT_PASS: 'Забыли пароль?',
      REGISTER: 'Зарегистрироваться',
      LOGIN: 'Войти',
      ERR: {
        LOGIN_FAILED: 'Попытка не удалась. Проверьте введённые почту и пароль и попробуйте ещё раз',
        EMAIL_REQUIRED: 'Пожалуйста введите емэил!',
        WRONG_EMAIL: 'Введённый текст не является емэйлом!',
        PASS_REQUIRED: 'Пожалуйста введите пароль!',
      }
    },
    SIGN_UP: {
      TITLE: 'Регистрация',
      PASS_CONFIRM_PLACEHOLDER: 'Пароль ещё раз',
      REGISTER: 'Зарегистрироваться',
      LOGIN: 'Войти',
      ERR: {
        REGISTRATION_FAILED: 'Регистрация не удалась. Приносим свои извинения',
        PASS_CONFIRM_REQUIRED: 'Пожалуйста повторите ваш пароль!',
        PASS_CONFIRM_SIMILARITY: 'Введённые пароли должны быть одинаковые!',
      }
    },
    PASS_RESET: {
      TITLE: 'Восстановление пароля',
      LOGIN: 'Вспомнил(а) пароль',
      RESET: 'Восстановить'
    },
  },
  CONSTANTS: {
    SEARCH_PLACEHOLDER: 'Поиск',
    DATE_SELECT_PLACEHOLDER: 'Выберите дату',
    TIME_SELECT_PLACEHOLDER: 'Укажите время',

    HABIT: 'Привычка',

    ADD: 'Добавить',
    CANCEL: 'Отменить',

    ALL: 'Все',
    ANY: 'Любой',

    DURATION: 'Длительность',
    HOUR_SHORT: 'ч',
    HOUR_MID: 'ч',
    HOURS_LONG: 'Часов',
    MIN_SHORT: 'м',
    MIN_MID: 'мин',
    DAY: 'день',

    HABIT_PERIODS: {
      [DATE_OPTIONS.DAY]: 'Ежедневно',
      [DATE_OPTIONS.WEEK]: 'Еженедельно',
      [DATE_OPTIONS.MONTH]: 'Ежемесячно',
      [DATE_OPTIONS.YEAR]: 'Ежегодно',
    },
    BUTTON_LABELS: {
      ADD: 'Добавить',
      CANCEL: 'Отменить',
    },
    TIME_PERIOD: {
      [DATE_OPTIONS.DAY]: 'День',
      [DATE_OPTIONS.WEEK]: 'Неделя',
      [DATE_OPTIONS.MONTH]: 'Месяц',
      [DATE_OPTIONS.YEAR]: 'Год',
    },
    TIME_PERIOD_OF: {
      [DATE_OPTIONS.DAY]: 'Дня',
      [DATE_OPTIONS.WEEK]: 'Недели',
      [DATE_OPTIONS.MONTH]: 'Месяца',
      [DATE_OPTIONS.YEAR]: 'Года',
    },
    TIME_PERIODS: {
      [DATE_OPTIONS.DAY]: 'Дни',
      [DATE_OPTIONS.WEEK]: 'Недели',
      [DATE_OPTIONS.MONTH]: 'Месяцы',
      [DATE_OPTIONS.YEAR]: 'Годы',
    },
    CURRENT_PERIOD_TEXT: {
      [DATE_OPTIONS.DAY]: 'Сегодня',
      [DATE_OPTIONS.WEEK]: 'Эта неделя',
      [DATE_OPTIONS.MONTH]: 'Этот месяц',
      [DATE_OPTIONS.YEAR]: 'Этот год',
    },
    URGENCY: 'Срочность',
    IMPORTANCE: 'Важность',
    IN_THE_PAST: "В прошлом",
    TODAY: "Сегодня",
    TOMORROW: "Завтра",
    ITEM: "задачa",
    ITEMS_1: "задачи",
    ITEMS_2: "задач"
  },
  PAGES: {
    ACTIONS: {
      ADD_NEW: 'Добавить новую цель',
      ADD_NEW_TO: 'Добавить новую цель в ',
      NEW_ITEM_PLACEHOLDER: 'Новая строка = Новая цель',
      MOBILE_EDIT: {
        DATE: {
          LABEL: "Дата",
          DROP_LABEL: "Выберите подходящую дату",
          OPTIONS: {
            TODAY: "Сегодня",
            TOMORROW: "Завтра",
            INAWEEK: "Через неделю",
            OTHER_DATE: "Другая дата",
          }
        },
        IMPORTANCE: {
          LABEL: "Важность",
          DROP_LABEL: "Выберите подходящую важность",
          OPTIONS: {
            MINOR: "Желательно",
            NORMAL: "Важно",
            HIGH: "Чрезвычайно",
          }
        },
        URGENCY: {
          LABEL: "Срочность",
          DROP_LABEL: "Выберите подходящую срочность",
          OPTIONS: {
            MINOR: "Не горит",
            NORMAL: "Поджимает",
            HIGH: "Срочно",
          }
        },
        PERIOD: {
          LABEL: "Период",
          DROP_LABEL: "Выберите подходящий период",
          OPTIONS: {
            NONE: "Без периода",
            WEEK: "Неделя",
            MONTH: "Месяц",
            YEAR: "Год"
          }
        },
        MORE: {
          LABEL: "Больше",
        },
        LESS: {
          LABEL: "Меньше",
        }
      }
    },
    PLANINGS: '',
    HABITS: {
      ADD_NEW: 'Новая привычка',
      NAME_PLACEHOLDER: 'Название привычки',
      ADD_NEW_MODAL_TITLE: 'Новая привычка',
      AMEND_MODAL_TITLE: 'Редактирование привычки',
      WRONG_PERIOD: 'Для этой привычки надо выбрать другой период',
    },
    NOTES: {
      ADD_NEW: 'Добавить новую запись',
      SEARCH_PLACEHOLDER: 'Поиск по названию записи',
      NOTE_FROM_DATE: 'Запись от',
      NOTE_DETAILS_NAME_PLACEHOLDER: 'Напишите здесь название заметки',
      NOTE_DETAILS_CONTENT_PLACEHOLDER: 'Напишите здесь всё что вы думаете',
      NO_NAME: '(Без названия)',
      TIMER: {
        TIME_OUT: 'Установленное время закончилось',
        NON_STOP_TIME_OUT: 'Упс... попробуйте ещё раз'
      }
    },
    RESULTS: {
      ADD_NEW: 'Добавить новую запись',
      NOTE_TITLE_PLACEHOLDER: {
        [DATE_OPTIONS.DAY]: 'Итоги дня',
        [DATE_OPTIONS.WEEK]: 'Итоги недели',
        [DATE_OPTIONS.MONTH]: 'Итоги месяца',
        [DATE_OPTIONS.YEAR]: 'Итоги года',
      },
      NOTE_DETAILS_CONTENT_PLACEHOLDER: 'Ваши мысли о выполненных задачах',
      SHOW_ACHIEVEMENTS: 'Показать достижения',
      SHOW_SUMMARY: 'Показать запись',
      ADD_SUMMARY: 'Добавить запись'
    },
  },
  DELETE: {
    areYouSure: "Вы уверены что хотите удалить?",
    willBeDeleted: "Запись на удаление",
    delete: "Удалить",
    cancel: "Отмена",
  }
}

export const russianMomentLocale = {
  months : 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split('_'),
  monthsShort : 'Янв_Фев_Мар_Апр_Май_Июн_Июл_Авг_Сен_Окт_Ноя_Дек'.split('_'),
  monthsParseExact : true,
  weekdays : 'Воскресенье_Поннедельник_Вторник_Среда_Четверг_Пятница_Суббота'.split('_'),
  weekdaysShort : 'Вос._Пон._Втр._Срд._Чет._Пят._Суб.'.split('_'),
  weekdaysMin : 'Вс_Пн_Вт_Ср_Чт_Пт_Сб'.split('_'),
  weekdaysParseExact : true,
  longDateFormat : {
    LT : 'HH:mm',
    LTS : 'HH:mm:ss',
    L : 'DD/MM/YYYY',
    LL : 'D MMMM YYYY',
    LLL : 'D MMMM YYYY HH:mm',
    LLLL : 'dddd D MMMM YYYY HH:mm'
  },
  calendar : {
    sameDay : '[Aujourd’hui à] LT',
    nextDay : '[Demain à] LT',
    nextWeek : 'dddd [à] LT',
    lastDay : '[Hier à] LT',
    lastWeek : 'dddd [dernier à] LT',
    sameElse : 'L'
  },
  relativeTime : {
    future : 'dans %s',
    past : 'il y a %s',
    s : 'quelques secondes',
    m : 'une minute',
    mm : '%d minutes',
    h : 'une heure',
    hh : '%d heures',
    d : 'un jour',
    dd : '%d jours',
    M : 'un mois',
    MM : '%d mois',
    y : 'un an',
    yy : '%d ans'
  },
  dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
  ordinal : function (number) {
    return number + (number === 1 ? 'er' : 'e');
  },
  meridiemParse : /PD|MD/,
  isPM : function (input) {
    return input.charAt(0) === 'M';
  },
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // },
  meridiem : function (hours, minutes, isLower) {
    return hours < 12 ? 'PD' : 'MD';
  },
  week : {
    dow : 1, // Monday is the first day of the week.
    doy : 4  // The week that contains Jan 4th is the first week of the year.
  }
};
