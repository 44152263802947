import React from "react";
import { Select } from 'antd';
const Option = Select.Option

export default ({ tags, allTags, update, mode='tags', size='small', style={width: '100%'}, disabled = false, className }) => {
  const children = (allTags || []).map(tag => <Option key={tag}>{tag}</Option>)
  return (
    <Select
      mode={mode}
      size={size}
      placeholder="Enter tag name"
      value={tags}
      disabled={disabled}
      onChange={update}
      className={className}
      style={style}
    >
      {children}
    </Select>
  )
}
