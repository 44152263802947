import React from 'react'
import { Radio } from 'antd'
import Text from "../../../../../../components/i18n/text";
import {DATE_OPTIONS} from "../../../../../../common/constants";

const actionOptions = ['', DATE_OPTIONS.WEEK, DATE_OPTIONS.MONTH, DATE_OPTIONS.YEAR]

export default ({value, onChange}) => {
  return <Radio.Group
      onChange={onChange}
      value={value}>
      {actionOptions.map(option => (<Radio.Button key={option} value={option}>
        {option ? <Text path={`CONSTANTS.TIME_PERIOD.${option}`}/> : '--'}
      </Radio.Button>))
      }
    </Radio.Group>
}
