import {DATE_OPTIONS} from "../../../../common/constants";

export const GRID_MODE = {
  DAY: DATE_OPTIONS.DAY,
  WEEK: DATE_OPTIONS.WEEK,
  MONTH: DATE_OPTIONS.MONTH
};

export const GRID_VIEW_PERIOD = {
  WEEK: DATE_OPTIONS.WEEK,
  MONTH: DATE_OPTIONS.MONTH,
  YEAR: DATE_OPTIONS.YEAR
};

export const PERIOD_MODES = {
  [GRID_VIEW_PERIOD.WEEK]: [GRID_MODE.DAY],
  [GRID_VIEW_PERIOD.MONTH]: [GRID_MODE.DAY, GRID_MODE.WEEK],
  [GRID_VIEW_PERIOD.YEAR]: [GRID_MODE.MONTH],
};

export const MOMENT_MODE_INDEX_METHODS = {
  [DATE_OPTIONS.DAY]: 'dayOfYear',
  [DATE_OPTIONS.WEEK]: 'week',
  [DATE_OPTIONS.MONTH]: 'month',
  [DATE_OPTIONS.YEAR]: 'year'
};

export const MOMENT_MODE_ADD_PROPERTIES = {
  [GRID_MODE.DAY]: 'd',
  [GRID_MODE.WEEK]: 'w',
  [GRID_MODE.MONTH]: 'M'
};

export const MODE_SCALES = {
  [GRID_MODE.DAY]: 1,
  [GRID_MODE.WEEK]: 2,
  [GRID_MODE.MONTH]: 3,
  [GRID_VIEW_PERIOD.YEAR]: 4
};
