import React from 'react'
import {eventValue} from "../../utils/index";
import PeriodSelect from "../action-habit-period-select";
import { Popover } from 'antd';
import { ReloadOutlined } from '@ant-design/icons'
import Text from "../../../components/i18n/text";

class PeriodSelectPopover extends React.Component {
  state = {
    visible: false
  };

  render() {
    const {item, updateItem} = this.props;

    const changePeriod = (e) => {
      const period = eventValue(e);
      this.handleVisibleChange(false);
      updateItem({id: item.id, period})
    };
    const periodSelect = <PeriodSelect type={item.type} value={item.period} onChange={changePeriod} />

    return <Popover
      content={periodSelect}
      title="Period"
      trigger="click"
      visible={this.state.visible}
      onVisibleChange={this.handleVisibleChange}
    >
      <ReloadOutlined /> <span className="cursor-pointer"><Text path={`CONSTANTS.CURRENT_PERIOD_TEXT.${item.period}`}/></span>
    </Popover>
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible })
  }
}

export default PeriodSelectPopover
