import { combineReducers } from 'redux'
import { omit } from 'lodash'
import { notesSelector } from '../selectors/notes-selectors'
import {NOTE_ACTION, STORAGE_ACTION} from '../types'
import moment from 'moment'

export const initialItems = {
  byId: {},
  selectedId: null
};

export const byIdReducer = (state = initialItems.byId, action) => {
  switch(action.type) {
    case NOTE_ACTION.ADD_NOTE: {
      const dateCreated = moment().valueOf()
      return {...state, [action.note.id]: {...action.note, dateCreated}}
    }
    case NOTE_ACTION.DELETE_NOTE: return omit(state, action.id)
    case NOTE_ACTION.UPDATE_NOTE: {
      const updatedResult = {...state[action.note.id], ...action.note}
      return {...state, [updatedResult.id]: updatedResult}
    }
    case STORAGE_ACTION.RESTORE: {
      const notes = notesSelector(action.state)
      return notes ? {...notes.byId} : state
    }
    default: return state
  }
}

export const selectedIdReducer = (state = initialItems.selectedId, action) => {
  switch(action.type) {
    case NOTE_ACTION.SELECT_NOTE: return action.id
    case NOTE_ACTION.DELETE_NOTE: return state === action.id ? null : state
    default: return state
  }
}

export default combineReducers({
  byId: byIdReducer,
  selectedId: selectedIdReducer
})
