import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {mapDispatchActionToProps, textMethod} from "../../../common/utils/index";
import {VIEW_TYPE} from "../../../common/constants";
import {withAuthorization} from "../../../common/utils/auth/with-authorization";
import PageTemplate from "../page-template/PageTemplate";
import HabitsFilterHeader from "./components/habits-filter-header/HabitsFilterHeader";
import {habitsArraySelector} from "../../../common/selectors/habits-selectors";
import moment from "moment";
import {GRID_MODE, GRID_VIEW_PERIOD, MODE_SCALES} from "./common/constants";
import HabitsTable from "./components/habits-table/habits-table"
import {modeScaleSort} from "./common/utils";
import "./HabitsPage.css"
import NewItem from "../../../common/components/new-item/new-item";
import HabitAmendModal from "./components/habit-amend-modal/habit-amend-modal";

const existingModesReducer = (periodsArr, {period}) => (periodsArr.includes(period) ? periodsArr : [period, ...periodsArr])

const HabitsPage = (props) => {
  const {text, habits} = props;

  const [existingModes, changeExistingModes] = useState([]);
  const [period, periodChange] = useState(GRID_VIEW_PERIOD.WEEK);
  const [mode, modeChange] = useState(GRID_MODE.DAY);
  const [selectedDate, selectedDateChange] = useState(moment());

  const [amendedHabit, amendHabit] = useState(null);
  const showNewItemModal = () => {amendHabit({});};

  const modes = periodModes(period, mode);
  const habitsToDisplay = habits.filter(habit => modes.includes(habit.period));

  useEffect(() => {
    if (window.innerWidth > 1150) {
      periodChange(GRID_VIEW_PERIOD.MONTH);
    }
  }, []);

  useEffect(() => {
    const modes = habits.reduce(existingModesReducer, []);
    modes.sort(modeScaleSort);
    changeExistingModes(modes);

    const startPeriod = modes.includes(GRID_MODE.DAY) ? GRID_VIEW_PERIOD.WEEK : (modes.includes(GRID_MODE.WEEK) ? GRID_VIEW_PERIOD.MONTH : GRID_VIEW_PERIOD.YEAR);
    periodChange(startPeriod);

    const startMode = modes.includes(GRID_MODE.DAY) ? GRID_MODE.DAY : (modes.includes(GRID_MODE.WEEK) ? GRID_MODE.WEEK : GRID_MODE.MONTH);
    modeChange(startMode);
  }, [habits]);

  const headerProps = {
    existingModes,
    mode,
    modeChange,
    period,
    periodChange,
    selectedDate,
    selectedDateChange,
    showNewItemModal
  };

  const tableProps = {
    amendHabit,
    mode,
    viewPeriod: period,
    selectedDate,
    habits: habitsToDisplay
  };

  const habitAmendModalProps = {
    habit: amendedHabit,
    finishAmend: () => amendHabit(null)
  };

  return <PageTemplate
    selectedView={VIEW_TYPE.HABITS}
    pageTitle={text("VIEWS.habits")}
  >
    <div className="habits">
      <div className="header">
        <HabitsFilterHeader {...headerProps}/>
      </div>
      <div className="content">
        <HabitsTable {...tableProps}/>
        <NewItem className="desktop" path="PAGES.HABITS.ADD_NEW" showNewItemModal={showNewItemModal}/>
        <HabitAmendModal {...habitAmendModalProps}/>
      </div>
      <div className="footer mobile">
        <NewItem path="PAGES.HABITS.ADD_NEW" showNewItemModal={showNewItemModal}/>
      </div>
    </div>
  </PageTemplate>
};

const periodModes = (period, mode) => {
  switch (period) {
    case GRID_VIEW_PERIOD.WEEK: {
      return [GRID_MODE.DAY, GRID_MODE.WEEK]
    }
    case GRID_VIEW_PERIOD.MONTH: {
      return mode === GRID_MODE.DAY
        ? [GRID_MODE.DAY, GRID_MODE.WEEK, GRID_MODE.MONTH]
        : [GRID_MODE.WEEK, GRID_MODE.MONTH]
    }
    case GRID_VIEW_PERIOD.YEAR: {
      return [GRID_MODE.MONTH]
    }
  }
};

const mapStateToProps = (state, ownProps) => ({
  habits: habitsArraySelector(state, ownProps),
  text: textMethod(state)
});

const HabitsPageController = connect(mapStateToProps, mapDispatchActionToProps)(HabitsPage);
export default withAuthorization(HabitsPageController)
