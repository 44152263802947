import React from 'react'
import { Modal } from 'antd'
import {itemsBulkDelete, itemsBulkUpdate} from "../actions/item-actions";
import moment from "moment";
import {toString, toLower, omit, clone} from "lodash";
import {getPeriodIndex, getPeriodMoment, isToday} from "./date-utils";
import DatePicker from "../components/date-picker/date-picker";

export const getChildrenMaxOrder = ({children}) => {
  if (!children || children.length === 0) return 0;

  const reducer = (previousMax, item) => Math.max(previousMax, item.order);

  return children.reduce(reducer, 0)
};

export const getNextSiblingOrder = ({id, order}, {children=[]}) => {
  const reducer = (previousMax, item) =>
    previousMax === 0
      ? (item.order > order ? item.order : previousMax)
      : (item.order > order && item.order < previousMax ? item.order : previousMax);

  const nextOrder = children.reduce(reducer, 0);

  return nextOrder === 0 ? order + 1 : (nextOrder + order) / 2
};

export const deleteSelected = ({selectedItemsIds, itemsWithChildren, dispatchAction}) => {
  deleteItems(selectedItemsIds, itemsWithChildren, dispatchAction)
};

export const deleteItems = (itemsIds, itemsWithChildren, dispatchAction) => {
  let childrenIds = [];
  itemsIds.forEach(id => childrenIds = childrenIds.concat(childrenIdsById(id, itemsWithChildren)));

  const childrenText = childrenIds.length === 0 ? '' : ` with ${childrenIds.length} sub item(s)`;

  Modal.confirm({
    title: 'Are you sure?',
    content: `You are going to delete ${itemsIds.length} item(s)${childrenText}`,
    okText: 'Delete',
    okType: 'danger',
    onOk(){
      dispatchAction(itemsBulkDelete(itemsIds.concat(childrenIds)))
    }
  })
};

export const doneSelected = ({selectedItemsIds, dispatchAction}, done) => {
  const doneDate = done ? moment().valueOf() : null;
  dispatchAction(itemsBulkUpdate(selectedItemsIds.map(id => ({id, done, doneDate}))))
};

const childrenIdsById = (id, itemsWithChildren) => {
  const item = itemsWithChildren[id];
  if (!item || !item.children) return [];

  let childrenIds = item.children.map(child => child.id);
  childrenIds.forEach(cid => childrenIds = childrenIds.concat(childrenIdsById(cid, itemsWithChildren)));

  return childrenIds
};

export const filtersFit = (item, filters, timeInThePast=0) => {
  const oldItem = timeInThePast ? timeMachine(item, timeInThePast): item;

  for (let i=0; i<filters.length; i++) {
    if (!filterFit(item, filters[i])) {
      if (timeInThePast) {
        if (!filterFit(oldItem, filters[i])) return false
      } else {
        return false
      }
    }
  }

  return true
};

export const filterFit = (item, filter) => {
  const {property, value, exactMatch, condition} = filter;

  if (value === null || value === '') return true;

  //if (value && !item[property]) return false

  if (exactMatch) return value ? item[property] === value : !item[property];

  return condition(item, property, value)
};

export const filter = (property, value, exactMatch = false, condition = containsCaseInsensitive) => {
  return {
    property, value, exactMatch, condition
  }
};

export const historyItem = (propertyName, oldValue = null, newValue = null) => ({
  propertyName,
  oldValue,
  newValue,
  date: moment().valueOf()
});

export const updateWithHistory = (byId, update, nonTrackedProperties) => {
  const old = byId[update.id];
  let history = old.history ? [...old.history] : [];

  Object.keys(omit({...update}, nonTrackedProperties)).forEach(key => {
    history.push(historyItem(key, old[key], update[key]))
  });

  return {...old, ...update, history}
};

// conditions
export const containsCaseInsensitive = (item, property, value) => {
  return toLower(toString(item[property])).indexOf(toLower(toString(value))) >= 0
};

export const lessThen = (item, property, value) => {
  return +item[property] <= value
};

export const lessOrEmpty = (item, property, value) => {
  return !item[property] || +item[property] <= value
};

export const moreThen = (item, property, value) => {
  return +item[property] >= value
};

export const arrayIncludesArray = (item, property, value) => {
  for (let i=0; i<value.length; i++) {
    if (!item[property] || !item[property].includes(value[i])) return false
  }

  return true
};

export const withDateOrPeriod = (item, property, value) => {
  return item.dateFrom || item.period
};

export const isNull = (item, property, value) => {
  return !item[property]
};

// time machine
export const yearPeriodIndexTimeMachine = (year, period, index) => item => {
  if (!item.history) return item;

  const now = moment();
  const curIndex = getPeriodIndex(now, period);

  // we are in that period or in future, no need to roll back in past
  const nowOrInFuture = year > now.year() || (year === now.year() && index >= curIndex);
  if (nowOrInFuture) return item;

  const edgeTime = getPeriodMoment(year, period, index).endOf(period).valueOf()

  return timeMachine(item, edgeTime)
};

export const timeMachine = (item, edgeTime) => {
  if (!item.history) return item;

  const pastClone = clone(item);

  for(let i=item.history.length-1; i>=0; i--) {
    const {propertyName, oldValue, date} = item.history[i];
    if (date > edgeTime) {
      pastClone[propertyName] = oldValue
    } else break
  }

  return pastClone
};

export const dateChangeAmount = (year, period, index) => item => {
  const edgeTime = getPeriodMoment(year, period, index).endOf(period).valueOf();
  const history = item.history || [];
  return history.filter(h => h.propertyName === 'dateFrom' && h.date <= edgeTime).length
};

export const getItemDateIcon = ({dateFrom, id}, updateItem) => {
  const now = moment();
  const pickerDate = dateFrom ? moment(dateFrom) : null;
  let dateClass = 'no-text ';

  if (pickerDate) {
    dateClass += isToday(pickerDate)
      ? 'today'
      : (pickerDate.isBefore(now) ? 'past' : 'future')
  }
  const changeDate = (moment) => {
    if(moment) {
      updateItem({id, dateFrom: moment.valueOf()})
    } else {
      updateItem({id, dateFrom: null, timeFrom: null})
    }

  };
  return <DatePicker value={pickerDate} onChange={changeDate} className={dateClass}/>
};
