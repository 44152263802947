import {createSelector} from "reselect";
import {filter} from "../utils/item-utils";
import {indexSelector, periodSelector, searchStringSelector, yearSelector} from "./own-props-selectors";
import {searchFilter} from "./selector-utils";
import {sortBy, property} from "lodash";
import {DATE_OPTIONS, ITEM_TYPE} from "../constants";
import doneItemModel from "../models/done-item-model";

export const habitsSelector = state => state.habits
export const habitsByIdSelector = state => (state.habits && state.habits.byId) || {}
export const habitsModeSelector = state => (state.habits && state.habits.mode) || DATE_OPTIONS.DAY
export const habitsViewPeriodSelector = state => (state.habits && state.habits.viewPeriod) || DATE_OPTIONS.MONTH

export const isHabitChecked = (record, year, index) => {
  return property(`habit.${record.period}.${year}.${index}.done`)(record)
}

export const habitsArraySelector = createSelector(
  habitsByIdSelector,
  searchStringSelector,
  (byId, search) => {
    const filters = [
      filter('name', search)
    ]

    const habits = Object.keys(byId)
      .map(id => byId[id])
      .filter(searchFilter(filters))

    return sortBy(habits, ['order'])
  }
)

export const habitsAmountSelector = createSelector(
  habitsByIdSelector,
  (byId) => {
    return Object.keys(byId).length
  }
)

export const maxHabitsOrderSelector = createSelector(
  habitsByIdSelector,
  (byId) => {
    return Object.keys(byId)
      .map(id => byId[id].order || 0)
      .reduce((res, order) => (order > res ? order : res), 0)
  }
)

export const periodFilteredResultHabitsSelector = createSelector(
  habitsByIdSelector,
  periodSelector,
  yearSelector,
  indexSelector,
  (byId, period, year, index) => {
    const filters = [filter('period', period, true)]

    const allIds = Object.keys(byId)

    const habits = allIds
      .map(id => ({...byId[id], filtered: true, type: ITEM_TYPE.HABIT}))
      .map(doneItemModel)
      .filter(searchFilter(filters))

    const {planned, done} = habits.reduce(
      ({planned, done}, habit) => {
        const checked = isHabitChecked(habit, year, index)
        if (checked) return {planned, done: [...done, habit]}
        else return {planned: [...planned, habit], done}
      },
      {planned: [], done: []}
    )

    return {planned, done}
  }
)
