import { combineReducers } from 'redux'
import { omit } from 'lodash'
import { resultsSelector } from '../selectors/results-selectors'
import {RESULT_ACTION, STORAGE_ACTION} from '../types'
import {updateWithHistory} from "../utils/item-utils";
import moment from 'moment'

export const initialItems = {
  byId: {}
};

const NON_TRACKED_PROPERTIES = ['id', 'name', 'content'];

export const byIdReducer = (state = initialItems.byId, action) => {
  switch(action.type) {
    case RESULT_ACTION.ADD_RESULT: {
      const dateCreated = moment().valueOf();
      return {...state, [action.result.id]: {...action.result, dateCreated}}
    }
    case RESULT_ACTION.DELETE_RESULT: return omit(state, action.id);
    case RESULT_ACTION.UPDATE_RESULT: {
      const updatedResult = updateWithHistory(state, action.result, NON_TRACKED_PROPERTIES);
      return {...state, [updatedResult.id]: updatedResult}
    }
    case STORAGE_ACTION.RESTORE: {
      const results = resultsSelector(action.state);
      return results ? {...results.byId} : state
    }
    default: return state
  }
};

export default combineReducers({
  byId: byIdReducer
})
