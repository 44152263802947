import React from 'react'
import {connect} from 'react-redux'
import { Tooltip, Checkbox } from 'antd';
import { SelectOutlined, DeleteOutlined, ClockCircleOutlined } from '@ant-design/icons'
import DatePicker from "../../../../../../common/components/date-picker/date-picker";
import {dateChangeAmount, deleteItems} from "../../../../../../common/utils/item-utils";
import {getItemPath} from "../../../../../../common/selectors/selector-utils";
import {goalsSelector, itemsWithChildrenSelector} from "../../../../../../common/selectors/goals-selectors";
import moment from 'moment'
import {getPeriodIndex} from "../../../../../../common/utils/date-utils";
import {itemUpdate} from "../../../../../../common/actions/item-actions";
import {mapDispatchActionToProps} from "../../../../../../common/utils/index";
import { Link } from 'react-router-dom'
import './planned-action-name-renderer.css'

class PlannedActionNameRenderer extends React.Component {
  render() {
    const {record, result, items:{byId}, itemsWithChildren, dispatchAction} = this.props;

    const planned = record.filtered;
    const className = 'planned-item-name' + (planned ? ' planned' : '');

    const timeTitle = moment(record.dateFrom).format('DD.MM');
    const dateChanges = dateChangeAmount(result.year, result.period, result.index)(record);
    const dateChangesStr = dateChanges > 0 ? ` (${dateChanges}*)` : '';
    const nameTitle = (planned ? (record.name + dateChangesStr) : getItemPath(byId, record.id).map(i => i.name).join(' > '));
    const detailsURL = `/app/actions#${record.id}`;

    const curYear = moment().year();
    const curIndex = getPeriodIndex(moment(), result.period);
    const showActionIcons = result.year > curYear || (curYear === result.year && (result.index >= curIndex));

    const doneItem = () => {dispatchAction(itemUpdate({id: record.id, done: true, doneDate: moment().valueOf()}))};
    const doneCheckbox = <Checkbox checked={false} onChange={doneItem}/>;

    const changeDate = (moment) => {dispatchAction(itemUpdate({id: record.id, dateFrom: moment.valueOf()}))};
    const dateIcon = <DatePicker onChange={changeDate} className='no-text'/>;

    const deleteItem = () => {deleteItems([record.id], itemsWithChildren, dispatchAction)};
    const deleteIcon = <DeleteOutlined onClick={deleteItem} />;

    let plannedIcons = null;

    if (planned) {
      if (showActionIcons) {
        plannedIcons = <Tooltip title={timeTitle} placement="topLeft">
          {doneCheckbox}
          {dateIcon}
          {deleteIcon}
        </Tooltip>
      } else {
        plannedIcons = <Tooltip title={timeTitle} placement="topLeft">
          <ClockCircleOutlined />
        </Tooltip>
      }
    }

    return <span className={className}>
      {plannedIcons}
      <Tooltip title={nameTitle} placement="topLeft">
        <span className='long-name'>{record.name}</span>
      </Tooltip>
      <Link className='float-right' to={detailsURL}><SelectOutlined /></Link>
    </span>
  }
}

const mapStateToProps = (state, ownProps) => ({
  items: goalsSelector(state),
  itemsWithChildren: itemsWithChildrenSelector(state)
});

export default connect(mapStateToProps, mapDispatchActionToProps)(PlannedActionNameRenderer)
