import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Drawer} from 'antd'
import {mapDispatchActionToProps, textMethod} from "../../../../../common/utils";
import "./ItemsProperties.css";
import ItemEdit from "./components/ItemEdit";

const ItemsProperties = (props) => {
  const {selectedItemsIds = [], stopEditing, text} = props;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (selectedItemsIds.length > 0) {
      setVisible(true);
    }
  }, [selectedItemsIds]);

  const closeModal = () => {
    setVisible(false);
    setTimeout(stopEditing, 1000);
  };

  const drawerProps = {
    visible,
    width: 310,
    onClose: closeModal,
    title: text(`VIEWS.PROPERTIES.${selectedItemsIds.length === 1 ? 'title' : 'groupTitle'}`)
  };

  const itemEditProps = {
    selectedItemsIds
  };

  return <Drawer {...drawerProps}>
    <ItemEdit {...itemEditProps}/>
  </Drawer>
};

const mapStateToProps = (state, ownProps) => ({
  text: textMethod(state)
});

export default connect(mapStateToProps, mapDispatchActionToProps)(ItemsProperties)
