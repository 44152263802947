import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {eventValue, mapDispatchActionToProps, textMethod} from "../../../../../common/utils";
import {Input} from "antd";
import ReactQuill from "react-quill";
import "./ResultsNote.css";
import {getPeriodDateString} from "../../common/utils";
import {useDebounce} from "../../../../../common/hooks/useDebounce";
import {resultAdd, resultUpdate} from "../../../../../common/actions/result-actions";
import uuid from "uuid-random";
import {getPeriodIndex} from "../../../../../common/utils/date-utils";

const EMPTY_HTML_CONTENT = '<p><br></p>';

const ResultsNote = (props) => {
  const {note, period, selectedDate, dispatchAction, text} = props;
  const {id, name, content} = note || {};

  const [noteChanged, setNoteChanged] = useState(false);
  const [noteName, noteNameChange] = useState("");
  const debouncedNoteName = useDebounce(noteName, 1000);
  const [noteContent, noteContentChange] = useState("");
  const debouncedNoteContent = useDebounce(noteContent, 1000);

  useEffect(() => {
    console.log('set false');
    setNoteChanged(false);
    noteNameChange(name);
    noteContentChange(content);
  }, [id]);

  const namePlaceholder = `${text(`PAGES.RESULTS.NOTE_TITLE_PLACEHOLDER.${period}`)}: ${getPeriodDateString(selectedDate, period)}`;

  const createOrUpdateResultsNote = (patch = {}) => {
    const {name: patchName, content: patchContent} = patch;

    if (id && (patchName !== name || patchContent !== content)) {
      dispatchAction(resultUpdate({id, name: patchName, content: patchContent}));
    } else if (!id && (patchName || (patchContent && patchContent !== EMPTY_HTML_CONTENT))) {
      const year = selectedDate.year();
      const index = getPeriodIndex(selectedDate, period);
      dispatchAction(resultAdd({id: uuid(), name: patchName || namePlaceholder, content: patchContent || "", year, period, index}));
    }
  };

  useEffect(() => {
    if (noteChanged && (debouncedNoteName || (debouncedNoteContent && debouncedNoteContent !== EMPTY_HTML_CONTENT)))
      createOrUpdateResultsNote({name: debouncedNoteName, content: debouncedNoteContent});
    },
    [debouncedNoteName, debouncedNoteContent]
  );

  const nameChangeHandler = (event) => {setNoteChanged(true);noteNameChange(eventValue(event));};
  const contentChangeHandler = (event) => {noteContentChange(eventValue(event) || "");};
  const contentKeyUpHandler = () => {setNoteChanged(true);};

  return <div className="results-note">
      <Input value={noteName}
             placeholder={namePlaceholder}
             onChange={nameChangeHandler}
             onKeyUp={nameChangeHandler}
             className="title"
      />
      <ReactQuill theme="snow"
                  placeholder={text('PAGES.RESULTS.NOTE_DETAILS_CONTENT_PLACEHOLDER')}
                  value={noteContent || ""}
                  onChange={contentChangeHandler}
                  onKeyUp={contentKeyUpHandler}/>
  </div>
};

const mapStateToProps = (state) => ({
  text: textMethod(state)
});

export default connect(mapStateToProps, mapDispatchActionToProps)(ResultsNote)
