import {createSelector} from "reselect";
import {sortBy} from 'lodash'
import resultModel from "../models/result-model";
import {ownPropsSelector} from "./own-props-selectors";
import {getSearchFilters, itemsTagsList, searchFilter, yearPeriodIndexFilter} from "./selector-utils";

export const resultsSelector = state => state.results;
export const selectedResultPropertySelector = state => state.results.selectedResut;

export const resultsListSelector = createSelector(
  resultsSelector,
  ownPropsSelector,
  ({byId}, ownProps) => {
    const filters = getSearchFilters(ownProps);

    const resultsList = Object.keys(byId)
      .map(id => byId[id])
      .map(resultModel)
      .filter(searchFilter(filters));

    return sortBy(resultsList, [function(res) { return -res.sortTime }])
  }
);

export const selectedResultSelector = createSelector(
  resultsSelector,
  selectedResultPropertySelector,
  ({byId}, selectedResult) => {
    if (!selectedResult) return null;

    if (selectedResult.id) {
      return byId[selectedResult.id]
    } else {
      const {year, period, index} = selectedResult;
      const results = Object.keys(byId).map(id => byId[id]).filter(yearPeriodIndexFilter(year, period, index));

      return results.length > 0 ? results[0] : selectedResult
    }
  }
);

export const resultsTagsSelector = createSelector(
  resultsSelector,
  ({byId}) => {
    return itemsTagsList(byId)
  }
);
