import React from "react";
import { AreaChartOutlined, TableOutlined, FlagOutlined, CopyOutlined } from '@ant-design/icons'

export const ITEM_TYPE = {
  ACTION: 'action',
  HABIT: 'habit'
};

export const VIEW_TYPE = {
  GOALS: 'goals',
  HABITS: 'habits',
  RESULTS: 'results',
  NOTES: 'notes',
  PROFILE: 'profile'
};

export const SIZE = {
  SMALL: 'small',
  DEFAULT: 'default'
};
export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm';

export const DATE_OPTIONS = {
  ANY: '',
  MIN: '10min',
  HOUR: 'hr',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
  PARTICULAR: 'particular'
};

export const DEFAULT_NEW_ITEM = {
  name: 'New action',
  type: 'action'
};

export const APP_DOMAIN = 'https://eddy-planner.com';

export const URL = {
  HOME: '/',
  HOWTO: '/howto',
  PRICING: '/pricing',
  APPLICATION: '/user/',
  SIGN_UP: '/signup',
  SIGN_IN: '/signin',
  PASSWORD_FORGET: '/pw-forget',
  GOALS: '/user/goals',
  HABITS: '/user/habits',
  NOTES: '/user/notes',
  RESULTS: '/user/results',
  PROFILE: '/user/profile'
};

export const EMPTY_HABIT = {
  done: false,
  params:{},
  history: []
};

export const DEFULT_SORT_ATTRIBUTES = ['order', 'id'];

export const VIEWS = {
  goals:    {id: 'goals',     type: VIEW_TYPE.GOALS,    icon: <AreaChartOutlined />, parentId: ''},
  habits:   {id: 'habits',    type: VIEW_TYPE.HABITS,    icon: <TableOutlined />, parentId: ''},
  results:  {id: 'results',   type: VIEW_TYPE.RESULTS,  icon: <FlagOutlined />, parentId: ''},
  notes:    {id: 'notes',     type: VIEW_TYPE.NOTES,   icon: <CopyOutlined />, parentId: ''}
};

export const VIEWS_LIST = [VIEWS.goals, VIEWS.habits, VIEWS.notes, VIEWS.results];

export const IMPORTANCE = {
  MINOR: {value: 1, label: 'Nice to have'},
  NORMAL: {value: 3, label: 'Important'},
  HIGH: {value: 7, label: 'Must have'}
};

export const URGENCY = {
  MINOR: {value: 1, label: 'Minor'},
  NORMAL: {value: 3, label: 'Normal'},
  HIGH: {value: 7, label: 'Urgent'}
};

export const DURATION = {
  MIN5: {value: 5, label: '5 min'},
  MIN15: {value: 15, label: '15 min'},
  MIN30: {value: 30, label: '30 min'},
  HR1: {value: 60, label: '1 hr'},
  HR2: {value: 120, label: '2 hr'},
  HR4: {value: 240, label: '4 hr'},
  D1: {value: 480, label: '1 day'},
};

export const DATE_FILTER = {
  OUTDATED: 'outdated',
  TODAY: 'today',
  NEXT_WEEK: 'nextWeek',
  NO_DATE: 'noDate',
};

export const IS_NULL = 'isNull';

export const DEFAULT_ITEM_DURATION = 30;
export const MS_IN_MIN = 60000;

export const LANGUAGE = {
  RU: 'RU',
  EN: 'EN'
};

export const EMPTY_OBJECT = {};
export const EMPTY_STRING = '';
