import {List, Modal, Tag} from "antd";
import {DeleteOutlined} from '@ant-design/icons'
import React from "react";
import {DATE_OPTIONS} from "../../../../../common/constants";
import {resultDelete} from "../../../../../common/actions/result-actions";
import {getPeriodMoment} from "../../../../../common/utils/date-utils";
import './resultListItemRenterer.css';
import {getPeriodDateString} from "../../common/utils";

const itemRenderer = (dispatchAction, selectedNote, selectResultsNote, text) => (resultsNote) => {
  const {id, name} = resultsNote;

  const resultsNoteClick = () => {
    selectResultsNote(id);
  };

  const deleteResultsNoteClick = (event) => {
    event.stopPropagation();

    const title = text("DELETE.areYouSure");
    const content = `${text("DELETE.willBeDeleted")}:"${name}"`;
    const okText = text("DELETE.delete");
    const cancelText = text("DELETE.cancel");

    Modal.confirm({
      title,
      content,
      okText,
      cancelText,
      okType: 'danger',
      onOk() {
        dispatchAction(resultDelete(id));
        if (selectedNote && id === selectedNote.id) {
          selectResultsNote(null);
        }
      }
    })
  };

  const className = `results-note-list-item ${selectedNote && id === selectedNote.id ? 'selected' : ''}`

  const title = name && name.trim().length > 0 ? name : text('PAGES.NOTES.NO_NAME');

  const resultTags = getResultTags(resultsNote);
  const tags = <span>{resultTags.map(t => <Tag key={t.name}>{t.name}</Tag>)}</span>

  const description = <div className="actions">
    {tags}
    <DeleteOutlined onClick={deleteResultsNoteClick}/>
  </div>;

  return <List.Item className={className} onClick={resultsNoteClick}>
    <List.Item.Meta
      title={title}
      description={description}
    />
  </List.Item>
};

const getResultTags = (result) => {
  let resultTags = [];

  resultTags.push({name: result.period, property: 'period', value: result.period});

  const resultDate = getPeriodMoment(result.year, result.period, result.index);
  resultTags.push({name: getPeriodDateString(resultDate, result.period)});

  if (result.tags) {
    result.tags.forEach(tag => resultTags.push({name: tag, property: 'tags', value: tag}))
  }

  return resultTags
};

export default itemRenderer;
