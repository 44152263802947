export const ITEM_ACTION = {
    BULK_ADD_ITEMS: 'BULK_ADD_ITEMS',
    BULK_DELETE_ITEMS: 'BULK_DELETE_ITEMS',
    UPDATE_ITEM: 'UPDATE_ITEM',
    BULK_UPDATE_ITEMS: 'BULK_UPDATE_ITEMS',
    ITEM_PARENT_CHANGE: 'ITEM_PARENT_CHANGE',
    HABIT_CHANGE: 'HABIT_CHANGE',
    SELECT_ITEMS: 'SELECT_ITEMS'
};

export const HABIT_ACTION = {
  ADD_HABIT: 'ADD_HABIT',
  BULK_ADD_HABITS: 'BULK_ADD_HABITS',
  DELETE_HABIT: 'DELETE_HABIT',
  UPDATE_HABIT: 'UPDATE_HABIT',
  BULK_UPDATE_HABITS: 'BULK_UPDATE_HABITS',
  SELECT_HABIT: 'SELECT_HABIT',
  HABIT_CHANGE: 'HABIT_CHANGE',
  VIEW_MODE_CHANGE: 'VIEW_MODE_CHANGE',
  VIEW_PERIOD_CHANGE: 'VIEW_PERIOD_CHANGE'
};

export const VIEW_ACTION = {
  ADD_VIEW: 'ADD_VIEW',
  DELETE_VIEW: 'DELETE_VIEW',
  UPDATE_VIEW: 'UPDATE_VIEW',
  SELECT_VIEW: 'SELECT_VIEW'
};

export const RESULT_ACTION = {
  ADD_RESULT: 'ADD_RESULT',
  DELETE_RESULT: 'DELETE_RESULT',
  UPDATE_RESULT: 'UPDATE_RESULT',
  SELECT_RESULT_BY_ID: 'SELECT_RESULT_BY_ID',
  SELECT_RESULT_BY_PROPS: 'SELECT_RESULT_BY_PROPS'
};

export const NOTE_ACTION = {
  ADD_NOTE: 'ADD_NOTE',
  DELETE_NOTE: 'DELETE_NOTE',
  UPDATE_NOTE: 'UPDATE_NOTE',
  SELECT_NOTE: 'SELECT_NOTE'
};

export const USER_ACTION = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT'
};

export const STORAGE_ACTION = {
  SAVE: 'SAVE',
  RECEIVE: 'RECEIVE',
  RESTORE: 'RESTORE'
};

export const I18N_ACTION = {
  LANGUAGE_CHANGE: 'LANGUAGE_CHANGE'
};

export const PROPERTIES_ACTION = {
  CHANGE_GOALS_VIEW: 'CHANGE_GOALS_VIEW'
};

export const CUSTOM_EVENTS = Object.freeze({
  EDIT_ACTION: 'EDIT_ACTION',
  DRAG_ACTION: 'DRAG_ACTION',
  TIMER_EVENT: 'TIMER_EVENT',
  TIMER_EXTERNAL_EVENT: 'TIMER_EXTERNAL_EVENT'
});
