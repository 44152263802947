import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import Select from "../../../../../common/components/select";
import {eventValue, textMethod} from "../../../../../common/utils";
import {DATE_OPTIONS} from "../../../../../common/constants";
import {Input} from "antd";
import {useDebounce} from "../../../../../common/hooks/useDebounce";
import './ResultsListFilter.css';
import PlusButton from "../../../../../common/components/plus-button/PlusButton";

const periodFilterOptions = (text) => ([
  {label: text('CONSTANTS.ALL'), value: null},
  {label: text(`CONSTANTS.TIME_PERIOD.${DATE_OPTIONS.DAY}`), value: DATE_OPTIONS.DAY},
  {label: text(`CONSTANTS.TIME_PERIOD.${DATE_OPTIONS.WEEK}`), value: DATE_OPTIONS.WEEK},
  {label: text(`CONSTANTS.TIME_PERIOD.${DATE_OPTIONS.MONTH}`), value: DATE_OPTIONS.MONTH},
  {label: text(`CONSTANTS.TIME_PERIOD.${DATE_OPTIONS.YEAR}`), value: DATE_OPTIONS.YEAR},
]);

const ResultsListFilter = (props) => {
  const {searchText, changeSearchText, searchPeriod, changeSearchPeriod, text, createNewResultsNote} = props;

  const [innerSearchText, changeInnerSearchText] = useState(searchText);
  const searchChange = (event) => {changeInnerSearchText(eventValue(event));};
  const debouncedSearchValue = useDebounce(innerSearchText, 1000);

  useEffect(() => {
    changeSearchText(debouncedSearchValue);
  }, [debouncedSearchValue]);

  return <div className="results-list-filter">
    <Select className="period-select"
            value={searchPeriod}
            options={periodFilterOptions(text)}
            onChange={changeSearchPeriod}
            placeholder="Period filter"
    />
    <Input.Search
      value={innerSearchText}
      placeholder={text("PAGES.NOTES.SEARCH_PLACEHOLDER")}
      onChange={searchChange}
      onKeyUp={searchChange}
      allowClear
      className="name-filter"
    />
    <PlusButton onClick={createNewResultsNote}/>
  </div>
};

const mapStateToProps = (state) => ({
  text: textMethod(state),
});

export default connect(mapStateToProps)(ResultsListFilter)
