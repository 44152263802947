import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { Form, Input, Button, message } from 'antd';
import { MailOutlined, LockOutlined, GoogleOutlined, FacebookOutlined } from '@ant-design/icons'
import {mapDispatchActionToProps, textMethod} from "../../../common/utils/index";
import {doSignInWithGoogleAccount, doSignInWithFacebookAccount, doSignInWithEmailAndPassword} from '../../../common/utils/firebase/auth'
import Text from "../../i18n/text";

const FormItem = Form.Item;

class LoginForm extends React.Component {
  formRef = React.createRef();

  render() {
    const {register, resetPass, text} = this.props;

    return (
      <Form ref={this.formRef} onFinish={this.handleSubmit} className="login-form">
        <FormItem name="email" rules={[{ required: true, message: text('AUTH.LOGIN.ERR.EMAIL_REQUIRED') }]}>
          <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={text('AUTH.LOGIN.EMAIL_PLACEHOLDER')} />
        </FormItem>
        <FormItem name="password" rules={[{ required: true, message: text('AUTH.LOGIN.ERR.PASS_REQUIRED') }]}>
          <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder={text('AUTH.LOGIN.PASS_PLACEHOLDER')} />
        </FormItem>
        <div className='reset-password'>
          <Button type="link" className="padding0" onClick={resetPass}><Text path="AUTH.LOGIN.FORGOT_PASS"/></Button>
        </div>
        <div className='buttons-holder w100'>
          <Button type="link" onClick={register} className="padding0"><Text path="AUTH.LOGIN.REGISTER"/></Button>
          <div className="buttons-holder">
            <Button icon={<img src="/images/google-icon.png" width="26" height="26" alt=""/>} shape="circle" onClick={this.signInWithGoogle}/>
            <Button icon={<img src="/images/fb-icon.png" width="26" height="26" alt=""/>} shape="circle" onClick={this.signInWithFB}/>
            <Button type="primary" htmlType="submit" className="login-form-button"><Text path="AUTH.LOGIN.LOGIN"/></Button>
          </div>
        </div>
      </Form>
    );
  }

  handleSubmit = (values) => {
    const {cancel, text} = this.props;
    const {email, password} = values;

    doSignInWithEmailAndPassword(email, password)
      .then(() => {
        cancel(true)
      }).catch(err => {
        this.formRef.current.setFieldsValue({
          email: '',
          password: ''
        });
        console.log(err);
        message.error(text('AUTH.LOGIN.ERR.LOGIN_FAILED'));
      })
  };

  signInWithGoogle = () => {
    doSignInWithGoogleAccount().then(() => {
      this.props.cancel(true)
    })
  };

  signInWithFB = () => {
    doSignInWithFacebookAccount().then(() => {
      this.props.cancel(true)
    })
  };
}

LoginForm.propTypes = {
  register: PropTypes.func,
  cancel: PropTypes.func
};

const mapStateToProps = (state) => ({
  text: textMethod(state)
});

export default connect(mapStateToProps, mapDispatchActionToProps)(LoginForm)
