import React from 'react'
import {connect} from 'react-redux'
import {Drawer, Radio} from "antd";
import {
  RocketOutlined,
  HomeOutlined,
  ArrowRightOutlined,
  GlobalOutlined,
  LogoutOutlined
} from '@ant-design/icons'
import "./MobileMenu.css"
import {languageSelector} from "../../../../../common/selectors/app-selectors";
import {mapDispatchActionToProps, textMethod} from "../../../../../common/utils";
import {LANGUAGE} from "../../../../../common/constants";
import {languageChange} from "../../../../../common/actions/i18n-actions";
import {doSignOut} from "../../../../../common/utils/firebase/auth";
import Text from "../../../../../components/i18n/text";

const MobileMenu = (props) => {
  const {visible, closeMenu, language, dispatchAction, text} = props;

  const menuProps = {
    title: <span><RocketOutlined />&nbsp;EDDY</span>,
    placement: "left",
    onClose: closeMenu,
    visible,
    width: 300,
    className: "mobile-menu",
    extra: <span/>
  };

  const changeLanguage = (newLang) => {
    dispatchAction(languageChange(newLang));
  };

  const selectDifferentLanguage = () => {
    changeLanguage(language === LANGUAGE.RU ? LANGUAGE.EN : LANGUAGE.RU);
  };

  const logUserOut = () => {
    const wantsToLogOut = window.confirm(text("TOP_MENU.LOG_OUT_CONFIRM"));
    if (wantsToLogOut) {
      doSignOut().then(() => {
        window.location.pathname = "/";
      });
    }
  };

  return <Drawer {...menuProps}>
    <div className="items-container">
      <a href="/" className="item">
        <div className="title">
          <HomeOutlined />
          <Text path="TOP_MENU.TO_HOME_PAGE"/>
        </div>
        <ArrowRightOutlined />
      </a>
      <div className="item">
        <div className="title" onClick={selectDifferentLanguage}>
          <GlobalOutlined />
          <Text path="TOP_MENU.LANGUAGE_CHANGE"/>
        </div>
        <div>
          {languageChangeComponent(language, changeLanguage)}
        </div>
      </div>
      <div className="item" onClick={logUserOut}>
        <div className="title">
          <LogoutOutlined />
          <Text path="TOP_MENU.LOG_OUT"/>
        </div>
        <ArrowRightOutlined />
      </div>
    </div>
  </Drawer>
};

const languageChangeComponent = (value, languageChange) => {
  const valueChange = ({target:{value}}) => {
    languageChange(value);
  };

  return <Radio.Group
    value={value}
    onChange={valueChange}
    buttonStyle="solid"
  >
    <Radio.Button value={LANGUAGE.RU}>RU</Radio.Button>
    <Radio.Button value={LANGUAGE.EN}>EN</Radio.Button>
  </Radio.Group>
};

const mapStateToProps = (state) => {
  return {
    language: languageSelector(state),
    text: textMethod(state)
  }
};

export default connect(mapStateToProps, mapDispatchActionToProps)(MobileMenu)
