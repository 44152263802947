import { ITEM_ACTION } from '../types'

export const itemsSelect = ids => ({
  type: ITEM_ACTION.SELECT_ITEMS,
  ids
})

export const itemsBulkAdd = items => ({
  type: ITEM_ACTION.BULK_ADD_ITEMS,
  items
})

export const itemsBulkDelete = ids => ({
  type: ITEM_ACTION.BULK_DELETE_ITEMS,
  ids
})

export const itemUpdate = item => ({
  type: ITEM_ACTION.UPDATE_ITEM,
  item
})

export const itemsBulkUpdate = items => ({
  type: ITEM_ACTION.BULK_UPDATE_ITEMS,
  items
})

export const itemParentChange = (id, parentId) => ({
  type: ITEM_ACTION.ITEM_PARENT_CHANGE,
  id,
  parentId
})

export const habitChange = (id, year, index, done, params = null) => ({
  type: ITEM_ACTION.HABIT_CHANGE,
  id,
  year,
  index,
  done,
  params
})