import React from 'react'
import {eventValue, labelByValue} from "../../utils/index";
import { Popover, Radio } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {IMPORTANCE} from "../../constants/index";

const RadioGroup = Radio.Group

class ImportanceSelectPopover extends React.Component {
  state = {
    visible: false
  }

  render() {
    const {item, updateItem} = this.props

    const changeImportance = (e) => {
      const importance = eventValue(e)
      this.handleVisibleChange(false)
      updateItem({id: item.id, importance})
    }

    const importanceSelect = <RadioGroup onChange={changeImportance} value={item.importance}>
      <Radio value={IMPORTANCE.MINOR.value}>{IMPORTANCE.MINOR.label}</Radio>
      <Radio value={IMPORTANCE.NORMAL.value}>{IMPORTANCE.NORMAL.label}</Radio>
      <Radio value={IMPORTANCE.HIGH.value}>{IMPORTANCE.HIGH.label}</Radio>
    </RadioGroup>

    return <Popover
      content={importanceSelect}
      title="Importance"
      trigger="click"
      visible={this.state.visible}
      onVisibleChange={this.handleVisibleChange}
    >
      <ExclamationCircleOutlined title={labelByValue(item.importance, IMPORTANCE)} className={`colorful level${item.importance || ''}`} />
    </Popover>
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible })
  }
}

export default ImportanceSelectPopover
