import React from 'react';

import AuthUserContext from './auth-user-context';
import {connect} from "react-redux";
import {userSelector} from "../../selectors/user-selectors";

const withAuthentication = (Component) => {
  const authenticationWrapper = ({ user }) => {
    return (
      <AuthUserContext.Provider value={user}>
        <Component />
      </AuthUserContext.Provider>
    )
  }

  const mapStateToProps = state => ({user: userSelector(state)})

  return connect(mapStateToProps)(authenticationWrapper)
}

export default withAuthentication;