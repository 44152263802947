import moment from 'moment'
import {DATE_OPTIONS} from "../constants";
import {MOMENT_MODE_ADD_PROPERTIES, MOMENT_MODE_INDEX_METHODS} from "../../pages/user/habits/common/constants";

export const yearWeekToMoment = yearWeekString => moment().day("Monday").year(yearWeekString.split('-')[0]).week(yearWeekString.split('-')[1]);
export const yearMonthToMoment = yearMonthString => moment(yearMonthString, 'YYYY-MM');

export const getPeriodIndex = (date, period) => {
  return date[MOMENT_MODE_INDEX_METHODS[period]]()
};

export const getPeriodMoment = (year, period, index) => {
  let selectedDate = moment().year(year).startOf('year');

  if(period !== DATE_OPTIONS.YEAR) {
    const trueIndex = period === DATE_OPTIONS.DAY ? (index - 1) : index;
    selectedDate.add(trueIndex, MOMENT_MODE_ADD_PROPERTIES[period])
  }

  return selectedDate
};

export const isToday = date => {
  const now = moment();
  return date.year() === now.year() && date.dayOfYear() === now.dayOfYear()
};

export const dayMinutes = date => {
  return date.hours() * 60 + date.minutes()
};

export const getDateString = (dateFrom, todayLabel) => {
  let dateString = '';

  if (dateFrom) {
    const date = moment(dateFrom);
    dateString = isToday(date) ? todayLabel : date.format('DD.MM.YY');
  }

  return dateString
};
