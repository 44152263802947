import { combineReducers } from 'redux'
import {VIEW_ACTION, STORAGE_ACTION} from '../types'
import {omit} from "lodash";
import {VIEW_TYPE} from "../constants";
import {viewsSelector} from "../selectors/views-selectors";

export const initialViews = {
  byId: {
    goals:    {id: 'goals',     type: VIEW_TYPE.GOALS,    name: 'Goals',    icon: 'area-chart', parentId: ''},
    habits:   {id: 'habits',    type: VIEW_TYPE.HABITS,    name: 'Habits',   icon: 'table', parentId: ''},
    results:  {id: 'results',   type: VIEW_TYPE.RESULTS,  name: 'Results',  icon: 'flag', parentId: ''},
    notes:    {id: 'notes',     type: VIEW_TYPE.NOTES,   name: 'Notes',    icon: 'copy', parentId: ''},
  }
}

export const byIdReducer = (state = initialViews.byId, action) => {
  switch(action.type) {
    case VIEW_ACTION.ADD_VIEW: return {...state, [action.view.id]: action.view}
    case VIEW_ACTION.DELETE_VIEW: return omit(state, action.view.id)
    case VIEW_ACTION.UPDATE_VIEW: console.log(action.view); return {...state, [action.view.id]: action.view}
    case STORAGE_ACTION.RESTORE: {
      const views = viewsSelector(action.state)
      return views ? {...views.byId} : state
    }

    default: return state
  }
}

export default combineReducers({
  byId: byIdReducer
})
