import React from 'react'
import {connect} from 'react-redux'
import { Modal, Row, Col, Input, Button } from 'antd'
import {DATE_OPTIONS, DEFAULT_NEW_ITEM} from "../../constants/index";
import DatePicker from '../date-picker/date-picker'
import ImportanceSelect from '../importance-select'
import UrgencySelect from '../urgency-select'
import T from '../../../components/i18n/text'

import './new-item-modal.css'
import {
  maxRootOrderValue, particularItemWithChildrenSelector
} from "../../selectors/goals-selectors";
import uuid from "uuid-random";
import {getChildrenMaxOrder} from "../../../common/utils/item-utils";
import {mapDispatchActionToProps, textMethod} from "../../../common/utils/index";
import {itemsBulkAdd, itemsSelect} from "../../../common/actions/item-actions";
import moment from 'moment'
import {minifyString} from "../../utils";
import Select from "../select";

const { TextArea } = Input;

const DEFAULT_STATE = {
  name: '',
  type: 'action',
  period: '',
  tags: [],
  dateFrom: '',
  timeFrom: '',
  duration: '',
  importance: '',
  urgency: '',

  newLineNewAction: true
};

const periodFilterOptions = (text) => ([
  {label: text('VIEWS.PROPERTIES.PERIOD'), value: ''},
  {label: text(`CONSTANTS.TIME_PERIOD.${DATE_OPTIONS.WEEK}`), value: DATE_OPTIONS.WEEK},
  {label: text(`CONSTANTS.TIME_PERIOD.${DATE_OPTIONS.MONTH}`), value: DATE_OPTIONS.MONTH},
  {label: text(`CONSTANTS.TIME_PERIOD.${DATE_OPTIONS.YEAR}`), value: DATE_OPTIONS.YEAR},
]);

class NewItemModal extends React.Component {
  state = {
    ...DEFAULT_STATE,
  };

  render() {
    const {visible, onCancel, selectedItem, text} = this.props;

    const newItemPlaceholder = text('PAGES.ACTIONS.NEW_ITEM_PLACEHOLDER');
    const modalTitle = !selectedItem ?
      text('PAGES.ACTIONS.ADD_NEW') :
      text('PAGES.ACTIONS.ADD_NEW_TO') + `'${minifyString(selectedItem.name, 20)}'`;

    return <Modal
      title={modalTitle}
      visible={visible}
      onOk={this.handleOk}
      onCancel={this.handleCancel}
      className="new-item-modal"
      width={530}
      footer={[
        <Button key="cancel" onClick={onCancel}><T path='CONSTANTS.CANCEL'/></Button>,
        <Button key="submit" type="primary" onClick={this.handleOk} disabled={!this.state.name}><T path='CONSTANTS.ADD'/></Button>
      ]}
    >
      <div className="parent-container">
        <TextArea rows={5}
                  ref={input => { this.nameInput = input }}
                  placeholder={newItemPlaceholder}
                  value={this.state.name}
                  onChange={this.stateChange('name')}
                  onKeyUp={this.handleKeyboardSubmit}/>
        <div className="properties-holder">
          <div className="time-period-holder">
            <Select className="period-select"
                    value={this.state.period}
                    options={periodFilterOptions(text)}
                    onChange={this.stateChange('period')}
                    placeholder={text('VIEWS.PROPERTIES.PERIOD')}
            />
            <DatePicker
              value={this.state.dateFrom ? moment(this.state.dateFrom) : null}
              onChange={moment => this.stateChange('dateFrom')(moment.valueOf())}
              placeholder={text('VIEWS.PROPERTIES.DATE')}
              placement={"topLeft"}
            />
          </div>
          <div className="importance-urgency-holder">
            <ImportanceSelect
              value={this.state.importance}
              onChange={this.stateChange('importance')}
              size='small'
            />
            <UrgencySelect
              value={this.state.urgency}
              onChange={this.stateChange('urgency')}
              size='small'
            />
          </div>
        </div>
      </div>
    </Modal>
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.visible && !this.props.visible) {
      if (window.location.pathname.indexOf('/app/habits') === 0) {
        this.setState({type: 'habit', period: 'day'})
      }
    }
  }

  componentDidUpdate(prevProps) {
    this.handleInitialFocusSet(prevProps)
  }

  handleOk = () => {
    const {selectedItem, dispatchAction, onCancel, maxRootOrder} = this.props;
    const {name, type ,period, tags, dateFrom, timeFrom, duration, urgency, importance, newLineNewAction} = this.state;

    const childOrder = ((selectedItem && selectedItem.id) ? getChildrenMaxOrder(selectedItem) : maxRootOrder)+1;

    let items = [];
    const names = newLineNewAction ? name.split('\n') : [name];

    for (let i=0; i<names.length; i++) {
      const n = names[i];

      const id = uuid();
      const parentId = selectedItem ? selectedItem.id : null;
      const order = childOrder + i;

      const newItem = {id, name: n, parentId: parentId || null, order, type ,period, tags, dateFrom, timeFrom, duration, urgency, importance};
      items.push({...DEFAULT_NEW_ITEM, ...newItem})
    }

    dispatchAction(itemsBulkAdd(items));

    this.resetState();
    onCancel()
  };

  handleCancel = () => {
    const {onCancel} = this.props;

    this.resetState();
    onCancel()
  };

  handleParentChange = parentId => {
    const {dispatchAction} = this.props;
    dispatchAction(itemsSelect(parentId ? [parentId] : []))
  };

  stateChange = property => e => {
    const value = e.target ? (e.target.value ? e.target.value : e.target.checked) : e;
    this.setState({[property]: value})
  };

  resetState = () => {
    this.setState({...DEFAULT_STATE})
  };

  handleKeyboardSubmit = (event) => {
    if (event.ctrlKey && event.keyCode === 13 && this.state.name) {
      this.handleOk()
    }
  };

  handleInitialFocusSet = prevProps => {
    const {visible} = this.props;

    if (!prevProps.visible && visible && this.nameInput) {
      this.nameInput.focus()
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const {newItemParentId} = ownProps

  return {
    selectedItem: newItemParentId ? particularItemWithChildrenSelector(state, {id: newItemParentId}) : null,
    maxRootOrder: maxRootOrderValue(state),
    text: textMethod(state)
  }
};

export default connect(mapStateToProps, mapDispatchActionToProps)(NewItemModal)
