import {Space} from "antd";
import {
  UserOutlined,
  RightOutlined,
  LeftOutlined,
  AreaChartOutlined,
  TableOutlined,
  CopyOutlined,
  FlagOutlined
} from '@ant-design/icons'
import Text from "../../../components/i18n/text";
import { URL } from "../../../common/constants";
import { Link } from 'react-router-dom'
import React, {useState} from "react";
import { useLocalStorage } from "../../../common/hooks/useLocalStorage";
import { MenuOutlined } from '@ant-design/icons';
import MobileMenu from "./components/mobile-menu/MobileMenu";
import './PageTemplate.css'

const SIDER_SIZE = {
  SMALL: 'small',
  LARGE: 'large',
};

const PageTemplate = (props) => {
  const {selectedView, pageTitle, headerSelect} = props;

  const [sizeClass, setSizeClass] = useLocalStorage("menuSize", SIDER_SIZE.LARGE);

  const changeSiderSize = (size) => (e) => {
    setSizeClass(size)
  };

  const [menuVisible, setMenuVisible] = useState(false);
  const showMenu = () => {
    setMenuVisible(true);
  };
  const closeMenu = () => {
    setMenuVisible(false);
  };

  return <div className={`application ${selectedView}`}>
    <div className="application-header">
      <div className="container left">
        <MenuOutlined onClick={showMenu}/>
      </div>
      <span>{pageTitle}</span>
      <div className="container right">
        {headerSelect}
      </div>
      <MobileMenu visible={menuVisible} closeMenu={closeMenu}/>
    </div>
    <div className="application-content">
      <div className={`app-menu-sider ${sizeClass}`}>
        <Space direction="vertical" className="h100 w100 jc-sb">
          <Space direction="vertical" className="w100" size={0}>
            <Link to={URL.GOALS} className="menu-item goals">
              <div><AreaChartOutlined /></div>
              <span><Text path="VIEWS.goals"/></span>
            </Link>
            <Link to={URL.HABITS} className="menu-item habits">
              <div><TableOutlined /></div>
              <span><Text path="VIEWS.habits"/></span>
            </Link>
            <Link to={URL.NOTES} className="menu-item notes">
              <div><CopyOutlined /></div>
              <span><Text path="VIEWS.notes"/></span>
            </Link>
            <Link to={URL.RESULTS} className="menu-item results">
              <div><FlagOutlined /></div>
              <span><Text path="VIEWS.results"/></span>
            </Link>
          </Space>
          <Space direction="vertical" className="w100" size={0}>
            <Link to={URL.PROFILE} className="menu-item profile">
              <div><UserOutlined /></div>
              <span><Text path="VIEWS.profile"/></span>
            </Link>
            <div className="menu-item menu-size-change small" onClick={changeSiderSize(SIDER_SIZE.SMALL)}>
              <LeftOutlined />
            </div>
            <div className="menu-item menu-size-change large" onClick={changeSiderSize(SIDER_SIZE.LARGE)}>
              <RightOutlined />
            </div>
          </Space>
        </Space>
      </div>
      <div className="app-page-content">
        {props.children}
      </div>
    </div>
  </div>
};

// TODO persist in local storage left side menu state and provide it through props
export default PageTemplate
