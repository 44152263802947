import {DATE_OPTIONS} from "../../../../common/constants";
import {getPeriodIndex} from "../../../../common/utils/date-utils";
import moment from "moment/moment";

export const RESULT_PERIODS = [DATE_OPTIONS.DAY, DATE_OPTIONS.WEEK, DATE_OPTIONS.MONTH, DATE_OPTIONS.YEAR];

export const DEFAULT_TODAY_RESULT = {
  period: DATE_OPTIONS.DAY,
  year: moment().year(),
  index: getPeriodIndex(moment(), DATE_OPTIONS.DAY),

  name: '',
  content:''
};
