import {minifyString} from "../utils/index";
import moment from "moment";
import {MS_IN_MIN} from "../constants";

const MAX_LIST_NAME_LENGTH = 30

export default (periodParams) => (item) => {
  const dateSortParams = getDateSortParams(item, periodParams)
  return {
    ...item,
    dateTo: (item.dateFrom ? moment(item.dateFrom + (item.duration || 30) * MS_IN_MIN) : null),
    listName: minifyString(item.name, MAX_LIST_NAME_LENGTH),
    searchString: `${item.name} ${item.type} ${item.period}`,
    ...dateSortParams
  }
}

const getDateSortParams = (item, periodParams) => {
  if (item.dateFrom) {
    const date = moment(item.dateFrom)
    return {
      year: date.year(),
      day: date.dayOfYear()
    }
  } else if (item.period) {
    return periodParams[item.period]
  }

  return {
    year: 0,
    day: 0
  }
}