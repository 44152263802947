import React from 'react'
import {
  GRID_MODE,
  GRID_VIEW_PERIOD, MODE_SCALES,
  MOMENT_MODE_ADD_PROPERTIES,
  MOMENT_MODE_INDEX_METHODS
} from "./constants";
import {property} from "lodash";
import moment from "moment";
import {DATE_OPTIONS} from "../../../../common/constants";
import HabitNameRenderer from "../components/habits-table/habit-name-renderer/habit-name-renderer"
import {renderHandler, onCellHandler} from "../components/habits-table/cell-renderer/habit-cell-renderer"

export const getHabitColumns = (mode, viewPeriod, selectedDate, nameColWidth, amendHabit, editHabitCase) => {
  const cellsAmount = getPeriodModeCellsAmount(mode, viewPeriod, selectedDate.clone());
  let columns = [nameColumn(amendHabit, nameColWidth)];

  for (let i=0; i<cellsAmount; i++) {
    columns.push(modeColumn(i, cellsAmount, mode, viewPeriod, selectedDate, editHabitCase))
  }

  return columns
};

const nameColumn = (amendHabit, nameColWidth) => ({
  title: '',
  dataIndex: 'name',
  key: 'name',
  width: nameColWidth,
  render: HabitNameRenderer(amendHabit),
  className: 'habit-name-col'
});

const modeColumn = (index, cellsAmount, mode, viewPeriod, selectedDate, editHabit) => {
  switch (mode) {
    case GRID_MODE.WEEK: return weekColumn(index, cellsAmount, viewPeriod, selectedDate, editHabit);
    case GRID_MODE.MONTH: return monthColumn(index, cellsAmount, viewPeriod, selectedDate, editHabit);
    default: return dayColumn(index, cellsAmount, viewPeriod, selectedDate, editHabit);
  }
};

const getPeriodModeCellsAmount = (mode, viewPeriod, selectedDate) => {
  if (viewPeriod === GRID_VIEW_PERIOD.WEEK) return 7;

  if (viewPeriod === GRID_VIEW_PERIOD.MONTH) {
    if (mode === GRID_MODE.DAY) {
      return selectedDate.endOf('month').format('DD')
    } else if (mode === GRID_MODE.WEEK) {
      const firstWeekNumber = selectedDate.startOf('month').week();
      const lastWeekNumber = selectedDate.endOf('month').week();
      return lastWeekNumber - firstWeekNumber + 1
    }
  }

  if (viewPeriod === GRID_VIEW_PERIOD.YEAR) {
    if (mode === GRID_MODE.WEEK) {
      const firstWeekNumber = selectedDate.startOf('year').week();
      let lastWeekNumber = selectedDate.endOf('year').week();

      if (lastWeekNumber === 1) {
        lastWeekNumber = selectedDate.endOf('year').subtract(6, 'days').week()
      }

      return lastWeekNumber - firstWeekNumber
    } else if (mode === GRID_MODE.MONTH) {
      return 12
    }
  }

  return 0
};

export const dayColumn = (day, daysToRender, period, selectedDate, editHabit) => {
  const {title, isCurrent, isWeekend} = getColumnDetails(GRID_MODE.DAY, period, day, selectedDate);

  const render = renderHandler(GRID_MODE.DAY, period, day, daysToRender, selectedDate, editHabit);
  const onCell = onCellHandler(GRID_MODE.DAY, period, day, daysToRender, selectedDate);
  const className = isCurrent ? 'current' : (isWeekend ? 'weekend' : '');

  return {
    title,
    dataIndex: `day${day}`,
    render,
    onCell,
    className
  }
};

export const weekColumn = (week, weeksToRender, period, selectedDate, editHabit) => {
  const {title, isCurrent} = getColumnDetails(GRID_MODE.WEEK, period, week, selectedDate);
  const render = renderHandler(GRID_MODE.WEEK, period, week, weeksToRender, selectedDate, editHabit);
  const onCell = onCellHandler(GRID_MODE.WEEK, period, week, weeksToRender, selectedDate);
  const className = isCurrent ? 'current' : '';

  return {
    title,
    dataIndex: `week${week}`,
    render,
    onCell,
    className
  }
};

export const monthColumn = (month, monthToRender, period, selectedDate, editHabit) => {
  const {title, isCurrent} =  getColumnDetails(GRID_MODE.MONTH, period, month, selectedDate);
  const className = isCurrent ? 'current' : '';

  return {
    title,
    dataIndex: `month${month}`,
    render: renderHandler(GRID_MODE.MONTH, period, month, monthToRender, selectedDate, editHabit),
    onCell: onCellHandler(GRID_MODE.MONTH, period, month, monthToRender, selectedDate),
    className
  }
};

export const getColumnDetails = (mode, period, index=0, selectedDate) => {
  const date = selectedDate.clone();
  const now = moment();
  const sameYear = date.year() === now.year();

  let title = '';
  let isCurrent = false;
  let isWeekend = false;

  switch(mode) {
    case GRID_MODE.DAY:
      if (period === GRID_VIEW_PERIOD.WEEK) {
        const dayDate = date.startOf('week').add(index, 'days');
        const day = dayDate.day();
        isCurrent = sameYear && dayDate.dayOfYear() === now.dayOfYear();
        isWeekend = day === 0 || day === 6;
        title = dayDate.format('ddd (DD.MM)')
      } else {
        const dayDate = date.startOf('month').add(index, 'days');
        const day = dayDate.day();
        isCurrent = sameYear && dayDate.dayOfYear() === now.dayOfYear();
        isWeekend = day === 0 || day === 6;
        title = (index + 1) + ''
      }
      break;

    case GRID_MODE.WEEK:
      const weekIndex = now.week();

      if (period === GRID_VIEW_PERIOD.MONTH) {
        const firstWeekIndex = date.startOf('month').week();
        const start = date.week(firstWeekIndex + index).startOf('week').format('DD.MM');
        const end = date.week(firstWeekIndex + index).endOf('week').format('DD.MM');

        isCurrent = sameYear && weekIndex === (firstWeekIndex + index);
        title =  `${start} - ${end}`
      } else {
        isCurrent = sameYear && weekIndex === index;
        title =  (index + 1) + ''
      }
      break;

    case GRID_MODE.MONTH:
      isCurrent = sameYear && index === now.month();
      title = date.month(index).format('MMM');
      break;

    default: return ''
  }

  return {title, isCurrent, isWeekend}
};

export const columnWidthReducer = (width, column) => column.width ? (width + column.width) : width;

export const habitParamValueReducer = habit => (habitParamValues, habitParam) => {
  const prop = habitParam.property;
  const curValues = habit.habitParamValues;

  return (curValues && curValues[prop] ? {...habitParamValues, [prop]: curValues[prop]} : habitParamValues)
};

export const getHabitDetails = (habitParams, habitParamValues) => {
  if (!habitParams || !habitParams.length) return null;

  const paramRow = ({label, property}) => <div key={property}><b className='label'>{label}: </b> <span className='value'>{habitParamValues && habitParamValues[property] ? habitParamValues[property] : ''}</span></div>

  return <div>
    {habitParams.map(paramRow)}
  </div>
};

export const getHabitDate = (mode, period, relativeIndex, selectedDate) => {
  return selectedDate.clone()
    .startOf(period)
    .add(+relativeIndex, MOMENT_MODE_ADD_PROPERTIES[mode])
};

export const getHabitIndex = (mode, period, relativeIndex, selectedDate) => {
  const ourDate = getHabitDate(mode, period, relativeIndex, selectedDate)
  return ourDate[MOMENT_MODE_INDEX_METHODS[mode]]()
};

export const getHabitYear = (mode, period, relativeIndex, selectedDate) => {
  const ourDate = getHabitDate(mode, period, relativeIndex, selectedDate)
  return ourDate.year()
};

export const isHabitChecked = (record, year, index) => {
  return property(`habit.${record.period}.${year}.${index}.done`)(record)
};

export const getHabitParamValues = (record, year, index) => {
  return property(`habit.${record.period}.${year}.${index}.params`)(record)
};

export const getPeriodOptions = (text) => {
  return [
    { label: text('CONSTANTS.HABIT_PERIODS.' + DATE_OPTIONS.DAY),   value: DATE_OPTIONS.DAY },
    { label: text('CONSTANTS.HABIT_PERIODS.' + DATE_OPTIONS.WEEK),  value: DATE_OPTIONS.WEEK },
    { label: text('CONSTANTS.HABIT_PERIODS.' + DATE_OPTIONS.MONTH), value: DATE_OPTIONS.MONTH },
    { label: text('CONSTANTS.HABIT_PERIODS.' + DATE_OPTIONS.YEAR),  value: DATE_OPTIONS.YEAR },
  ]
};

export const modeScaleSort = (a, b) => (MODE_SCALES[a] > MODE_SCALES[b] ? 1 : -1);
