import {NOTE_ACTION} from '../types'

export const noteAdd = note => ({
  type: NOTE_ACTION.ADD_NOTE,
  note
})

export const noteDelete = id => ({
  type: NOTE_ACTION.DELETE_NOTE,
  id
})

export const noteUpdate = note => ({
  type: NOTE_ACTION.UPDATE_NOTE,
  note
})

export const noteSelect = id => ({
  type: NOTE_ACTION.SELECT_NOTE,
  id
})