import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {mapDispatchActionToProps} from "../../../../../common/utils";
import './SelectionEditPanel.css';
import {Button} from "antd";
import {
  DownCircleOutlined,
  UpCircleOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import PropertyButton from "./components/PropertyButton";
import PropertyValueButton from "./components/PropertyValueButton";
import {ItemSubTypes, ItemTypes, SELECTION_EDIT_PANEL_STATE} from "./common/constants";
import Text from "../../../../../components/i18n/text";
import ItemEdit from "../item-properties/components/ItemEdit";
import {goalsByIdSelector, itemsWithChildrenSelector} from "../../../../../common/selectors/goals-selectors";
import moment from "moment";
import {itemsBulkUpdate} from "../../../../../common/actions/item-actions";
import {DATE_OPTIONS, IMPORTANCE, URGENCY} from "../../../../../common/constants";
import {deleteSelected} from "../../../../../common/utils/item-utils";

const SelectionEditPanel = (props) => {
  const {selectedItemsIds = [], stopEditing, goalsMap, dispatchAction, itemsWithChildren} = props;
  const items = selectedItemsIds.map(id => goalsMap[id]).filter(item => !!item);

  const [panelState, updatePanelState] = useState(SELECTION_EDIT_PANEL_STATE.SHORT);
  const [editingProperty, changeEditingProperty] = useState(null);

  useEffect(() => {
    if (items.length === 0) {
      updatePanelState(SELECTION_EDIT_PANEL_STATE.SHORT);
    }
  }, [items]);

  if (items.length === 0) return null;

  const editingClass = selectedItemsIds.length > 0 ? 'editing' : '';
  const extendedClass = !!editingProperty ? `extended ${editingProperty}` : '';
  const className = `selection-edit-panel ${panelState} ${editingClass} ${extendedClass}`;

  const propsChange = (key, value) => {
    setTimeout(() => {
      const itemsUpdate = items.map(({id}) => ({id, [key]: value}));
      dispatchAction(itemsBulkUpdate(itemsUpdate))
    }, 0);
  };

  const changePropertyValue = (property, value) => {
    switch (property) {
      case ItemTypes.DATE: {
        let newDate = null;

        switch (value) {
          case ItemSubTypes[ItemTypes.DATE].TODAY: {newDate = moment(); break;}
          case ItemSubTypes[ItemTypes.DATE].TOMORROW: {newDate = moment().add(1, 'days'); break;}
          case ItemSubTypes[ItemTypes.DATE].INAWEEK: {newDate = moment().add(7, 'days'); break;}
        }

        if (newDate) {
          propsChange("dateFrom", newDate.valueOf());
        }
        break;
      }
      case ItemTypes.URGENCY: {
        propsChange("urgency", URGENCY[value].value);
        break;
      }
      case ItemTypes.IMPORTANCE: {
        propsChange("importance", IMPORTANCE[value].value);
        break;
      }
      case ItemTypes.PERIOD: {
        let newPeriod = null;
        if (value !== ItemSubTypes[ItemTypes.PERIOD].NONE) {
          newPeriod = DATE_OPTIONS[value];
        }
        propsChange("period", newPeriod);
        break;
      }
    }

    changeEditingProperty(null);
  };

  const editProperty = (property) => {
    changeEditingProperty(editingProperty === property ? null : property);
  };

  const showMoreEditFields = () => {
    changeEditingProperty(null);
    updatePanelState(SELECTION_EDIT_PANEL_STATE.FULL);
  };
  const showLessEditFields = () => {
    changeEditingProperty(null);
    updatePanelState(SELECTION_EDIT_PANEL_STATE.SHORT);
  };
  const deleteItem = () => deleteSelected({selectedItemsIds, dispatchAction, itemsWithChildren});

  let itemEditProps = {
    selectedItemsIds,
    extendedFieldsOnly: true
  };


  return <div className={className}>
    <div className="drag-to-close">
      <div className="inner"/>
    </div>
    <div className="more-edit-fields">
      <ItemEdit {...itemEditProps}/>
      <div className="delete-container">
        <Button icon={<DeleteOutlined />} onClick={deleteItem}><Text path="DELETE.delete"/></Button>
      </div>
    </div>
    <div className="property-edit-buttons">
      <div className="values-panel">
        <span className="info">
          <Text path={`PAGES.ACTIONS.MOBILE_EDIT.${editingProperty}.DROP_LABEL`}/>
        </span>
        {getPropertyValues(editingProperty, "", changePropertyValue)}
      </div>
      <div className="action-buttons">
        {Object.values(ItemTypes).map(property => <PropertyButton
          key={`prop-btn-${property}`}
          property={property}
          editProperty={editProperty}
          currentValueLabel={""}
        />)}

        <div className="property-button more" onClick={showMoreEditFields}>
          <Button shape="circle" icon={<UpCircleOutlined />}/>
          <span className="label"><Text path="PAGES.ACTIONS.MOBILE_EDIT.MORE.LABEL"/></span>
        </div>

        <div className="property-button less" onClick={showLessEditFields}>
          <Button shape="circle" icon={<DownCircleOutlined />}/>
          <span className="label"><Text path="PAGES.ACTIONS.MOBILE_EDIT.LESS.LABEL"/></span>
        </div>
      </div>
    </div>
  </div>
};

const getPropertyValues = (property, currentValue, changePropertyValue) => {
  if (!property) return null;

  const values = Object.values(ItemSubTypes[property]);

  return <div className="action-buttons">
    {values.map(val => <PropertyValueButton
      key={`prop-val-btn-${property}-${val}`}
      property={property}
      value={val}
      changePropertyValue={changePropertyValue}
      currentValue={currentValue}
    />)}
  </div>
};

const mapStateToProps = (state) => ({
  itemsWithChildren: itemsWithChildrenSelector(state),
  goalsMap: goalsByIdSelector(state)
});

export default connect(mapStateToProps, mapDispatchActionToProps)(SelectionEditPanel)
