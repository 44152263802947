import React, {useState} from 'react'
import {DATE_FORMAT, SIZE} from "../../constants/index";
import { DatePicker, Button } from 'antd'
import moment from "moment/moment";
import { ClearOutlined } from '@ant-design/icons'
import './date-picker.css'

const MyDatePicker = (props) => {
  const {value, onChange, size=SIZE.DEFAULT, className, placeholder, placement} = props;
  const [open, openChange] = useState(false);

  const extraFooter = () => {
    const today = moment()
    const tomorrow = moment().add(1, 'days')
    const inaweek = moment().add(7, 'days')

    const selectDate = date => () => {
      onChange(date)
      openChange(false)
    };

    return <div>
      <Button className='day-clear-btn' shape="circle" icon={<ClearOutlined />} onClick={selectDate(null)}/>
      <div className='day-select'>
        <span className='day-select-option' onClick={selectDate(today)}>Today</span>
        <span className='day-select-option' onClick={selectDate(tomorrow)}>Tomorrow</span>
        <span className='day-select-option' onClick={selectDate(inaweek)}>In a week</span>
      </div>
    </div>
  };

  return <DatePicker format={DATE_FORMAT}
                     size={size}
                     value={value}
                     onChange={onChange}
                     className={className}
                     placeholder={placeholder}
                     placement={placement}

                     showToday={false}
                     renderExtraFooter={extraFooter}
                     open={open}
                     onOpenChange={openChange} />
};

export default MyDatePicker
