import React from 'react'
import {connect} from 'react-redux'
import { Tooltip } from 'antd';
import { SelectOutlined, ReloadOutlined, CheckCircleOutlined } from '@ant-design/icons'
import './done-name-renderer.css'
import {getItemPath} from "../../../../../../common/selectors/selector-utils";
import {goalsSelector} from "../../../../../../common/selectors/goals-selectors";
import moment from 'moment'
import { Link } from 'react-router-dom'
import {ITEM_TYPE} from "../../../../../../common/constants";
import {textMethod} from "../../../../../../common/utils";

export default () => (text, record) => {
  const params = {record};
  return <DoneNameComponent {...params}/>
}

class DoneNameRenderer extends React.Component {
  render() {
    const {record, items:{byId}, text} = this.props;

    const done = record.filtered;
    const className = 'clrfix done-item-name' + (done ? ' done' : '');

    const timeTitle = moment(record.doneDate).format('DD.MM');
    const nameTitle = done ? record.name : getItemPath(byId, record.id).map(i => i.name).join(' > ');
    const habitTitle = text('CONSTANTS.HABIT');

    const detailsURL = `/user/goals#${record.id}`;

    const linkToDetails = record.type === ITEM_TYPE.ACTION
      ? <Link className='float-right' to={detailsURL}><SelectOutlined /></Link>
      : <Tooltip title={habitTitle}><span className='float-right'><ReloadOutlined /></span></Tooltip>;

    return <span className={className}>
      {(done && <Tooltip title={timeTitle} placement="topLeft">
        <CheckCircleOutlined className='done-icon' />
      </Tooltip>) || null}
      <Tooltip title={nameTitle} placement="topLeft">
        <span className='long-name'>{record.name}</span>
      </Tooltip>
      {linkToDetails}
    </span>
  }
}

const mapStateToProps = (state, ownProps) => ({
  items: goalsSelector(state, ownProps),
  text: textMethod(state)
});

const DoneNameComponent = connect(mapStateToProps)(DoneNameRenderer);
