import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updatePassword,
  sendPasswordResetEmail,
  deleteUser,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider
} from "firebase/auth";

const googleAuthProvider = new GoogleAuthProvider();
const fbAuthProvider = new FacebookAuthProvider();

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
  createUserWithEmailAndPassword(getAuth(), email, password);

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
  signInWithEmailAndPassword(getAuth(), email, password);

export const doSignInWithGoogleAccount = () =>
  signInWithPopup(getAuth(), googleAuthProvider);

export const doSignInWithFacebookAccount = () =>
  signInWithPopup(getAuth(), fbAuthProvider);

// Sign out
export const doSignOut = () =>
  getAuth().signOut();

// Password Reset
export const doPasswordReset = (email, settings) =>
  sendPasswordResetEmail(getAuth(), email, settings);

// Password Change
export const doPasswordUpdate = (password) =>
  updatePassword(getAuth().currentUser, password);

// User Delete
export const doUserDelete = () =>
  deleteUser(getAuth().currentUser);
