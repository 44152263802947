import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd';
import LoginForm from './forms/login-form'
import RegisterForm from './forms/register-form'
import ResetPasswordForm from './forms/reset-password-form'
import "./AuthModal.css";
import {textMethod} from "../../common/utils";
import {connect} from "react-redux";

const AUTH_MODE = {
  LOGIN: 'login',
  REGISTER: 'register',
  RESET_PASS: 'resetpass',
};

const AuthModal = (props) => {
  const {visible, text} = props;
  const [mode, changeMode] = useState(AUTH_MODE.LOGIN);

  const switchToRegister = () => {changeMode(AUTH_MODE.REGISTER)};
  const switchToLogin = () => {changeMode(AUTH_MODE.LOGIN)};
  const switchToPassReset = () => {changeMode(AUTH_MODE.RESET_PASS)};

  const closeModal = (loginSuccessful = false) => {
    changeMode(AUTH_MODE.LOGIN);
    props.closeModal(loginSuccessful)
  };

  let form;
  let title;

  switch(mode) {
    case AUTH_MODE.LOGIN:
      form = <LoginForm register={switchToRegister} resetPass={switchToPassReset} cancel={closeModal}/>;
      title = text('AUTH.LOGIN.TITLE');
      break;
    case AUTH_MODE.REGISTER:
      form = <RegisterForm login={switchToLogin} cancel={closeModal}/>;
      title = text('AUTH.SIGN_UP.TITLE');
      break;
    case AUTH_MODE.RESET_PASS:
      form = <ResetPasswordForm login={switchToLogin} cancel={closeModal}/>;
      title = text('AUTH.PASS_RESET.TITLE');
      break;
    default: {}
  }

  return <Modal
    title={title}
    visible={visible}
    onCancel={closeModal}
    footer={null}
  >
  {form}
  </Modal>
};

AuthModal.propTypes = {
  visible: PropTypes.bool,
  closeModal: PropTypes.func
};

const mapStateToProps = (state) => ({
  text: textMethod(state)
});

export default connect(mapStateToProps)(AuthModal);
