import React, {useEffect, useState} from "react";
import {withAuthorization} from "../../../common/utils/auth/with-authorization";
import PageTemplate from "../page-template/PageTemplate";
import {DATE_OPTIONS, VIEW_TYPE} from "../../../common/constants";
import {connect} from "react-redux";
import {mapDispatchActionToProps, textMethod} from "../../../common/utils";
import "./ResultsPage.css"
import {List, Radio} from "antd";
import {
  FlagOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import NewItem from "../../../common/components/new-item/new-item";
import ResultsListFilter from "./components/results-list-filter/ResultsListFilter";
import itemRenderer from "./components/results-list-item/resultListItemRenterer";
import {resultsListSelector} from "../../../common/selectors/results-selectors";
import ResultsFilterHeader from "./components/results-filter-header/ResultsFilterHeader";
import moment from "moment";
import {getPeriodIndex, getPeriodMoment} from "../../../common/utils/date-utils";
import ResultsNote from "./components/results-note/ResultsNote";
import PeriodItems from "./components/period-items/period-items";

const FOCUS_AREA = Object.freeze({
  SUMMARY_LIST: "summary-list",
  DONE_ITEMS_HIERARCHY: "done-items-hierarchy",
});

const FOCUS_SUB_AREA = Object.freeze({
  SUMMARY: "summary",
  DONE_ITEMS: "done-items",
  BOTH: "both",
});

const ResultsPage = (props) => {
  const {resultsList, text, dispatchAction} = props;

  const [focusArea, changeFocusArea] = useState(FOCUS_AREA.SUMMARY_LIST);
  const [focusSubArea, changeFocusSubArea] = useState(FOCUS_SUB_AREA.DONE_ITEMS);

  const changeSubAreaVisibility = (moreAchievements) => () => {
    if (moreAchievements) {
      if (focusSubArea === FOCUS_SUB_AREA.SUMMARY) changeFocusSubArea(FOCUS_SUB_AREA.BOTH);
      if (focusSubArea === FOCUS_SUB_AREA.BOTH) changeFocusSubArea(FOCUS_SUB_AREA.DONE_ITEMS);
    } else {
      if (focusSubArea === FOCUS_SUB_AREA.DONE_ITEMS) changeFocusSubArea(FOCUS_SUB_AREA.BOTH);
      if (focusSubArea === FOCUS_SUB_AREA.BOTH) changeFocusSubArea(FOCUS_SUB_AREA.SUMMARY);
    }
  };

  const createNewResultsNote = () => {
    selectedDateChange(moment());
    periodChange(DATE_OPTIONS.DAY);
    changeFocusSubArea(FOCUS_SUB_AREA.BOTH);
    changeFocusArea(FOCUS_AREA.DONE_ITEMS_HIERARCHY);
  };

  const pageClasses = `results page ${focusArea}`;
  const contentClasses = `search-results ${focusSubArea}`;

  const selectResultsNote = (resultNoteId) => {
    const resultsNote = resultsList.filter(({id}) => resultNoteId === id)[0];

    if (resultsNote) {
      periodChange(resultsNote.period);
      selectedDateChange(getPeriodMoment(resultsNote.year, resultsNote.period, resultsNote.index));

      changeFocusSubArea(FOCUS_SUB_AREA.BOTH);
      changeFocusArea(FOCUS_AREA.DONE_ITEMS_HIERARCHY);
    }
  };

  const [searchText, changeSearchText] = useState("");
  const [searchPeriod, changeSearchPeriod] = useState(null);

  const [selectedDate, selectedDateChange] = useState(moment());
  const [period, periodChange] = useState(DATE_OPTIONS.DAY);

  const [selectedNote, updateSelectedNote] = useState(getResultsNoteByPeriodAndDate(resultsList, period, selectedDate));
  useEffect(() => {
    updateSelectedNote(getResultsNoteByPeriodAndDate(resultsList, period, selectedDate));
  }, [resultsList, period, selectedDate]);

  const moreAchievementsBtnLabel = focusSubArea === FOCUS_SUB_AREA.SUMMARY
    ? text("PAGES.RESULTS.SHOW_ACHIEVEMENTS")
    : "⌃";
  const moreSummaryBtnLabel = focusSubArea === FOCUS_SUB_AREA.DONE_ITEMS
    ? (selectedNote ? text("PAGES.RESULTS.SHOW_SUMMARY") : text("PAGES.RESULTS.ADD_SUMMARY"))
    : "⌃";

  const resultsListFilterProps = {
    searchText, changeSearchText,
    searchPeriod, changeSearchPeriod,
    createNewResultsNote
  };

  const resultsFilterHeaderProps = {
    period, periodChange,
    selectedDate, selectedDateChange,
    showResultsNotesList: () => {changeFocusArea(FOCUS_AREA.SUMMARY_LIST)},
  };

  const resultsNoteProps = {
    note: selectedNote,
    period,
    selectedDate
  };

  const periodItemsProps = {
    selectedPeriod: {
      period,
      year: selectedDate.year(),
      index: getPeriodIndex(selectedDate, period)
    }
  };

  const filteredResults = resultsList.filter(result =>
    (!searchText || (result.name && result.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0))
    && (!searchPeriod || result.period === searchPeriod)
  );

  return <PageTemplate
    selectedView={VIEW_TYPE.RESULTS}
    pageTitle={text("VIEWS.results")}
    headerSelect={resultsViewSwitch(focusArea, changeFocusArea, changeFocusSubArea)}
  >
    <div className={pageClasses}>
      <div className="page-list">
        <div className="header">
          <ResultsListFilter {...resultsListFilterProps}/>
        </div>
        <div className="content">
          <List className='results-notes-list' itemLayout="horizontal"
                dataSource={filteredResults}
                renderItem={itemRenderer(dispatchAction, selectedNote, selectResultsNote, text)}
                locale={{emptyText: 'Start typing and the note will appear here'}}
          />
        </div>
        <div className="footer">
          <NewItem path="PAGES.RESULTS.ADD_NEW" showNewItemModal={createNewResultsNote}/>
        </div>
      </div>
      <div className="page-content">
        <div className="results-container">
          <div className="search-bar">
            <ResultsFilterHeader {...resultsFilterHeaderProps}/>
          </div>
          <div className={contentClasses}>
            <div className="summary-details">
              <ResultsNote {...resultsNoteProps}/>
            </div>
            <div className="blocks-delimiter">
              <button onClick={changeSubAreaVisibility(true)} className="more-achievements">
                {moreAchievementsBtnLabel}
              </button>
              <button onClick={changeSubAreaVisibility(false)} className="more-results">
                {moreSummaryBtnLabel}
              </button>
            </div>
            <div className="period-achievements">
              <PeriodItems {...periodItemsProps}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageTemplate>
};

const getResultsNoteByPeriodAndDate = (resultsList=[], selectedPeriod, selectedDate) => {
  const selectedYear = selectedDate.year();
  const selectedIndex = getPeriodIndex(selectedDate, selectedPeriod);

  const resultsByDate = resultsList
    .filter(({year, period, index}) => period === selectedPeriod && year === selectedYear && index === selectedIndex);

  return resultsByDate.length > 0 ? resultsByDate[0] : null;
};

const resultsViewSwitch = (focusArea, changeFocusArea, changeFocusSubArea) => {
  const valueChange = ({target:{value}}) => {
    changeFocusArea(value);
  };

  return <Radio.Group
    value={focusArea}
    onChange={valueChange}
    buttonStyle="solid"
    className="header-switch"
  >
    <Radio.Button value={FOCUS_AREA.SUMMARY_LIST}><UnorderedListOutlined /></Radio.Button>
    <Radio.Button value={FOCUS_AREA.DONE_ITEMS_HIERARCHY}><FlagOutlined /></Radio.Button>
  </Radio.Group>
};

const mapStateToProps = (state, ownProps) => ({
  resultsList: resultsListSelector(state, ownProps),
  text: textMethod(state)
});

const ResultsPageController = connect(mapStateToProps, mapDispatchActionToProps)(ResultsPage)
export default withAuthorization(ResultsPageController)
