import React from "react"
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import {itemsBulkUpdate} from "../../../../../../common/actions/item-actions";
import { Popover, Checkbox, Tag } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import {clickStop, mapDispatchActionToProps, minifyString, textMethod} from "../../../../../../common/utils";
import PeriodSelectPopover from '../../../../../../common/components/list-item-popover/period-select-popover'
import ImportanceSelectPopover from '../../../../../../common/components/list-item-popover/importance-select-popover'
import UrgencySelectPopover from '../../../../../../common/components/list-item-popover/urgency-select-popover'
import {getDateString, isToday} from "../../../../../../common/utils/date-utils";
import {itemsWithChildrenSelector, selectedGoalsIdsSelector} from "../../../../../../common/selectors/goals-selectors";
import {deleteSelected, getItemDateIcon} from "../../../../../../common/utils/item-utils";
import "./GoalsPriorityListItem.css";

class GoalsPriorityListItem extends React.Component {
  render() {
    const {isDragging, item, onSelect, selected, text} = this.props;

    const {name, parentPath=[], period, dateFrom} = item;

    const titlePaths = parentPath.length < 4 ? parentPath : [parentPath[0], {name: '...'}, parentPath[parentPath.length-1]];
    const popoverTitle = titlePaths.map(i => minifyString(i.name, 20)).join(' > ');
    const popoverContent = <div className='name-wrapper'>{name}</div>;

    const mobileBreadCrumps = popoverTitle ? <div className="item-path mobile-only">
      <Tag>{popoverTitle}</Tag>
    </div> : null;

    const className = `ant-list-item ${selected ? 'selected' : ''}`;

    const doneCheckbox = this.getDoneCheckbox();
    const dateIcon = getItemDateIcon(item, this.updateItem);

    const dateString = getDateString(dateFrom, text("CONSTANTS.TODAY"));
    const periodWithUpdate = <div {...clickStop}><PeriodSelectPopover item={item} updateItem={this.updateItem}/></div>
    const dateOrPeriod = dateFrom ? <div> {dateString} </div> : (period ? periodWithUpdate : null);

    return <div className={className} style={{opacity: isDragging ? 0.5 : 1}} onClick={() => onSelect()}>
      <div className="ant-list-item-meta">
        <div className="ant-list-item-meta-avatar"/>
        <div className="ant-list-item-meta-content">
          <h4 className="ant-list-item-meta-title">
            <div>
              {doneCheckbox}

              <Popover title={popoverTitle} content={popoverContent} trigger="hover">
                {name}
              </Popover>
            </div>
          </h4>
          <div className="ant-list-item-meta-description">
            <div>
              {dateOrPeriod}
            </div>
            {mobileBreadCrumps}
            <div {...clickStop} className='actions'>

              <div className="desktop-only">
                <div> {this.getEditIcon()} </div>
              </div>

              <div>
                <div><ImportanceSelectPopover item={item} updateItem={this.updateItem}/></div>
                <div><UrgencySelectPopover item={item} updateItem={this.updateItem}/></div>
              </div>

              <div className="desktop-only">
                <div>{this.getDeleteItemIcon()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  getDoneCheckbox = () => {
    const {item:{id}} = this.props;

    const doneItem = () => {this.updateItem({id, done: true, doneDate: moment().valueOf()})}
    return <Checkbox checked={false} onChange={doneItem}/>
  };

  getDeleteItemIcon = () => {
    const {dispatchAction, selectedItemsIds, item, itemsWithChildren} = this.props;

    const ids = selectedItemsIds.indexOf(item.id) >= 0 ? selectedItemsIds : [item.id];

    const deleteItem = () => {
      deleteSelected({selectedItemsIds: ids, dispatchAction, itemsWithChildren})
    };

    return <DeleteOutlined onClick={deleteItem}/>
  };

  getEditIcon = () => {
    const {onEdit} = this.props;
    return <EditOutlined onClick={onEdit}/>
  };

  updateItem = (update) => {
    const {selectedItemsIds, dispatchAction} = this.props;

    let itemsUpdate = [];
    if (selectedItemsIds.indexOf(update.id) >= 0) {
      itemsUpdate = selectedItemsIds.map(id => ({...update, id}))
    } else {
      itemsUpdate = [update]
    }

    dispatchAction(itemsBulkUpdate(itemsUpdate))
  }
}

const mapStateToProps = (state, ownProps) => ({
  text: textMethod(state),
  itemsWithChildren: itemsWithChildrenSelector(state),
  selectedItemsIds: selectedGoalsIdsSelector(state),
});

GoalsPriorityListItem.propTypes = {
  onSelect: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchActionToProps)(GoalsPriorityListItem);
