import {property} from 'lodash'
import {EMPTY_OBJECT, EMPTY_STRING} from "../constants";

export const ownPropsSelector = (state, ownProps) => ownProps || EMPTY_OBJECT
export const propertySelector = property => (state, ownProps) => (ownProps && ownProps[property]) || EMPTY_STRING
export const idSelector = propertySelector('id')
export const searchStringSelector = propertySelector('search')
export const periodSelector = propertySelector('period')
export const yearSelector = propertySelector('year')
export const indexSelector = propertySelector('index')
export const tagsSelector = propertySelector('tags')
export const locationHashSelector = (state, ownProps) => {
  const hash = property('location.hash')(ownProps)
  if (hash) {
    return hash.substr(1)
  }

  return EMPTY_STRING
}
