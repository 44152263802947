import React from 'react';
import PropTypes from "prop-types";
import {Button, DatePicker, Select} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {eventValue, selectOptionFilter, textMethod} from "../../../../../common/utils";
import {DATE_OPTIONS} from "../../../../../common/constants";
import {connect} from "react-redux";
import {GRID_VIEW_PERIOD, GRID_MODE} from "../../common/constants";
import T from "../../../../../components/i18n/text";
import moment from "moment";
import "./HabitsFilterHeader.css"
import PlusButton from "../../../../../common/components/plus-button/PlusButton";

const HabitsFilterHeader = (props) => {
  return <div className="habits-filter-header">
    <div className="habits-filter">
      {getDesktopModeFilter(props)}
      {getMobileModeFilter(props)}
      {getExactDateSelection(props)}
    </div>
    <PlusButton onClick={props.showNewItemModal}/>
  </div>
};

const getDesktopModeFilter = (props) => {
  const {existingModes, mode, modeChange, periodChange} = props;

  const modeChangeHandler = event => {
    const newMode = eventValue(event);

    modeChange(newMode);

    switch (newMode) {
      case GRID_MODE.DAY:
      case GRID_MODE.WEEK: periodChange(GRID_VIEW_PERIOD.MONTH); break;
      case GRID_MODE.MONTH: periodChange(GRID_VIEW_PERIOD.YEAR); break
    }
  };

  return <Select onChange={modeChangeHandler} value={mode} className='modes desktop'>
    {existingModes.map(period => <Select.Option key={period} value={period}><T path={`CONSTANTS.HABIT_PERIODS.${period}`}/></Select.Option>)}
  </Select>
};

const getMobileModeFilter = (props) => {
  const {existingModes, mode, modeChange, periodChange} = props;

  const modeChangeHandler = event => {
    const newMode = eventValue(event);

    modeChange(newMode);

    switch (newMode) {
      case GRID_MODE.DAY: periodChange(GRID_VIEW_PERIOD.WEEK); break;
      case GRID_MODE.WEEK: periodChange(GRID_VIEW_PERIOD.MONTH); break;
      case GRID_MODE.MONTH: periodChange(GRID_VIEW_PERIOD.YEAR); break
    }
  };

  return <Select onChange={modeChangeHandler} value={mode} className='modes mobile'>
    {existingModes.map(period => <Select.Option key={period} value={period}><T path={`CONSTANTS.HABIT_PERIODS.${period}`}/></Select.Option>)}
  </Select>
};

const getExactDateSelection = (props) => {
  const {existingModes, period, selectedDate, selectedDateChange} = props;

  if (existingModes.length === 0) return null;

  const now = moment();

  const handlePeriodChange = (period, selectedDate, selectedDateChange) => val => () => {
    if (val === 0) {
      selectedDateChange(moment())
    } else {
      selectedDateChange(selectedDate.clone().add(val, period))
    }
  };

  const dateChange = (newDate) => {
    selectedDateChange(newDate === null ? now : newDate);
  };

  return <Button.Group>
    <Button onClick={handlePeriodChange(period, selectedDate, dateChange)(-1)} icon={<LeftOutlined />}/>
    <div style={{width: 122, display: 'inline-block'}}>{datePicker(period, selectedDate, dateChange)}</div>
    <Button onClick={handlePeriodChange(period, selectedDate, dateChange)(1)} icon={<RightOutlined />}/>
  </Button.Group>
};

const datePicker = (period, selectedDate, selectedDateChange) => {
  switch (period) {
    case DATE_OPTIONS.DAY: {
      return <DatePicker value={selectedDate} onChange={selectedDateChange} placeholder="Select day" />
    }
    case DATE_OPTIONS.WEEK: {
      return <DatePicker.WeekPicker value={selectedDate} onChange={selectedDateChange} placeholder="Select week" />
    }
    case DATE_OPTIONS.MONTH: {
      return <DatePicker.MonthPicker value={selectedDate} onChange={selectedDateChange} placeholder="Select month" />
    }
    case DATE_OPTIONS.YEAR: {
      return yearSelect(selectedDate, selectedDateChange)
    }
    default: return
  }
};

const yearSelect = (selectedDate, selectedDateChange) => {
  const yearChange = year => selectedDateChange(moment().year(year))

  const currentYear = (new Date()).getFullYear()
  return <Select
    value={selectedDate.year()}
    showSearch
    style={{ width: 122 }}
    placeholder="Select an year"
    optionFilterProp="children"
    onChange={yearChange}
    filterOption={selectOptionFilter}
  >
    {[...Array(10).keys()].map(index => <Select.Option key={index} value={currentYear-index}>{currentYear-index}</Select.Option>)}
  </Select>
};

HabitsFilterHeader.propTypes = {
  existingPeriods: PropTypes.array,
  period: PropTypes.string,
  periodChange: PropTypes.func,
  selectedDate: PropTypes.object,
  selectedDateChange: PropTypes.func,
  text: PropTypes.func
};

const mapStateToProps = (state) => ({
  text: textMethod(state)
});

export default connect(mapStateToProps)(HabitsFilterHeader);
