import React from 'react'
import {connect} from 'react-redux'
import {URL} from "../../common/constants";
import { Menu } from 'antd'
import { RocketOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import AuthButton from './items/auth-button'
import AuthUserContext from '../../common/utils/auth/auth-user-context'
import LanguageChange from '../i18n/language-change/language-change'
import './top-menu.css'
import {textMethod} from "../../common/utils";



class TopMenu extends React.Component {
  render () {
    const {openLoginModal, text} = this.props;

    const authNavigationLinks = [
      {url: URL.GOALS, label: text('TOP_MENU.APPLICATION')}
    ];

    const authButton = <Menu.Item key='enter'>
      <AuthButton logIn={openLoginModal}/>
    </Menu.Item>;

    const authNavigation = authNavigationLinks.map(this.linkToMenuItem);

    return <div className='top-menu'>
      <div className='logo-container'>
        <Link className='home-link' to={URL.HOME}><RocketOutlined /></Link>
      </div>
      <div className='top-menu-container'>
        <AuthUserContext.Consumer>
          {user => user
            ? this.menu([...authNavigation, authButton])
            : this.menu([authButton])
          }
        </AuthUserContext.Consumer>
        <LanguageChange/>
      </div>
    </div>
  }

  menu = items => {
    return <Menu
      theme="dark"
      mode="horizontal"
      selectedKeys={[window.location.pathname]}
      style={{ lineHeight: '64px' }}
    >{items}</Menu>
  };

  linkToMenuItem = ({url, label}, index) => {
    return <Menu.Item key={url}>
      <Link to={url}>{label}</Link>
    </Menu.Item>
  }
}

const mapStateToProps = (state, ownProps) => ({
  text: textMethod(state)
});

export default connect(mapStateToProps)(TopMenu)
