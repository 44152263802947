import React, {useEffect, useState} from 'react'
import { Button, Progress, Popover, InputNumber, Checkbox, message } from 'antd'
import moment from 'moment'
import './note-timer.css'
import {eventValue, textMethod} from "../../../../../common/utils";
import useInterval from "../../../../../common/hooks/useInterval";
import {ClockCircleOutlined, PlayCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
import {connect} from "react-redux";
import {dispatchCustomEvent, useCustomEvent} from "../../../../../common/hooks/customEvents";
import {CUSTOM_EVENTS} from "../../../../../common/types";

export const TIMER_ACTION = Object.freeze({
  START: 'TIMER_START',
  STOP: 'TIMER_STOP',
  EXTERNAL_STOP: 'TIMER_EXTERNAL_STOP',
  TIMEOUT: 'TIMER_TIMEOUT',
});

const NoteTimer = (props) => {
  const {className, text} = props;

  const [seconds, changeSeconds] = useState('');
  const [nonStop, changeNonStop] = useState(false);
  const [secondsPassed, changeSecondsPassed] = useState(0);
  const [showConfig, changeShowConfig] = useState(false);
  const [running, changeRunning] = useState(false);

  const timerExternalEvent = useCustomEvent(CUSTOM_EVENTS.TIMER_EXTERNAL_EVENT);
  useEffect(() => {
    if (timerExternalEvent && timerExternalEvent.action === TIMER_ACTION.EXTERNAL_STOP) {
      stopTimer(false);
    }
  }, [timerExternalEvent]);

  useInterval(
    () => {
      // Your custom logic here
      changeSecondsPassed(secondsPassed + 1);
      if (secondsPassed >= seconds) {
        timeOut();
      }
    },
    // Delay in milliseconds or null to stop it
    running ? 1000 : null,
  );

  const startTimer = () => {
    changeShowConfig(false);
    changeRunning(true);
    dispatchCustomEvent(CUSTOM_EVENTS.TIMER_EVENT, {action: TIMER_ACTION.START, nonStop});
  };

  const stopTimer = (dispatchCustomStopEvent = true) => {
    changeRunning(false);
    changeSecondsPassed(0);
    if (dispatchCustomStopEvent) dispatchCustomEvent(CUSTOM_EVENTS.TIMER_EVENT, {action: TIMER_ACTION.STOP});
  };

  const timeOut = () => {
    dispatchCustomEvent(CUSTOM_EVENTS.TIMER_EVENT, {action: TIMER_ACTION.TIMEOUT});
    stopTimer(false);
    message.success(text('PAGES.NOTES.TIMER.TIME_OUT'));
  };

  const allProps = {
    seconds, changeSeconds,
    nonStop, changeNonStop,
    secondsPassed, changeSecondsPassed,
    showConfig, changeShowConfig,
    running, changeRunning,
    startTimer,
    stopTimer
  };

  const internalClass = 'note-timer' + (running ? ' running': '');
  const finalClassName = (className || '') + internalClass;

  return <div className={finalClassName}>
    {running ? getCountBlock(allProps) : getConfigPopover(allProps)}
  </div>
};

const getCountBlock = (allProps) => {
  const {seconds, secondsPassed, stopTimer} = allProps;
  const percent = 100 - secondsPassed / seconds * 100;
  return <div className='running-only count-block'>
    <Button className='stop-btn' shape="circle" icon={<CloseCircleOutlined/>} onClick={stopTimer} />
    <Progress width={30} type="circle" percent={percent} format={() => getTimeByPercent(seconds, secondsPassed)} />
  </div>
};

const getTimeByPercent = (seconds, secondsPassed) => {
  return moment().startOf('day').second(seconds - secondsPassed).format('m:ss')
};

const getConfigPopover = (allProps) => {
  const {showConfig, changeShowConfig} = allProps;

  const showConfigChangeHandler = event => {
    changeShowConfig(eventValue(event));
  };

  return <Popover
    content={getTimerConfig(allProps)}
    trigger="click"
    visible={showConfig}
    onVisibleChange={showConfigChangeHandler}
    placement="left"
  >
    <Button shape="circle" icon={<ClockCircleOutlined />} onClick={()=>{changeShowConfig(true)}} />
  </Popover>
};

const getTimerConfig = (allProps) => {
  const {seconds, changeSeconds, changeNonStop, nonStop, startTimer} = allProps;
  const min = (seconds && Math.round(seconds / 60)) || '';

  const minChange = event => {
    changeSeconds(eventValue(event) * 60);
  };

  const nonStopChange = event => {
    changeNonStop(eventValue(event));
  };

  return <div className='note-timer-config'>
    <InputNumber min={1} max={60} placeholder='min' value={min} onChange={minChange}/>
    <Checkbox checked={nonStop} onChange={nonStopChange}>Non stop</Checkbox>
    <Button shape="circle" disabled={!seconds} icon={<PlayCircleOutlined />} onClick={startTimer} />
  </div>
};

const mapStateToProps = (state) => ({
  text: textMethod(state)
});

export default connect(mapStateToProps)(NoteTimer);
