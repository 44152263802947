import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import T from '../../../components/i18n/text';
import './new-item.css'

export default({showNewItemModal, path, className = ''}) => {
  return <div className={`add-item ${className}`}>
    <div onClick={showNewItemModal} className='invite-mode'>
      <PlusOutlined /><T path={path}/>
    </div>
  </div>
}
