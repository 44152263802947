import React from 'react'
import {eventValue, labelByValue} from "../../utils/index";
import { Popover, Radio } from 'antd';
import { DashboardOutlined } from '@ant-design/icons'
import {URGENCY} from "../../constants/index";

const RadioGroup = Radio.Group

class UrgencySelectPopover extends React.Component {
  state = {
    visible: false
  }

  render() {
    const {item, updateItem} = this.props

    const changeUrgency = (e) => {
      const urgency = eventValue(e)
      this.handleVisibleChange(false)
      updateItem({id: item.id, urgency})
    }

    const importanceSelect = <RadioGroup onChange={changeUrgency} value={item.urgency}>
      <Radio value={URGENCY.MINOR.value}>{URGENCY.MINOR.label}</Radio>
      <Radio value={URGENCY.NORMAL.value}>{URGENCY.NORMAL.label}</Radio>
      <Radio value={URGENCY.HIGH.value}>{URGENCY.HIGH.label}</Radio>
    </RadioGroup>

    return <Popover
      content={importanceSelect}
      title="Urgency"
      trigger="click"
      visible={this.state.visible}
      onVisibleChange={this.handleVisibleChange}
    >
      <DashboardOutlined title={labelByValue(item.urgency, URGENCY)} className={`colorful level${item.urgency || ''}`} />
    </Popover>
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible })
  }
}

export default UrgencySelectPopover
