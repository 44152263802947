import React from 'react'
import {connect} from 'react-redux'
import { UpOutlined, EditOutlined, DownOutlined, DeleteOutlined } from '@ant-design/icons'
import {mapDispatchActionToProps} from "../../../../../../common/utils";
import './habit-name-renderer.css'
import {habitsArraySelector} from "../../../../../../common/selectors/habits-selectors";
import {deleteHabit} from "../../../../../../common/utils/habit-utils";
import {findIndex} from "lodash";
import {habitsBulkUpdate} from "../../../../../../common/actions/habit-actions";

const HabitNameRenderer = (props) => {
  const {habit, amendHabit, dispatchAction} = props;

  const editHabit = () => {
    amendHabit(habit)
  };

  const deleteHabitHandler = () => {
    deleteHabit(habit, dispatchAction)
  };

  return <div className='habit-name-renderer clrfix'>
    <div className='name-container'>
      <div>{habit.name}</div>
    </div>
    <div className='actions-container'>
      <div className='action' onClick={moveHabit(props, 'up')}><UpOutlined /></div>
      <div className='action' onClick={editHabit}><EditOutlined /></div>
      <div className='action' onClick={moveHabit(props,'down')}><DownOutlined /></div>
      <div className='action' onClick={deleteHabitHandler}><DeleteOutlined /></div>
    </div>
  </div>
};

const moveHabit = (props, direction) => () => {
  const {habit, habits, dispatchAction} = props;

  const selectedIndex = findIndex(habits, { 'id': habit.id });
  const otherIndex = direction === 'up' ? (selectedIndex-1) : (selectedIndex+1);

  if (otherIndex < 0 || otherIndex >= habits.length) return;
  const otherHabit = habits[otherIndex];

  dispatchAction(habitsBulkUpdate([
    {id: habit.id, order: otherHabit.order},
    {id: otherHabit.id, order: habit.order}
  ]))
};

const mapStateToProps = (state, ownProps) => ({
  habits: habitsArraySelector(state, ownProps)
});

const HabitNameRendererController = connect(mapStateToProps, mapDispatchActionToProps)(HabitNameRenderer);

export default (amendHabit) => (text, record) => {
  return <HabitNameRendererController habit={record} amendHabit={amendHabit}/>
}
