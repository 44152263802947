import React from 'react'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Table } from 'antd';
import './items-table.css'
import {connect} from "react-redux";
import {mapDispatchActionToProps} from "../../../../../../../common/utils/index";
import {itemsTreeSelector} from "../../../../../../../common/selectors/goals-selectors";
import NameRenderer from "./name-renderer/name-renderer";
import BodyRowController from './items-table-row/items-table-row'
import {itemParentChange} from "../../../../../../../common/actions/item-actions";
import {findIndex, pick} from "lodash";
import {getSearchParams} from "../../../../../../../common/utils/url-utils";
import {visibleItems} from "../../../../../../../common/utils/item-selection-utils";

const columns = searchProps => [
  {
    title: 'Name',
    key: 'name',
    render: NameRenderer(searchProps)
  }
];

class DragSortingTable extends React.Component {
  state = {
    seconds: 0
  };

  components = {
    body: {
      row: BodyRowController,
    },
  };

  render() {
    const {items, expandedRowKeys, selectedItemsIds} = this.props;
    const searchProps = pick(this.props, ['search', 'tags', 'period', 'done', 'showNewItemModal', 'editGoalsWithIds']);

    if (!(items && items.length)) return null;

    return (
      <DndProvider backend={HTML5Backend}>
        <Table
          rowKey="id"
          columns={columns(searchProps)}
          dataSource={items}
          components={this.components}
          pagination={false}
          showHeader={false}
          expandedRowKeys={expandedRowKeys}
          onExpand={this.onExpand}
          onRow={(record) => ({
            id: record.id,
            selected: selectedItemsIds.includes(record.id),
            updateparent: this.updateParent,
            onClick: this.rowSelect(record),
            onTouchEnd: this.rowTouchEnd(record)
          })}
          locale={{emptyText: 'Click "+" button below to add new actions'}}
          className="actions-hierarchy"
          indentSize={24}
        />
      </DndProvider>
    )
  }

  updateParent = (id, parentId) => {
    const {selectedItemsIds, dispatchAction} = this.props;

    if (selectedItemsIds.includes(id)) {
      selectedItemsIds.forEach(selectedId => {
        dispatchAction(itemParentChange(selectedId, parentId))
      })
    } else {
      dispatchAction(itemParentChange(id, parentId))
    }
  };

  rowSelect = record => e => {
    const {selectedItemsIds, ctrlPressed, shiftPressed, selectItemsWithIds} = this.props;

    if (ctrlPressed) {
      let ids = [];
      if (selectedItemsIds.includes(record.id)) {
        ids = selectedItemsIds.filter(id => id !== record.id)
      } else {
        ids = [...selectedItemsIds, record.id]
      }
      selectItemsWithIds(ids);
    } else if(shiftPressed) {
      const {items, itemsWithChildren, expandedRowKeys} = this.props;
      const visibleItemsArray = visibleItems(items, expandedRowKeys, itemsWithChildren);

      let firstIndex = findIndex(visibleItemsArray, {id: selectedItemsIds[0]});
      let lastIndex = firstIndex;

      selectedItemsIds.forEach(id => {
        const idIndex = findIndex(visibleItemsArray, {id});

        firstIndex = idIndex < firstIndex ? idIndex : firstIndex;
        lastIndex = idIndex > lastIndex ? idIndex : lastIndex
      });

      const index = findIndex(visibleItemsArray, {id: record.id});

      let newSelection = [];
      if (index < firstIndex) {
        newSelection = visibleItemsArray.slice(index, firstIndex+1)
      } else {
        newSelection = visibleItemsArray.slice(firstIndex, index+1)
      }

      let newSelectionIds = newSelection.map(item => item.id);
      selectItemsWithIds(newSelectionIds)
    } else {
      if (selectedItemsIds.length === 1 && selectedItemsIds[0] === record.id) {
        selectItemsWithIds([])
      } else {
        selectItemsWithIds([record.id])
      }
    }

  };

  rowTouchEnd = record => e => {
    const PATH_THROUGH_NODES = ['TD', 'SPAN'];
    if (e.cancelable && PATH_THROUGH_NODES.includes(e.target.nodeName)) {
      e.preventDefault();
      e.stopPropagation();

      const {selectedItemsIds, selectItemsWithIds} = this.props;

      if ((selectedItemsIds || []).length === 1 && selectedItemsIds[0] === record.id) {
        selectItemsWithIds([]);
      } else {
        selectItemsWithIds([record.id]);
      }
    }

  };

  onExpand = (expanded, record) => {
    const {expandRow, collapseRow} = this.props;

    if (expanded) {
      expandRow(record.id)
    } else {
      collapseRow(record.id)
    }
  };

  secondsCounter = () => {
    const seconds = this.state.seconds + 1;
    this.setState({seconds})
  };

  // lifecycle methods
  componentDidMount() {
    this.secondsCounterInterval = setInterval(this.secondsCounter, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.secondsCounterInterval)
  }
}

const mapStateToProps = (state, ownProps) => {
  const topId = getSearchParams(ownProps.location).topId

  const {tree, byId} = itemsTreeSelector(state, {...ownProps, topId})

  return {
    items: tree,
    itemsWithChildren: byId
  }
};

export default connect(mapStateToProps, mapDispatchActionToProps)(DragSortingTable)
