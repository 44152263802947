import React from 'react'
import {connect} from "react-redux";
import { Checkbox, Popover } from 'antd';
import { FileAddOutlined, FileTextOutlined } from '@ant-design/icons'
import {mapDispatchActionToProps} from "../../../../../../common/utils/index";
import {habitChange} from "../../../../../../common/actions/habit-actions";
import {MOMENT_MODE_ADD_PROPERTIES, MODE_SCALES, GRID_MODE} from "../../../common/constants";
import {getHabitDetails, getHabitIndex, getHabitParamValues, getHabitYear, isHabitChecked} from "../../../common/utils";
import T from '../../../../../../components/i18n/text'
import './habit-cell-renderer.css'

const habitCellRenderer = ({mode, period, relativeIndex, selectedDate, record, dispatchAction, editHabit}) => {
  const index = getHabitIndex(mode, period, relativeIndex, selectedDate);
  const year = getHabitYear(mode, period, relativeIndex, selectedDate);

  const doneChange = e => {
    const done = e.target.checked;

    dispatchAction(habitChange(record.id, year, index, done))
  };

  const checked = isHabitChecked(record, year, index);

  // habit case params
  const hasParams = (record.habitParams && record.habitParams.length > 0);
  let paramsIcon = null;

  if (hasParams) {
    const paramValues = getHabitParamValues(record, year, index) || {};
    const hasParamValues = hasParams && Object.keys(paramValues).length > 0;

    const editClick = () => editHabit(record, relativeIndex, index, year);

    const addIcon = <FileAddOutlined onClick={editClick}/>;
    const detailsContent = getHabitDetails(record.habitParams, paramValues);

    const detailsIcon = (<Popover content={detailsContent} trigger="hover">
      <FileTextOutlined onClick={editClick}/>
    </Popover>);

    paramsIcon = hasParamValues ? detailsIcon : addIcon
  }

  return <div className='habit-cell-renderer'>
    <Checkbox checked={checked} onChange={doneChange} disabled={record.period !== mode}/>
    {paramsIcon}
  </div>
};

const HabitCellRendererController = connect(null, mapDispatchActionToProps)(habitCellRenderer)

const controller = (mode, period, index, selectedDate, record, editHabit) => <HabitCellRendererController mode={mode} period={period} relativeIndex={index} selectedDate={selectedDate} record={record} editHabit={editHabit}/>

const childrenAndPropsCalculator = (mode, period, index, amount, selectedDate, editHabit) => (record) => {
  let colSpan = 1;
  let children = null;

  const scaleDelta = MODE_SCALES[record.period] - MODE_SCALES[mode];
  if (scaleDelta === 0) {
    children = controller(mode, period, index, selectedDate, record, editHabit)
  } else if (scaleDelta < 0 || scaleDelta > 1) {
    children = <span><T path='PAGES.HABITS.WRONG_PERIOD'/></span>;
    colSpan = index === 0 ? amount : 0
  } else { // scale === 1
    if (record.period === GRID_MODE.WEEK) {
      const startOfPeriod = selectedDate.clone().startOf(period);
      const ourDate = startOfPeriod.clone().add(+index, MOMENT_MODE_ADD_PROPERTIES[mode]);

      if (index === 0) {
        colSpan = 7 - ourDate.weekday()
      } else {
        colSpan = ourDate.weekday() === 0 ? 7 : 0
      }

      const startWeek = startOfPeriod.week();
      const yearEndWeek = selectedDate.clone().endOf('year').week();

      let ourWeek = ourDate.week();
      if (ourWeek < startWeek) {
        ourWeek += yearEndWeek
      }
      const weekIndex = ourWeek - startWeek;

      children = controller(GRID_MODE.WEEK, period, weekIndex, selectedDate, record, editHabit)
    } else if (record.period === GRID_MODE.MONTH) {
      children = controller(GRID_MODE.MONTH, period, 0, selectedDate, record, editHabit);
      colSpan = index === 0 ? amount : 0
    }
  }

  return {children, props:{colSpan}}
};

export const renderHandler = (mode, period, index, amount, selectedDate, editHabit) => (text, record) => {
  return childrenAndPropsCalculator(mode, period, index, amount, selectedDate, editHabit)(record).children;
};

export const onCellHandler = (mode, period, index, amount, selectedDate, editHabit) => (record) => {
  return childrenAndPropsCalculator(mode, period, index, amount, selectedDate, editHabit)(record).props;
};
