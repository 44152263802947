import React from 'react'
import {Button} from "antd";
import {getPropertyIcon} from "../common/utils";
import Text from "../../../../../../components/i18n/text";

const PropertyButton = (props) => {
  const {property, editProperty, currentValueLabel} = props;

  return <div className={`property-button ${property}`} onClick={() => editProperty(property)}>
    <Button shape="circle" icon={getPropertyIcon(property)} />
    <span className="label"><Text path={`PAGES.ACTIONS.MOBILE_EDIT.${property}.LABEL`}/></span>
  </div>
};

export default PropertyButton
