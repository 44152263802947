import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {mapDispatchActionToProps, textMethod} from "../../../common/utils/index";
import {VIEW_TYPE} from "../../../common/constants";
import {withAuthorization} from "../../../common/utils/auth/with-authorization";
import {
  itemsWithChildrenSelector,
} from "../../../common/selectors/goals-selectors";
import PageTemplate from "../page-template/PageTemplate";
import "./GoalsPage.css"
import {useLocalStorage} from "../../../common/hooks/useLocalStorage";
import GoalsPriorityList from "./components/goals-priority-list/GoalsPriorityList";
import NewItem from "../../../common/components/new-item/new-item";
import NewItemModal from "../../../common/components/new-item-modal/new-item-modal";
import GoalsHierarchy from "./components/goals-hierarchy/GoalsHierarchy";
import GoalsHierarchyActions from "./components/goals-hierarchy-actions/GoalsHierarchyActions";
import GoalsPriorityListFilter from "./components/goals-priority-list-filter/GoalsPriorityListFilter";
import {DATE_CREATED_OPTIONS, DONE_OPTIONS} from "./components/common/constants";
import moment from "moment";
import {getChildParentIds} from "../../../common/selectors/selector-utils";
import {Radio} from "antd";
import {
  OrderedListOutlined,
  UnorderedListOutlined
} from '@ant-design/icons';
import ItemsProperties from "./components/item-properties/ItemsProperties";
import {CUSTOM_EVENTS} from "../../../common/types";
import {useCustomEvent} from "../../../common/hooks/customEvents";
import SelectionEditPanel from "./components/selection-edit-panel/SelectionEditPanel";

const FOCUS_AREA = Object.freeze({
  PRIORITY_LIST: "priority-list",
  HIERARCHY: "hierarchy",
});

const GoalsPage = (props) => {
  const {text} = props;

  const [editingGoalsIds, editGoalsWithIds] = useState([]);
  const editActionEvent = useCustomEvent(CUSTOM_EVENTS.EDIT_ACTION);
  useEffect(() => {
    const {ids = []} = editActionEvent || {};
    editGoalsWithIds(ids);
  }, [editActionEvent]);

  const [focusArea, changeFocusArea] = useLocalStorage("goalsVisibleArea", FOCUS_AREA.PRIORITY_LIST);

  const [newItemParentRecordId, changeNewItemParentRecordId] = useState(undefined);
  const showNewItemModal = (parentRecordId) => {changeNewItemParentRecordId(parentRecordId);};
  const hideNewItemModal = () => {changeNewItemParentRecordId(undefined);};

  const pageClasses = `goals page ${focusArea}`;

  const [priorityListFilterPeriod, changePriorityListFilterPeriod] = useState(null);
  const [searchText, changeSearchText] = useState('');
  const [searchPeriod, changeSearchPeriod] = useState('');
  const [searchDateCreatedOption, changeSearchDateCreatedOption] = useState(DATE_CREATED_OPTIONS.NA.value);
  const [searchDoneDateOption, changeSearchDoneDateOption] = useState(DONE_OPTIONS.NOT_DONE.value);

  const [expandedRowKeys, changeExpandedRowKeys] = useState([]);

  const [selectedPriorityItemsIds, selectPriorityItemsWithIds] = useState([]);
  const [selectedHierarchyItemsIds, selectHierarchyItemsWithIds] = useState([]);

  const priorityListFilterProps = {
    period: priorityListFilterPeriod,
    periodChange: changePriorityListFilterPeriod,
    showNewItemModal,
    selectedItemsIds: selectedPriorityItemsIds,
  };
  const priorityListProps = {
    period: priorityListFilterPeriod,
    selectedItemsIds: selectedPriorityItemsIds,
    selectItemsWithIds: selectPriorityItemsWithIds
  };

  const filterProps = prepareFilterProps(searchText, searchPeriod, searchDateCreatedOption, searchDoneDateOption);
  const goalsHierarchyActionsProps = {
    ...filterProps,
    dateCreatedChange: changeSearchDateCreatedOption,
    doneDateChange: changeSearchDoneDateOption,
    periodChange: changeSearchPeriod,
    searchChange: changeSearchText,
    showNewItemModal,
    selectedItemsIds: selectedHierarchyItemsIds,
  };
  const goalsHierarchyProps = {
    ...filterProps,
    showNewItemModal,
    expandRow: expandRow(expandedRowKeys, changeExpandedRowKeys),
    collapseRow: collapseRow(props.itemsWithChildren, expandedRowKeys, changeExpandedRowKeys),
    selectedItemsIds: selectedHierarchyItemsIds,
    selectItemsWithIds: selectHierarchyItemsWithIds
  };

  const itemPropertiesProps = {
    selectedItemsIds: editingGoalsIds,
    stopEditing: () => {editGoalsWithIds([])}
  };

  const selectionEditProps = {
    selectedItemsIds: focusArea === FOCUS_AREA.PRIORITY_LIST ? selectedPriorityItemsIds : selectedHierarchyItemsIds,
    stopEditing: () => {focusArea === FOCUS_AREA.PRIORITY_LIST ? selectPriorityItemsWithIds([]) : selectHierarchyItemsWithIds([])}
  };

  return <PageTemplate
    selectedView={VIEW_TYPE.GOALS}
    pageTitle={text("VIEWS.goals")}
    headerSelect={goalsViewSwitch(focusArea, changeFocusArea)}
  >
    <NewItemModal newItemParentId={newItemParentRecordId} visible={newItemParentRecordId !== undefined} onCancel={hideNewItemModal}/>
    <ItemsProperties {...itemPropertiesProps}/>
    <div className={pageClasses}>
      <div className="page-list">
        <div className="header">
          <GoalsPriorityListFilter {...priorityListFilterProps}/>
        </div>
        <div className="content">
          <GoalsPriorityList {...priorityListProps}/>
        </div>
        <div className="footer">
          <NewItem path="PAGES.ACTIONS.ADD_NEW" showNewItemModal={showNewItemModal}/>
        </div>
      </div>
      <div className="page-content">
        <div className="header">
          <GoalsHierarchyActions {...goalsHierarchyActionsProps}/>
        </div>
        <div className="content">
          <GoalsHierarchy {...goalsHierarchyProps}/>
        </div>
        <div className="footer">
          <NewItem path="PAGES.ACTIONS.ADD_NEW" showNewItemModal={showNewItemModal}/>
        </div>
      </div>
    </div>
    <SelectionEditPanel {...selectionEditProps}/>
  </PageTemplate>
};

const goalsViewSwitch = (focusArea, changeFocusArea) => {
  const valueChange = ({target:{value}}) => {
    changeFocusArea(value);
  };

  return <Radio.Group
    value={focusArea}
    onChange={valueChange}
    buttonStyle="solid"
    className="header-switch"
  >
    <Radio.Button value={FOCUS_AREA.PRIORITY_LIST}><OrderedListOutlined /></Radio.Button>
    <Radio.Button value={FOCUS_AREA.HIERARCHY}><UnorderedListOutlined /></Radio.Button>
  </Radio.Group>
};

const prepareFilterProps = (searchText, searchPeriod, searchDateCreatedOption, searchDoneDateOption) => {
  return {
    search: searchText,
    period: searchPeriod,

    dateCreated: searchDateCreatedOption,
    ...getDateCreatedFilterValues(searchDateCreatedOption),

    doneDate: searchDoneDateOption,
    ...getDoneDateFilterValues(searchDoneDateOption)
  }
};

const getDateCreatedFilterValues = dateCreated => {
  const now = moment();
  let dateCreatedFrom = null;
  let dateCreatedTo = null;

  switch(dateCreated) {
    case DATE_CREATED_OPTIONS.IN15M.value:
      dateCreatedFrom = now.subtract(15, 'minutes').valueOf();
      break;
    case DATE_CREATED_OPTIONS.IN1H.value:
      dateCreatedFrom = now.subtract(1, 'hours').valueOf();
      break;
    case DATE_CREATED_OPTIONS.TODAY.value:
      dateCreatedFrom = now.startOf('day').valueOf();
      break;
    case DATE_CREATED_OPTIONS.YESTERDAY.value:
      const yesterday = now.subtract(1, 'days');
      dateCreatedFrom = yesterday.startOf('day').valueOf();
      dateCreatedTo = yesterday.endOf('day').valueOf();
      break;
    default: {
      dateCreatedFrom = null;
      dateCreatedTo = null
    }
  }

  return {dateCreatedFrom, dateCreatedTo}
};

const getDoneDateFilterValues = doneDate => {
  const now = moment();

  let done = null;
  let doneDateFrom = null;
  let doneDateTo = null;

  switch(doneDate) {
    case DONE_OPTIONS.NOT_DONE.value:
      done = false;
      break;
    case DONE_OPTIONS.IN15M.value:
      done = true;
      doneDateFrom = now.subtract(15, 'minutes').valueOf();
      break;
    case DONE_OPTIONS.TODAY.value:
      done = true;
      doneDateFrom = now.startOf('day').valueOf();
      break;
    case DONE_OPTIONS.YESTERDAY.value:
      done = true;

      const yesterday = now.subtract(1, 'days');
      doneDateFrom = yesterday.startOf('day').valueOf();
      doneDateTo = yesterday.endOf('day').valueOf();
      break;
    default: {
      // leave values null
    }
  }

  return {done, doneDateFrom, doneDateTo};
};

const expandRow = (expandedRowKeys, changeExpandedRowKeys) => (rowId) => {
  const newIds = Array.isArray(rowId) ? rowId : [rowId]

  changeExpandedRowKeys([...newIds, ...expandedRowKeys])
};

const collapseRow = (itemsWithChildren, expandedRowKeys, changeExpandedRowKeys) => (rowId) => {
  let excludeIds = Array.isArray(rowId) ? rowId : [rowId];
  let childParentIds = [];

  excludeIds.forEach(id => {
    const ids = getChildParentIds(itemsWithChildren)(id);
    childParentIds = childParentIds.concat(ids)
  });

  excludeIds = excludeIds.concat(childParentIds);

  const expandedKeys = expandedRowKeys.filter(id => excludeIds.indexOf(id) === -1);

  changeExpandedRowKeys(expandedKeys)
};

const mapStateToProps = (state) => ({
  itemsWithChildren: itemsWithChildrenSelector(state),
  text: textMethod(state)
});

const GoalsPageController = connect(mapStateToProps, mapDispatchActionToProps)(GoalsPage)
export default withAuthorization(GoalsPageController)
