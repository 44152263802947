import React from 'react'
import { Tooltip, Radio } from 'antd'
import { DashboardOutlined } from '@ant-design/icons'
import {URGENCY} from "../constants/index";
import T from "../../components/i18n/text";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

export default ({value, onChange, size='default', className, style}) => {
  return <div className={`tripple-selection ${className}`} style={style}>
    <div className='background-text'>
      <DashboardOutlined />&nbsp;
      <T path='CONSTANTS.URGENCY'/>
    </div>
    <RadioGroup
      onChange={onChange}
      value={value || undefined}
      size={size}
    >
      <Tooltip mouseEnterDelay={1} placement="top" title={URGENCY.MINOR.label}>
        <RadioButton value={URGENCY.MINOR.value}/>
      </Tooltip>
      <Tooltip mouseEnterDelay={1} placement="top" title={URGENCY.NORMAL.label}>
        <RadioButton value={URGENCY.NORMAL.value}/>
      </Tooltip>
      <Tooltip mouseEnterDelay={1} placement="top" title={URGENCY.HIGH.label}>
        <RadioButton value={URGENCY.HIGH.value}/>
      </Tooltip>
    </RadioGroup>
  </div>
}
