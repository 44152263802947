import { createSelector } from 'reselect'
import {
  createItemsTree, dateWeightTimeSorting,
  fillTreeItemsPath,
  getDoneWithinPeriodFilters,
  getFilteredItemsTree,
  getFilteredTmItemsTree,
  getItemPath, getPeriodParams,
  getPlannedWithinPeriodFilters,
  getRootItemsMaxOrderValue,
  getSearchFilters,
  itemsTagsList, searchFilter, weightSorting,
} from './selector-utils'
import {filter, withDateOrPeriod} from '../../common/utils/item-utils'
import {DEFAULT_ITEM_DURATION, VIEW_TYPE} from "../constants";
import {
  idSelector,
  indexSelector,
  ownPropsSelector,
  periodSelector,
  yearSelector
} from "./own-props-selectors";
import itemModel from "../models/item-model";
import planItemModel from "../models/plan-item-model";
import doneItemModel from "../models/done-item-model";
import moment from 'moment'
import {periodFilteredResultHabitsSelector} from "./habits-selectors";

export const goalsSelector = state => state.goals;
export const goalsByIdSelector = state => state.goals.byId;
export const selectedGoalsIdsSelector = state => state.goals.selectedIds;

const sortAttributes = ['order', 'id'];

export const itemsWithChildrenSelector = createSelector(
  goalsSelector,
  ({byId}) => {
    return createItemsTree(byId, sortAttributes)
  }
)

export const itemsTreeSelector = createSelector(
  goalsByIdSelector,
  ownPropsSelector,
  (byId, ownProps) => {
    const filters = getSearchFilters(ownProps);
    const timeInThePast = moment(moment().valueOf() - 3000);

    return getFilteredItemsTree(
      byId,
      filters,
      itemModel,
      ownProps.topId,
      ownProps.viewItemId,
      timeInThePast
    )
  }
);

export const selectedItemSelector = createSelector(
  goalsByIdSelector,
  selectedGoalsIdsSelector,
  (byId, selectedIds) => {
    if (!(selectedIds && selectedIds.length === 1)) return {}

    return byId[selectedIds[0]]
  }
);

export const selectedItemWithChildrenSelector = createSelector(
  itemsWithChildrenSelector,
  selectedGoalsIdsSelector,
  (itemsWithChildren, selectedIds) => {
    if (!(selectedIds && selectedIds.length === 1)) return {}

    return itemsWithChildren[selectedIds[0]]
  }
);

export const particularItemWithChildrenSelector = createSelector(
  itemsWithChildrenSelector,
  idSelector,
  (itemsWithChildren, id) => {
    if (!id) return {}

    return itemsWithChildren[id]
  }
)

export const selectedItemParentWithChildrenSelector = createSelector(
  itemsWithChildrenSelector,
  selectedGoalsIdsSelector,
  (itemsWithChildren, selectedIds) => {
    if (!(selectedIds && selectedIds.length === 1)) return {}
    if (!itemsWithChildren[selectedIds[0]].parentId) return {}

    return itemsWithChildren[itemsWithChildren[selectedIds[0]].parentId]
  }
)

export const selectedItemPathSelector = createSelector(
  goalsByIdSelector,
  selectedGoalsIdsSelector,
  (byId, selectedIds) => {
    if (!(selectedIds && selectedIds.length === 1)) return []

    return getItemPath(byId, selectedIds[0])
  }
)

export const itemPathSelector = createSelector(
  goalsByIdSelector,
  idSelector,
  (byId, id) => {
    return getItemPath(byId, id)
  }
)

export const itemsTagsSelector = createSelector(
  goalsByIdSelector,
  (byId) => {
    return itemsTagsList(byId)
  }
)

//TODO delete once all habits are copied
export const itemHabitsSelector = createSelector(
  goalsByIdSelector,
  (byId) => {
    const filters = [
      filter('type', VIEW_TYPE.HABITS, true)
    ]

    return Object.keys(byId)
      .map(id => byId[id])
      .filter(searchFilter(filters))
  }
)

export const doneItemsWithParentsSelector = createSelector(
  goalsByIdSelector,
  periodFilteredResultHabitsSelector,
  periodSelector,
  yearSelector,
  indexSelector,
  (byId, {done}, period, year, index) => {
    const filters = getDoneWithinPeriodFilters(period, year, index)
    const tree = getFilteredTmItemsTree(byId, filters, doneItemModel, period, year, index)

    return tree.concat(done)
  }
)

export const plannedItemsWithParentsSelector = createSelector(
  goalsByIdSelector,
  periodFilteredResultHabitsSelector,
  periodSelector,
  yearSelector,
  indexSelector,
  (byId, {planned}, period, year, index) => {
    const filters = getPlannedWithinPeriodFilters(period, year, index)
    const tree = getFilteredTmItemsTree(byId, filters, doneItemModel, period, year, index)

    return tree.concat(planned)
  }
)

export const maxRootOrderValue = createSelector(
  goalsByIdSelector,
  (byId) => {
    return getRootItemsMaxOrderValue(byId)
  }
)

export const planingItemsSelector = createSelector(
  goalsByIdSelector,
  ownPropsSelector,
  (byId, ownProps) => {
    const filters = [
      filter('done', false, true),
      filter('type', 'action', true),
      filter('id', 'any', false, withDateOrPeriod),
      ...getSearchFilters(ownProps)
    ]

    // {items, parents, tree, byId}
    const periodParams = getPeriodParams()
    const result = getFilteredItemsTree(byId, filters, planItemModel(periodParams))

    let itemsById = {...result.byId}
    const itemsByIdWithPaths = fillTreeItemsPath(result.tree, itemsById)

    const reducer = ({withDatesOrPeriods, other}, item) => {
      if (item.dateFrom || item.period) {
        return {withDatesOrPeriods: [...withDatesOrPeriods, item], other}
      } else {
        return {withDatesOrPeriods, other: [...other, item]}
      }
    }
    const itemGroups = result.items
      .map(({id}) => itemsByIdWithPaths[id])
      .reduce(reducer, {withDatesOrPeriods: [], other: []})

    return itemGroups.withDatesOrPeriods.sort(dateWeightTimeSorting)
      .concat(itemGroups.other.sort(weightSorting))
  }
)

export const planingItemGroupsSelector = createSelector(
  planingItemsSelector,
  (items) => {
    const todayStart = moment().startOf('day').valueOf()
    const todayEnd = moment().endOf('day').valueOf()
    const tomorrowEnd = moment().add(1, 'days').endOf('day').valueOf()

    let groups = []

    const getGroup = name => {
      if (!(groups.length && groups[groups.length-1].name === name)) {
        groups.push({name, items: [], totalDuration: 0})
      }

      return groups[groups.length-1]
    };

    const addItem = (groupName, item) => {
      let group = getGroup(groupName);
      group.items.push(item);
      group.totalDuration += item.duration || DEFAULT_ITEM_DURATION
    };

    items.forEach(item => {
      if (item.dateFrom) {
        if (item.dateFrom < todayStart) {
          addItem('IN_THE_PAST', item)
        } else if (item.dateFrom > todayStart && item.dateFrom < todayEnd) {
          addItem('TODAY', item)
        } else {
          const tomorrow = item.dateFrom > todayEnd && item.dateFrom < tomorrowEnd
          const dayString = tomorrow ? 'TOMORROW' : moment(item.dateFrom).format('DD.MM.YY')

          addItem(dayString, item)
        }
      } else {
        addItem(item.period, item)
      }
    });

    return groups
  }
);
