import {DATE_OPTIONS} from "../../../../common/constants";

export const getPeriodDateString = (resultDate, period) => {
  switch(period) {
    case DATE_OPTIONS.DAY: return resultDate.format('DD.MM.YYYY');
    case DATE_OPTIONS.WEEK: {
      const from = resultDate.startOf(period).format('DD.MM');
      const to = resultDate.endOf(period).format('DD.MM');
      return `${from} - ${to}`;
    }
    case DATE_OPTIONS.MONTH: return resultDate.format('MM.YYYY');
    case DATE_OPTIONS.YEAR: return resultDate.format('YYYY');
    default: return ''
  }
};
