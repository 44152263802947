import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {mapDispatchActionToProps} from "../../utils/index";
import {userLogin, userLogout} from "../../actions/user-actions";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {getUserDataRef} from "../../utils/firebase/db";
import {receivedFromStorage} from "../../actions/storage-actions";
import {userSelector} from "../../selectors/user-selectors";
import {onValue} from "firebase/database";

const FirebaseUpdateWatcher = (props) => {
  useEffect(() => {
    watchAuthStateChanged(props);
  }, []);

  return <div/>;
};

const watchAuthStateChanged = (props) => {
  const { dispatchAction, user } = props;

  onAuthStateChanged(getAuth(), (authUser) => {
    if(authUser) {
      dispatchAction(userLogin(authUser))
      watchFirebaseDbUpdate(authUser, props)
    } else {
      dispatchAction(userLogout(user))
    }
  });
};

const watchFirebaseDbUpdate = (user, props) => {
  const { dispatchAction } = props;

  const userDataRef = getUserDataRef(user);

  onValue(userDataRef, (newsnapshot) => {
    dispatchAction(receivedFromStorage(newsnapshot.val()));
  });
};

const mapStateToProps = (state) => ({
  user: userSelector(state)
});

export default connect(mapStateToProps, mapDispatchActionToProps)(FirebaseUpdateWatcher)
