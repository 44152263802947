import {minifyString} from "../utils/index";
import {getPeriodMoment} from "../utils/date-utils";

const MAX_LIST_NME_LENGTH = 30

export default (result) => ({
  ...result,
  listName: minifyString(result.name, MAX_LIST_NME_LENGTH),
  sortTime: getPeriodMoment(result.year, result.period, result.index).endOf(result.period).valueOf(),
  searchString: `${result.name} ${result.year} ${result.period} ${result.content}`
})