import {ItemSubTypes, ItemTypes} from "./constants";
import React from "react";
import {
  RedoOutlined,
  CalendarOutlined,
  ExclamationOutlined,
  DashboardOutlined,
  StopOutlined,
} from '@ant-design/icons';

export const getPropertyIcon = (property) => {
  switch (property) {
    case ItemTypes.DATE: return <CalendarOutlined />;
    case ItemTypes.IMPORTANCE: return <ExclamationOutlined />;
    case ItemTypes.URGENCY: return <DashboardOutlined />;
    case ItemTypes.PERIOD: return <RedoOutlined />;
  }
};

export const getPropertyValueIcon = (property, value) => {
  switch (property) {
    case ItemTypes.DATE: {
      switch (value) {
        case ItemSubTypes[property].TODAY: return <span>0</span>;
        case ItemSubTypes[property].TOMORROW: return <span>+1</span>;
        case ItemSubTypes[property].INAWEEK: return <span>+7</span>;
      }
    }
    case ItemTypes.PERIOD: {
      switch (value) {
        case ItemSubTypes[property].NONE: return <StopOutlined />;
        case ItemSubTypes[property].WEEK: return <span>7</span>;
        case ItemSubTypes[property].MONTH: return <span>30</span>;
        case ItemSubTypes[property].YEAR: return <span>365</span>;
      }
    }
    default: return getPropertyIcon(property);
  }
};
