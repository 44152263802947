import React from 'react'
import {LoadingOutlined} from '@ant-design/icons'
import {Space} from "antd";

const Loading = () => {
  return <Space direction="horizontal" align="center" className="w100 h100">
    <Space direction="vertical" align="center" className="h100">
      <LoadingOutlined />
    </Space>
  </Space>
};

export default Loading
