export const getSearchParams = (location) => {
  if (location && location.search) {
    const {search} = location
    const paramsString = search.indexOf('?') === 0 ? search.substring(1) : search
    const paramPeers = paramsString.split('&')
    let params = {}

    paramPeers.forEach(peer => {
      if (peer.indexOf('=') > 0) {
        const keyVal = peer.split('=')
        params[keyVal[0]] = keyVal[1]
      }
    })

    return params
  }

  return {}
}