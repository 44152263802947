import React from 'react'
import {Button, Dropdown, Menu} from "antd";
import {eventValue, keyboardProps} from "../../../../../common/utils";
import SearchInput from "../../../../../common/components/search-input";
import {pick} from "lodash";
import { FilterOutlined } from '@ant-design/icons';
import HierarchyMoveButtons
  from "./components/HierarchyMoveButtons";
import "./GoalsHierarchyActions.css";
import {DATE_CREATED_OPTIONS, DONE_OPTIONS, PERIOD_OPTIONS} from "../common/constants";
import PlusButton from "../../../../../common/components/plus-button/PlusButton";

const GoalsHierarchyActions = (props) => {
  const {selectedItemsIds = [], showNewItemModal} = props;

  const noItemsSelected = selectedItemsIds.length === 0;

  const className = `goals-hierarchy-actions ${noItemsSelected ? 'noItemsSelected' : ''}`;

  const plusButtonClick = () => {
    if (selectedItemsIds.length === 1) {
      showNewItemModal(selectedItemsIds[0]);
    } else {
      showNewItemModal(null);
    }
  };

  return <div className={className}>
    <div className="filters">
      <div className="search">
        {getSearchFilter(props)}
      </div>
      <div className="filter">
        {getFilterMenu(props)}
      </div>
    </div>
    <div className="actions">
      {getHierarchyMoveButtons(props)}
    </div>
    <PlusButton onClick={plusButtonClick} disabled={selectedItemsIds.length > 1}/>
  </div>
};

const getFilterMenu = (props) => {
  const {dateCreated, dateCreatedChange, doneDate, doneDateChange, period, periodChange} = props;

  const handleMenuClick = e => {
    if (e.keyPath.length === 2) {
      switch(e.keyPath[1]) {
        case 'dateCreated': dateCreatedChange(DATE_CREATED_OPTIONS[e.key].value); break;
        case 'doneDate': doneDateChange(DONE_OPTIONS[e.key].value); break;
        case 'period': periodChange(PERIOD_OPTIONS[e.key].value); break;
        default:
      }
    } else {
      if (e.key === 'clearAll') {
        dateCreatedChange(DATE_CREATED_OPTIONS.NA.value)
        doneDateChange(DONE_OPTIONS.NOT_DONE.value)
        periodChange(PERIOD_OPTIONS.ANY.value)
      }
    }
  };

  const clearAllDisabled = (!dateCreated || dateCreated === DATE_CREATED_OPTIONS.NA.value)
    && (!doneDate || doneDate === DONE_OPTIONS.NOT_DONE.value)
    && (!period || period === PERIOD_OPTIONS.ANY.value);

  const menu = <Menu onClick={handleMenuClick}>
    {getDateCreatedFilterSubMenu(dateCreated)}
    {getDoneFilterSubMenu(doneDate)}
    {getPeriodFilterSubMenu(period)}
    <Menu.Divider />
    <Menu.Item key="clearAll" disabled={clearAllDisabled}>Clear all</Menu.Item>
  </Menu>;

  return <Dropdown overlay={menu} placement="bottomRight" className="float-right">
    <Button icon={<FilterOutlined />}/>
  </Dropdown>
};

const getDateCreatedFilterSubMenu = (dateCreated) => {
  const selected = dateCreated && dateCreated !== DATE_CREATED_OPTIONS.NA.value;
  const title = (selected ? "> " : "  ") +  "Date created";

  let menuItems = Object.keys(DATE_CREATED_OPTIONS).map(key => {
    const {value, label} = DATE_CREATED_OPTIONS[key];

    const menuLabel = (value === dateCreated && dateCreated !== DATE_CREATED_OPTIONS.NA.value ? "> " : "  ") + label;
    const disabled = !selected && key === "NA";

    return <Menu.Item key={key} disabled={disabled}>{menuLabel}</Menu.Item>
  });

  menuItems.splice(menuItems.length-1, 0, <Menu.Divider key="DATE_CREATED_DIVIDER"/>);

  return <Menu.SubMenu key="dateCreated" title={title}>
    {menuItems}
  </Menu.SubMenu>
};

const getDoneFilterSubMenu = (doneDate) => {
  const selected = doneDate && doneDate !== DONE_OPTIONS.NOT_DONE.value;
  const title = (selected ? "> " : "  ") +  "Done date";

  let menuItems = Object.keys(DONE_OPTIONS).map(key => {
    const {value, label} = DONE_OPTIONS[key];

    const menuLabel = (value === doneDate && doneDate !== DONE_OPTIONS.NOT_DONE.value ? "> " : "  ") + label;
    const disabled = !selected && key === "NOT_DONE";

    return <Menu.Item key={key} disabled={disabled}>{menuLabel}</Menu.Item>
  });

  menuItems.splice(menuItems.length-1, 0, <Menu.Divider key="DONE_DIVIDER"/>);

  return <Menu.SubMenu key="doneDate" title={title}>
    {menuItems}
  </Menu.SubMenu>
};

const getPeriodFilterSubMenu = (period) => {
  const selected = period && period !== PERIOD_OPTIONS.ANY.value;
  const title = (selected ? "> " : "  ") +  "Period";

  let menuItems = Object.keys(PERIOD_OPTIONS).map(key => {
    const {value, label} = PERIOD_OPTIONS[key];

    const menuLabel = (value === period && period !== PERIOD_OPTIONS.ANY.value ? "> " : "  ") + label;
    const disabled = !selected && key === "ANY";

    return <Menu.Item key={key} disabled={disabled}>{menuLabel}</Menu.Item>
  });

  menuItems.splice(menuItems.length-1, 0, <Menu.Divider key="PERIOD_DIVIDER"/>);

  return <Menu.SubMenu key="period" title={title}>
    {menuItems}
  </Menu.SubMenu>
};

const getSearchFilter = (props) => {
  const {search, searchChange} = props;

  const changeHandler = event => {
    searchChange(eventValue(event))
  };

  const searchProps = {
    defaultValue: search,
    className: "headItem headSearch search float-right",
    placeholder: "Search",
    onChange: changeHandler,
    ...keyboardProps(props)
  };

  return <SearchInput {...searchProps} />
};

const getHierarchyMoveButtons = (props) => {
  const btnProps = pick(props, ['expandRow', 'expandedRowKeys', 'selectedItemsIds']);

  return <HierarchyMoveButtons className='float-right' {...btnProps}/>
};

export default GoalsHierarchyActions;
